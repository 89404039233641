import { useState } from 'react'
import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Markdown from '@components/Markdown'
import Button from '@components/Button'

const CREATE_MULTIPLE_CHOICE_SUBMISSION = gql`
  mutation createMultipleChoiceSubmission($createMultipleChoiceSubmissionInput: CreateMultipleChoiceSubmissionInput!) {
    createMultipleChoiceSubmission(input: $createMultipleChoiceSubmissionInput) {
      multipleChoiceSubmission {
        question {
          id
        }
        answer {
          id
        }
        correct
      }
    }
  }
`

const MultipleChoiceQuestion = ({ questionText, id, answers, text, selectedAnswerId }) => {
  const [submissionMessage, setSubmissionMessage] = useState(null)
  const { register, handleSubmit } = useForm({ defaultValues: { answerId: selectedAnswerId } })

  const { mutate: createMultipleChoiceSubmission } = useMutation({
    mutationFn: (variables) => request(CREATE_MULTIPLE_CHOICE_SUBMISSION, variables)
  })

  const onSubmit = data => {
    const answer = answers.find(answer => answer.id === data.answerId)

    setSubmissionMessage(answer.correct ? `That's correct 🎉` : `That's incorrect 🤔 Try again!`)

    createMultipleChoiceSubmission({
      createMultipleChoiceSubmissionInput: {
        questionId: id,
        answerId: answer.id
      }
    })
  }

  return (
    <div className="mt-5">
      <If condition={text}>
        <Markdown content={text} />
      </If>

      <form onSubmit={handleSubmit(onSubmit)} className="p-5 bg-gray-200 rounded-md">
        <div role="group" aria-labelledby="question-text">
          <h4 id="question-text" className="font-semibold text-lg">{questionText}</h4>

          <div className="flex flex-col">
            <For each="answer" of={answers}>
              <label key={answer.id} className=" flex items-center mt-2 cursor-pointer">
                <input
                  {...register('answerId')}
                  className="h-[1.25rem] w-[1.25rem] min-h-[1.25rem] min-w-[1.25rem] bg-white mr-3 cursor-pointer"
                  type="radio"
                  value={answer.id}
                />
                {answer.text}
              </label>
            </For>
          </div>

          <If condition={submissionMessage}>
            <p className="mt-3">{submissionMessage}</p>
          </If>
        </div>

        <Button type="submit" className="mt-5" label="Submit" />
      </form>
    </div>
  )
}

export default MultipleChoiceQuestion
