import SecureMarkdown from '@components/SecureMarkdown'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'

import { useUpdateBlockSubmission } from './hooks'

const TextBlock = ({
  content,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  submitDisabled,
  openSubmitModal,
  submissionStatus,
  submissionId,
  blockId
}) => {

  const { updateBlockSubmission } = useUpdateBlockSubmission({ educatorProjectSubmissionId: submissionId })

  const next = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    moveToNextBlock()
  }

  const submitProject = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    openSubmitModal()
  }


  return (
    <>
      <SecureMarkdown content={content} />

      <div className="flex flex-row items-center justify-between mt-3">
        <LinkButton
          disabled={isFirstBlock}
          onClick={moveToPreviousBlock}>
          back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              className="self-end"
              disabled={submissionStatus === 'COMPLETED' || submitDisabled}
              label={submissionStatus === 'COMPLETED' ? 'Submitted' : 'Submit'}
              onClick={submitProject}
            />
          </When>

          <Otherwise>
            <Button
              className="self-end"
              label="Next"
              onClick={next} />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default TextBlock
