import { useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

const Collapsible = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>

        <div className="relative flex">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
            {isOpen ? <ChevronDownIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" /> : <ChevronUpIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />}
            Advanced settings
          </button>
        </div>
      </div>

      <If condition={isOpen}>
        {children}
      </If>
    </>
  )
}

export default Collapsible
