import { useMutation } from '@tanstack/react-query'
import { useForm, Controller } from 'react-hook-form'
import { gql } from 'graphql-request'
import { BuildingLibraryIcon, GlobeAltIcon, LockClosedIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import RadioGroup from '@components/RadioGroup'
import Pill from '@components/Pill'

const UDPATE_SERIES_MUTATION = gql`
  mutation updateSeries($input: UpdateSeriesInput!) {
    updateSeries(input: $input) {
      series {
        id
        name
        description
        notes
        createdAt
        visibility
      }
    }
  }
`

const EditModal = ({ id, isOpen, closeModal, refetch, name, description, visibility, notes }) => {
  const { user: { organization: { features } }, } = useCurrentUser()
  const publicSeriesEnabled = features.includes('series_public_visibility')

  const { register, handleSubmit, formState: { errors }, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      name,
      description,
      notes,
      visibility
    }
  })

  const { mutate: updateSeries, isLoading } = useMutation({
    mutationFn: async data => request(UDPATE_SERIES_MUTATION, { input: { seriesId: id, ...data } }),
    onSuccess: () => {
      refetch()
      closeModal(false)
    }
  })

  const onSubmit = data => updateSeries(data)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => closeModal(false)}
      size="sm">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col">
        <h3 className="text-2xl font-semibold mb-3">Edit series</h3>

        <div className="mb-3">
          <TextInput
            id="name"
            label="Series name"
            {...register('name', { required: "Series name is required" })} />
          <If condition={errors.name}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.name.message}</p>
          </If>
        </div>

        <div className="mb-3">
          <TextArea
            id="description"
            label="Description"
            required={false}
            {...register('description')}
          />
        </div>

        <div className="mb-5">
          <Controller
            name="visibility"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                theme="primary"
                label="Visibility">
                <RadioGroup.Option
                  label={
                    <span className="flex items-center">
                      <LockClosedIcon className="h-5 w- mr-2" />
                      Private
                    </span>
                  }
                  description="This project series will only be visible to you."
                  value="PRIVATE" />
                <RadioGroup.Option
                  label={
                    <span className="flex items-center">
                      <BuildingLibraryIcon className="h-5 w-5 mr-2" />
                      Organization
                    </span>
                  }
                  description="This project series will be visible to educators within your organization."
                  value="ORGANIZATION" />
                <RadioGroup.Option
                  disabled={!publicSeriesEnabled}
                  label={
                    <span className="flex items-center">
                      <GlobeAltIcon className="h-5 w-5 mr-2" />
                      Public
                      <If condition={!publicSeriesEnabled}>
                        <Pill theme="light" className="ml-2" label="Coming soon" />
                      </If>
                    </span>
                  }
                  description="This project series will be publicly visible to the Mindjoy community."
                  value="PUBLIC" />
              </RadioGroup>
            )} />
        </div>

        <div className="mb-3">
          <TextArea
            id="notes"
            label="Notes"
            required={false}
            {...register('notes')}
          />
          <small>Notes are not visible to students</small>
        </div>

        <Button
          disabled={isLoading}
          type="submit"
          label={isLoading ? 'Saving...' : 'Save'} />
      </form>
    </Modal>
  )
}

export default EditModal
