import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  CodeBracketIcon,
  NumberedListIcon,
  ListBulletIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  TableCellsIcon
} from '@heroicons/react/24/outline'

import Tooltip from '@components/Tooltip'

const Button = ({ id, label, icon, disabled, className, onClick }) => (
  <>
    <button
      id={id}
      type='button'
      onClick={onClick}
      disabled={disabled}
      className={twMerge('px-3 py-1 rounded-md hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400', className)}
      aria-label={label}
    >
      {icon}
    </button>

    <Tooltip anchorSelect={`#${id}`}>
      {label}
    </Tooltip>
  </>
)

const LatexText = ({ math }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      katex.render(math, containerRef.current, {
        throwOnError: false
      })
    }
  }, [math])

  return <span ref={containerRef} />
}

const ToolBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  const isTableFocused = editor.isActive('table')

  return (
    <div className='bg-gray-100 border-x border-t border-gray-300 rounded-t-md'>
      <div className='flex flex-wrap p-1'>
        <Button
          id='bold-button'
          label='Bold'
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<BoldIcon className='size-4' />}
        />

        <Button
          id='italic-button'
          label='Italic'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'bold' : ''}
          icon={<ItalicIcon className='size-4' />}
        />

        <Button
          id='strike-button'
          label='Strikethrough'
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          className={editor.isActive('strike') ? 'bold' : ''}
          icon={<StrikethroughIcon className='size-4' />}
        />

        <Button
          id='unordered-list-button'
          label='Bullet List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'bold' : ''}
          icon={<ListBulletIcon className='size-4' />}
        />

        <Button
          id='numbered-list-button'
          label='Numbered List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'bold' : ''}
          icon={<NumberedListIcon className='size-4' />}
        />

        <Button
          id='code-button'
          label='Code'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<CodeBracketIcon className='size-4' />}
        />

        <Button
          id='table-button'
          label='Table'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<TableCellsIcon className='size-4' />}
        />

        <Button
          id='math-button'
          label='Math'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().insertMath(String.raw`\placeholder{}^2`).run()} // eslint-disable-line
          className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'bold' : ''}
          icon={<LatexText math='x^2' />}
        />

        <div className='flex ml-auto'>
          <Button
            id='undo-button'
            label='Undo'
            onClick={() => editor.chain().focus().undo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .undo()
                .run()
            }
            icon={<ArrowUturnLeftIcon className='size-4' />}
          />

          <Button
            id='redo-button'
            label='Redo'
            onClick={() => editor.chain().focus().redo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .redo()
                .run()
            }
            icon={<ArrowUturnRightIcon className='size-4' />}
          />
        </div>
      </div>
    </div>
  )
}

export default ToolBar
