import React, { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'
import { Link, useSearchParams } from 'react-router-dom'

import { request } from '@helpers/graphql'
import { formatTimestamp } from '@helpers/format'
import Card from '@components/Card'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import Spinner from '@components/Spinner'
import Select from '@components/Select'
import Toggle from '@components/Toggle'

import NoResults from './NoResults'

const CLASSROOMS_QUERY = gql`
  query classrooms {
    me {
      profile {
        ... on EducatorProfile {
          classrooms {
            id
            name
            educatorProfile {
              fullName
            }
            studentProfiles {
              id
            }
          }
        }
      }
    }
  }
`

const TUTOR_ASSIGNMENTS_QUERY = gql`
  query tutor($id: ID!, $page: Int!, $showClosed: Boolean, $classroomId: ID) {
    node(id: $id) {
      ... on Tutor {
        assignments(showClosed: $showClosed, classroomId: $classroomId, page: $page, perPage: 10) {
          pagesCount
          nodesCount
          nodes {
            id
            createdAt
            closed
            classroom {
              id
              name
            }
            chats {
              nodesCount
            }
          }
        }
      }
    }
  }
`

const StudentActivity = ({ tutorId }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const showClosed = searchParams.get('showClosed') === 'true' || false
  const page = searchParams.get('page') || 1
  const classroomId = searchParams.get('classroomId') || null

  const { isLoading, data } = useQuery({
    queryKey: ['tutorAssignments', tutorId, page, showClosed, classroomId],
    queryFn: async () => request(TUTOR_ASSIGNMENTS_QUERY, { id: tutorId, page, showClosed, classroomId })
  })

  const { data: { me: { profile: { classrooms: educatorClassrooms = [] } = {} } = {} } = {} } = useQuery({
    queryKey: ['classrooms'],
    queryFn: async () => request(CLASSROOMS_QUERY)
  })

  const assignments = data?.node?.assignments?.nodes || []
  const pagesCount = data?.node?.assignments?.pagesCount || 0

  const updateParams = newParams => {
    const params = {
      page,
      showClosed,
      classroomId,
      ...newParams
    }

    if (params.page === 1) delete params.page
    if (params.showClosed !== true) delete params.showClosed
    if (!params.classroomId) delete params.classroomId

    setSearchParams(params)
  }

  return (
    <>
      <h2 className='text-2xl font-semibold ml-5 py-3'>Sessions</h2>

      <Card className='flex flex-col sm:flex-row sm:items-center gap-5 justify-between p-5 sm:mx-5 mb-5 w-auto'>
        <Select
          value={classroomId}
          onChange={event => updateParams({ classroomId: event.target.value })}
          label='Classroom'
        >
          <Select.Option key='' value=''>Select a classroom</Select.Option>
          <For each='classroom' of={educatorClassrooms}>
            <Select.Option key={classroom.id} value={classroom.id}>{classroom.name}</Select.Option>
          </For>
        </Select>

        <Toggle
          value={showClosed}
          onChange={() => updateParams({ showClosed: !showClosed })}
          label='Show closed sessions'
        />
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className="flex items-center justify-center w-full mt-32" />
        </When>

        <When condition={assignments.length === 0}>
          <NoResults showClosedSessions={() => updateParams({ showClosed: true })} />
        </When>

        <Otherwise>
          <div className='overflow-x-scroll md:mx-5 mx-0 p-0.5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='40%'>Classroom</Table.Header>
                  <Table.Header width='20%'>Started at</Table.Header>
                  <Table.Header width='20%'>Chats</Table.Header>
                  <Table.Header width='20%'>Status</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='assignment' of={assignments}>
                  <Table.Row className='hover:cursor-pointer' key={assignment.id}>
                    <Table.Cell>
                      <Link className='block' to={assignment.id}>
                        {assignment.classroom?.name}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block' to={assignment.id}>
                        {formatTimestamp(assignment.createdAt)}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block' to={assignment.id}>
                        <span className='bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                          {assignment.chats?.nodesCount}
                        </span>
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block' to={assignment.id}>
                        <Choose>
                          <When condition={assignment.closed}>
                            <span className='bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                              Closed
                            </span>
                          </When>

                          <Otherwise>
                            <span className='bg-green-100 text-green-900 px-3 py-2 rounded-full'>
                              Active
                            </span>
                          </Otherwise>
                        </Choose>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>

            <Pagination page={page} pagesCount={pagesCount} setPage={page => updateParams({ page })} />
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default StudentActivity
