import { useState, useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'
import { VideoCameraIcon } from '@heroicons/react/24/outline'
import ReactPlayer from 'react-player'

import { request } from '@helpers/graphql'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Notification from '@components/Notification'

import BlockCard from './BlockCard'

const UPDATE_VIDEO_BLOCK_MUTATION = gql`
  mutation updateVideoBlock($input: UpdateVideoBlockInput!) {
    updateVideoBlock(input: $input) {
      video {
        url
      }
      errors {
        message
      }
    }
  }
`

const formatUrl = (url) => {
  if (!url) return null

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }

  return `https://${url}`
}

const VideoBlock = ({ id, url, showDeleteModal, refetch, dragHandleProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoErrored, setVideoErrored] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues: { url } })

  const { mutate: updateVideoBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_VIDEO_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => {
      refetch()
      setIsModalOpen(false)
    }
  })

  const onSubmit = ({ url }) => {
    const formattedUrl = formatUrl(url)

    updateVideoBlock({ url: formattedUrl })
  }

  useEffect(() => {
    setVideoErrored(false)
  }, [url])

  return (
    <>

      <BlockCard
        title="Video"
        icon={<VideoCameraIcon className="h-5 w-5" />}
        onEdit={setIsModalOpen}
        onDelete={showDeleteModal}
        dragHandleProps={dragHandleProps}>
        <div className="px-5 pt-3 pb-5 text-lg">
          <Choose>
            <When condition={videoErrored}>
              <Notification className="w-fit" theme="error">Oops! Something went wrong while loading your video. </Notification>
            </When>

            <When condition={url}>
              <ReactPlayer
                width="100%"
                controls
                url={url}
                onError={() => setVideoErrored(true)}
              />
            </When>

            <Otherwise>
              <em>You haven't added a video just yet</em>
            </Otherwise>
          </Choose>
        </div>
      </BlockCard>

      <Modal
        size="md"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 py-5">
          <h3 className="font-heading text-2xl font-bold mb-3">Edit Video</h3>

          <TextInput
            label="Video URL"
            placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            {...register('url', { required: 'Video URL is required' })}
          />
          <If condition={errors.url}>
            <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.url.message}</p>
          </If>

          <small className="mt-2">You can add videos from Youtube, Vimeo, Facebook and Twitch.</small>

          <Button
            className="ml-auto"
            type="submit"
            disabled={isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </form>
      </Modal>
    </>
  )
}

export default VideoBlock
