import { Link, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import TutorCard from '@components/TutorCard'
import Pagination from '@components/Pagination'
import Spinner from '@components/Spinner'

import NoResults from './NoResults'

const CLASSROOM_TUTORS_QUERY = gql`
  query classroomTutors($id: ID!, $page: Int) {
    classroom(id: $id) {
      tutors(page: $page, perPage: 8) {
        nodes {
          id
          name
          summary
          description
          organizationName
          userName
          image {
            url
          }
        }
        pagesCount
        nodesCount
      }
    }
  }
`

const OpenTutorList = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || 1

  const { isLoading, data: { classroom: { tutors: { nodes: tutors = [], pagesCount, nodesCount } = {} } = {} } = {} } = useQuery({
    queryKey: ['classroomTutors', id, page],
    queryFn: async () => request(CLASSROOM_TUTORS_QUERY, { id, page: parseInt(page) })
  })

  return (
    <div className='sm:px-5 pt-5'>
      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full h-full' />
        </When>

        <When condition={nodesCount === 0}>
          <NoResults />
        </When>

        <Otherwise>
          <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5'>
            <For each='tutor' of={tutors}>
              <Link
                key={tutor.id}
                to={`/educators/tutors/${tutor.id}/sessions?classroomId=${id}`}
              >
                <TutorCard
                  id={tutor.id}
                  organizationName={tutor.organizationName}
                  userName={tutor.userName}
                  name={tutor.name}
                  description={tutor.summary || tutor.description}
                  imageUrl={tutor.image?.url}
                />
              </Link>
            </For>
          </div>

          <Pagination page={parseInt(page)} pagesCount={pagesCount} setPage={page => setSearchParams({ page })} />
        </Otherwise>
      </Choose>
    </div>
  )
}

export default OpenTutorList
