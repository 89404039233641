import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { ClockIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import { formatShortTimestamp } from '@helpers/format'
import Button from '@components/Button'
import Card from '@components/Card'
import Modal from '@components/Modal'
import Spinner from '@components/Spinner'
import ShowMore from '@components/ShowMore'

const CREATE_PROJECT_COMPLETION_MUTATION = gql`
  mutation createEducatorProjectCompletion($input: CreateEducatorProjectCompletionInput!) {
    createEducatorProjectCompletion(input: $input) {
      completion
      errors {
        message
      }
    }
  }
`

const COMPLETION_HISTORY_QUERY = gql`
  query projectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        completions {
          status
          createdAt
          prompt
          result
        }
      }
    }
  }
`

const CompletionPlayground = ({ submissionId }) => {
  const [showHistory, setShowHistory] = useState(false)
  const { register, handleSubmit, getValues, setValue } = useForm({ mode: 'onChange' })

  const { data: { node: { completions = [] } = {} } = {}, refetch } = useQuery({
    queryKey: ['completions', submissionId],
    queryFn: async () => request(COMPLETION_HISTORY_QUERY, { id: submissionId }),
  })
  const { mutate: createCompletion, isLoading: isCreating, error } = useMutation({
    mutationFn: async variables => request(CREATE_PROJECT_COMPLETION_MUTATION, variables),
    onSuccess: data => {
      setValue('text', getValues('text') + ' ' + data.createEducatorProjectCompletion.completion)
    },
    onSettled: () => refetch()
  })

  const submit = data => createCompletion({ input: { educatorProjectSubmissionId: submissionId, ...data } })

  return (
    <div className="pr-5 pl-2 py-5 rounded-lg h-full relative">
      <form className="flex flex-col h-full" onSubmit={handleSubmit(submit)}>
        <textarea
          className="peer w-full h-full border-x-2 border-t-2 rounded-x-md rounded-t-md p-2 border-grey-500 focus:outline-none font-normal resize-none focus:shadow-input"
          {...register('text', { required: true })}
        />
        <p className="peer-focus:shadow-input w-full text-xs border-x-2 border-y-2 border-black mb-0 p-2 rounded-b-md bg-purple-100">
          <span className="font-bold">JoyBot</span> is a software program created to help you learn with AI. Responses are aren't always factually accurate. When working with AI, your job is to be curious, responsible and have fun!
        </p>

        <div className="flex flex-row items-center">
          <If condition={error}>
            <p className="text-sm mt-2">⛔️ Unsafe content detected</p>
          </If>

          <If condition={isCreating}>
            <Spinner className="mt-2" />
          </If>

          <div className="ml-auto flex flex-row">
            <button
              type="button"
              onClick={() => setShowHistory(!showHistory)}
              className="flex flex-row items-center mt-3 mr-3 text-sm font-semibold hover:bg-grey-100 p-2 rounded-md">
              <ClockIcon className="h-5 w-5 mr-1" />
              View history
            </button>

            <Button className="mt-3" type="submit" label="Submit" />
          </div>
        </div>
      </form>

      <Modal
        isOpen={showHistory}
        onClose={() => setShowHistory(false)}>
        <h2 className="text-2xl font-bold mb-3">History</h2>
        <Card className="bg-white border-black p-3">
          <Choose>
            <When condition={completions.length === 0}>
              <p className="text-sm mb-[200px] font-semibold">You don't have any history just yet, try submitting a prompt 💬</p>
            </When>
            <Otherwise>
              <For each="completion" of={completions}>
                <div className="flex flex-row mb-2">
                  <p className="text-sm whitespace-nowrap mr-3 font-semibold">
                    {formatShortTimestamp(completion.createdAt)}
                  </p>

                  <div className="text-sm">
                    <p className="whitespace-pre-wrap">
                      {completion.prompt}

                      <If condition={completion.status === 'SUCCESS'}>
                        <mark className="bg-green-100 whitespace-pre-wrap">
                          <ShowMore content={completion.result} />
                        </mark>
                      </If>
                    </p>

                    <If condition={completion.status === 'ERROR'}>
                      <mark className="bg-red-100">Unsafe content detected</mark>
                    </If>
                  </div>
                </div>
              </For>
            </Otherwise>
          </Choose>
        </Card>
      </Modal>
    </div >
  )
}

export default CompletionPlayground
