import { twMerge } from 'tailwind-merge'

const projectTheme = (theme, onClick) => {
  if (theme === 'success') return 'bg-green-100 border border-green-100 hover:border-green-800 hover:cursor-pointer'
  if (theme === 'light' && !!onClick) return 'hover:bg-gray-100 bg-white hover:cursor-pointer'
}

const Project = ({ name, summary, icon, onClick, theme }) => (
  <button
    className={twMerge("flex flex-row w-full p-3 sm:rounded-lg hover:cursor-default shadow bg-white", projectTheme(theme, onClick))}
    onClick={onClick}>
    <div className="p-2 mr-2">{icon}</div>

    <div className="w-full text-left">
      <h3 className="text-lg font-semibold">{name}</h3>
      <p className="text-sm">{summary}</p>
    </div>
  </button>
)

export default Project
