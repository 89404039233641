import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import TextArea from '@components/TextArea'
import { useFlashMessage } from '@components/FlashMessage'

const INVITE_EDUCATORS_TO_ORGANIZATION_MUTATION = gql`
  mutation adminInviteEducatorsToOrganization(
    $adminInviteEducatorsToOrganizationInput: AdminInviteEducatorsToOrganizationInput!
  ) {
    adminInviteEducatorsToOrganization(
      input: $adminInviteEducatorsToOrganizationInput
    ) {
      success
      errors
    }
  }
`

const InviteEducatorsModal = ({ organizationId, closeModal, isOpen }) => {
  const { setFlashMessage } = useFlashMessage()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm({ mode: 'onTouched' })
  const { mutateAsync: inviteEducators, isLoading: sending } = useMutation({
    mutationFn: async (variables) =>
      request(INVITE_EDUCATORS_TO_ORGANIZATION_MUTATION, variables),
    onSettled: (response) => {
      const data = response?.adminInviteEducatorsToOrganization

      if (data.success === true) {
        setFlashMessage('Invitations sent', 'success')
        reset()
        closeModal()
      } else {
        const message = data.errors[0] || 'Something went wrong'
        setError('emailAddresses', { message })
      }
    }
  })

  const submit = ({ emailAddresses }) => {
    inviteEducators({
      adminInviteEducatorsToOrganizationInput: {
        organizationId,
        emailAddresses
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col space-y-4 mt-5'
      >
        <h2 className='font-heading text-3xl font-bold'>Invite educators</h2>
        <p>Send educators an invitation to join this organization.</p>

        <div>
          <TextArea
            id='email-addresses'
            label='Educator email addresses'
            className='h-20'
            autoFocus
            required
            {...register('emailAddresses', {
              required: 'Email addresses are required',
              validate: {
                emails: (value) =>
                  value
                    .split(',')
                    .every((email) => /^\S+@\S+\.\S+$/.test(email.trim())) ||
                  'Email addresses must be valid',
                format: (value) =>
                  /^\s*(\S+)(,\s*\S+)*\s*$/.test(value) ||
                  'Must be a valid comma-separated list'
              }
            })}
          />
          <If condition={errors.emailAddresses}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
              {errors.emailAddresses.message}
            </p>
          </If>

          <p
            className='text-sm mt-1 inline flex-row items-center leading-3'
            role='alert'
          >
            <QuestionMarkCircleIcon className='w-5 h-5 mr-1 inline' />
            Invite multiple educators by listing their email addresses separated
            by a comma, or by pasting the contents of a CSV file.
          </p>
        </div>

        <Button
          disabled={sending}
          className='w-fit ml-auto'
          type='submit'
          label={sending ? 'Sending...' : 'Send invitations'}
        />
      </form>
    </Modal>
  )
}

export default InviteEducatorsModal
