import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftEndOnRectangleIcon, Cog6ToothIcon, LightBulbIcon, UserPlusIcon, ChatBubbleLeftEllipsisIcon, BookOpenIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'

import { client } from '@helpers/api'
import { useCurrentUser } from '@contexts/currentUser'
import TechnicalDifficultiesBanner from './TechnicalDifficultiesBanner'

import Navbar from './Navbar'

const StudentNav = ({ children }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setUser, user: { organization: { features } } } = useCurrentUser()
  const { mutate: signOut } = useMutation(['signOut'], async () => client.delete('/users/sign_out'),
    {
      onSuccess: () => {
        setUser({ loading: false, signedIn: false })
        queryClient.clear()
        navigate('/sign_in')
      }
    }
  )

  const legacyNavigation = [
    { name: 'Tutors', href: '/students/tutors', icon: ChatBubbleLeftEllipsisIcon },
    { name: 'Courses', href: '/students/courses', icon: BookOpenIcon }
  ]

  if (features.includes('tutor_insights')) {
    legacyNavigation.push({ name: 'Tutor Insights', href: '/students/tutor_insights', icon: LightBulbIcon })
  }

  if (features.includes('course_insights')) {
    legacyNavigation.push({ name: 'Course Insights', href: '/students/course_insights', icon: LightBulbIcon })
  }

  const navigation = [
    { name: 'Tutors', href: '/students/tutors', icon: ChatBubbleLeftEllipsisIcon }
  ]

  if (features.includes('student_events')) {
    legacyNavigation.push({ name: 'Workshops', href: '/students/workshops', icon: CalendarDaysIcon })
    navigation.push({ name: 'Workshops', href: '/students/workshops', icon: CalendarDaysIcon })
  }

  const settings = [
    { name: 'Student join code', href: '/join_short_code', icon: UserPlusIcon },
    { name: 'Settings', href: '/students/settings', icon: Cog6ToothIcon },
    { name: 'Sign out', icon: ArrowLeftEndOnRectangleIcon, onClick: signOut }
  ]

  const legacyFeaturesEnabled = features.includes('legacy_features')
  const technicalDifficultiesBannerEnabled = features.includes('technical_difficulties_banner')

  return (
    <Navbar
      navigation={legacyFeaturesEnabled ? legacyNavigation : navigation}
      settings={settings}
      message={technicalDifficultiesBannerEnabled && <TechnicalDifficultiesBanner />}
    >
      {children}
    </Navbar>
  )
}

export default StudentNav
