import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { Tab } from '@headlessui/react'

import { request } from '@helpers/graphql'
import { useBreakpoint } from '@contexts/breakpoints'
import Explanation from '@components/Explanation'
import MultipleChoiceQuestion from '@components/MultipleChoiceQuestion'
import Spinner from '@components/Spinner'

import CompletionPlayground from './CompletionPlayground'
import ChatPlayground from './ChatPlayground'

const PROJECT_SUBMISSION_QUERY = gql`
  query projectSubmission($id: ID!) {
    projectSubmission(id: $id) {
      id
      status
      project {
        id
        name
        imageUrl
        summary
        playground
        unit {
          id
          name
        }
        contents {
          id
          contentSubmission {
            id
            status
          }
          ... on Explanation {
            heading
            text
            __typename
          }
          ... on MultipleChoiceQuestion {
            id
            question
            text
            answers {
              id
              text
              correct
            }
            lastSubmission {
             answer {
                id
              }
            }
            __typename
          }
        }
      }
    }
  }
`

const UPDATE_PROJECT_SUBMISSION_MUTATION = gql`
  mutation updateProjectSubmission($updateProjectSubmissionInput: UpdateProjectSubmissionInput!) {
    updateProjectSubmission(input: $updateProjectSubmissionInput) {
      projectSubmission {
        id
        status
      }
    }
  }
`

const UPDATE_CONTENT_SUBMISSION_MUTATION = gql`
  mutation updateContentSubmission($updateContentSubmissionInput: UpdateContentSubmissionInput!) {
    updateContentSubmission(input: $updateContentSubmissionInput) {
      contentSubmission {
        id
        status
      }
    }
  }
`

const ProjectSubmission = ({ onProjectExit }) => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [contentIndex, setContentIndex] = useState(null)
  const isMobile = useBreakpoint('sm')

  const { isLoading, data: { projectSubmission: { project } = {} } = {} } = useQuery({
    queryKey: ['projectSubmission', id],
    queryFn: async () => request(PROJECT_SUBMISSION_QUERY, { id }),
    onSuccess: data => {
      const { projectSubmission: { project, status } } = data

      if (status === 'COMPLETED') {
        setContentIndex(0)
      } else {
        const lastContentIndex = project.contents.findLastIndex(content => content.contentSubmission?.status === 'COMPLETED')
        setContentIndex(lastContentIndex + 1)
      }
    }
  })
  const { mutate: updateProjectSubmission } = useMutation({
    mutationKey: ['updateProjectSubmission', id],
    mutationFn: async variables => request(UPDATE_PROJECT_SUBMISSION_MUTATION, variables),
    onSuccess: () => onProjectExit()
  })
  const { mutate: updateContentSubmission } = useMutation({
    mutationKey: ['updateContentSubmission'],
    mutationFn: async variables => request(UPDATE_CONTENT_SUBMISSION_MUTATION, variables),
    onMutate: variables => {
      queryClient.setQueryData(['projectSubmission', id], old => {
        const { projectSubmission: { project } } = old

        const newProjectSubmission = {
          projectSubmission: {
            project: {
              ...project,
              contents: project.contents.map(content => {
                if (content.id === variables.updateContentSubmissionInput.contentId) {
                  return {
                    ...content,
                    contentSubmission: {
                      id: Date.now(), // Temporary ID
                      status: 'COMPLETED'
                    }
                  }
                } else {
                  return content
                }
              })
            }
          }
        }

        return newProjectSubmission
      })
    }
  })

  const currentContent = project?.contents[contentIndex]

  const nextSection = () => {
    if (contentIndex < project?.contents.length - 1) {
      updateContentSubmission({ updateContentSubmissionInput: { contentId: currentContent.id, status: 'COMPLETED' } })
      setContentIndex(contentIndex + 1)
    } else {
      updateContentSubmission({ updateContentSubmissionInput: { contentId: currentContent.id, status: 'COMPLETED' } })
      updateProjectSubmission({ updateProjectSubmissionInput: { projectSubmissionId: id, status: 'COMPLETED' } })
    }
  }

  const previousSection = () => {
    if (contentIndex < 1) {
      onProjectExit()
    } else {
      setContentIndex(contentIndex - 1)
    }
  }

  if (isLoading) return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />

  return (
    <Choose>
      <When condition={isMobile}>
        <Tab.Group>
          <Tab.List className='sticky top-0 flex flex-row items-center justify-between border-b-gray-200 border-b bg-white'>
            <div className='w-full flex flex-row text-base h-[50px] leading-none'>
              <Tab className='w-full flex flex-row justify-center items-center px-1 h-full border-b-white ui-selected:border-b-blue-500 ui-selected:font-semibold border-b-4 outline-none'>
                Project
              </Tab>

              <Tab className='w-full flex flex-row justify-center items-center px-1 h-full border-b-white ui-selected:border-b-blue-500 ui-selected:font-semibold border-b-4 outline-none'>
                Playground
              </Tab>
            </div>
          </Tab.List>

          <Tab.Panels className='h-full mb-3'>
            <Tab.Panel>
              <div className='flex flex-row items-center mb-3 m-5'>
                <button
                  className='flex justify-center items-center h-10 w-10 mr-2 rounded-md border shadow bg-white'
                  aria-label='Previous content'
                  onClick={previousSection}
                >
                  <ChevronLeftIcon className='w-6 h-6 text-black' />
                </button>

                <For each='content' index='index' of={project?.contents}>
                  <Choose>
                    <When condition={content.contentSubmission?.status === 'COMPLETED'}>
                      <span key={content.id} className={index === contentIndex ? 'mx-1 rounded-2xl flex-grow h3 border-gray-300 border p-1' : 'mx-1 rounded-2xl flex-grow h3 p-1 border-white'}>
                        <span className='bg-blue-500 rounded-md w-full h-3 block' />
                      </span>
                    </When>
                    <Otherwise>
                      <span key={content.id} className={index === contentIndex ? 'mx-1 rounded-2xl flex-grow h3 border-gray-300 border-2 p-1' : 'mx-1 rounded-2xl flex-grow h3 p-1 border-white'}>
                        <span className='bg-gray-300 rounded-md w-full h-3 block' />
                      </span>
                    </Otherwise>
                  </Choose>
                </For>

                <button
                  className='flex justify-center items-center h-10 w-10 mr-2 rounded-md border shadow bg-white'
                  aria-label='Next content'
                  onClick={nextSection}
                >
                  <ChevronRightIcon className='w-6 h-6 text-black' />
                </button>
              </div>

              <If condition={currentContent}>
                <div className='overflow-auto px-5'>
                  <Choose>
                    <When condition={currentContent.__typename === 'Explanation'}>
                      <Explanation
                        key={currentContent.id}
                        heading={currentContent.heading}
                        text={currentContent.text}
                      />
                    </When>
                    <When condition={currentContent.__typename === 'MultipleChoiceQuestion'}>
                      <MultipleChoiceQuestion
                        key={currentContent.id}
                        id={currentContent.id}
                        questionText={currentContent.question}
                        text={currentContent.text}
                        selectedAnswerId={currentContent.lastSubmission?.answer?.id}
                        answers={currentContent.answers}
                      />
                    </When>
                  </Choose>
                </div>
              </If>
            </Tab.Panel>

            <Tab.Panel className='h-full'>
              <Choose>
                <When condition={project?.playground === 'COMPLETION'}>
                  <CompletionPlayground projectSubmissionId={id} />
                </When>
                <When condition={project?.playground === 'CHAT'}>
                  <ChatPlayground projectSubmissionId={id} />
                </When>
              </Choose>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </When>

      <Otherwise>
        <div className='flex flex-row items-center mb-3 m-5'>
          <button
            className='flex justify-center items-center h-10 w-10 mr-2 rounded-md border shadow bg-white'
            aria-label='Previous content'
            onClick={previousSection}
          >
            <ChevronLeftIcon className='w-6 h-6 text-black' />
          </button>

          <For each='content' index='index' of={project?.contents}>
            <Choose>
              <When condition={content.contentSubmission?.status === 'COMPLETED'}>
                <span key={content.id} className={index === contentIndex ? 'mx-1 rounded-2xl flex-grow h3 border-gray-300 border p-1' : 'mx-1 rounded-2xl flex-grow h3 p-1 border-white'}>
                  <span className='bg-blue-500 rounded-md w-full h-3 block' />
                </span>
              </When>
              <Otherwise>
                <span key={content.id} className={index === contentIndex ? 'mx-1 rounded-2xl flex-grow h3 border-gray-300 border-2 p-1' : 'mx-1 rounded-2xl flex-grow h3 p-1 border-white'}>
                  <span className='bg-gray-300 rounded-md w-full h-3 block' />
                </span>
              </Otherwise>
            </Choose>
          </For>

          <button
            className='flex justify-center items-center h-10 w-10 mr-2 rounded-md border shadow bg-white'
            aria-label='Next content'
            onClick={nextSection}
          >
            <ChevronRightIcon className='w-6 h-6 text-black' />
          </button>
        </div>

        <If condition={currentContent}>
          <div className='flex flex-row grid-flow-col h-full m-5 '>
            <div className='grow relative'>
              <div className='absolute top-0 bottom-0 left-0 right-0 overflow-auto pr-5'>
                <Choose>
                  <When condition={currentContent.__typename === 'Explanation'}>
                    <Explanation
                      key={currentContent.id}
                      heading={currentContent.heading}
                      text={currentContent.text}
                    />
                  </When>
                  <When condition={currentContent.__typename === 'MultipleChoiceQuestion'}>
                    <MultipleChoiceQuestion
                      key={currentContent.id}
                      id={currentContent.id}
                      questionText={currentContent.question}
                      text={currentContent.text}
                      selectedAnswerId={currentContent.lastSubmission?.answer?.id}
                      answers={currentContent.answers}
                    />
                  </When>
                </Choose>
              </div>
            </div>

            <Choose>
              <When condition={project?.playground === 'COMPLETION'}>
                <CompletionPlayground projectSubmissionId={id} />
              </When>
              <When condition={project?.playground === 'CHAT'}>
                <ChatPlayground projectSubmissionId={id} />
              </When>
            </Choose>
          </div>
        </If>
      </Otherwise>
    </Choose>
  )
}

export default ProjectSubmission
