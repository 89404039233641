import { Link } from 'react-router-dom'
import {
  CheckIcon,
  PlayIcon,
  ClipboardDocumentIcon,
  CodeBracketSquareIcon,
} from '@heroicons/react/24/outline'
import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import Card from '@components/Card'

import Project from './Project'
import Icon from './Icon'

const CREATE_PROJECT_SUBMISSION_MUTATION = gql`
  mutation createProjectSubmission($createProjectSubmissionInput: CreateProjectSubmissionInput!) {
    createProjectSubmission(input: $createProjectSubmissionInput) {
      projectSubmission {
        id
      }
    }
  }
`

const Overview = ({ unitName, unitSummary, nextProject, projectCount, sections, imageUrl, pathPrefix }) => {
  const { user: { role } } = useCurrentUser()
  const navigate = useNavigate()

  const { mutate: createProjectSubmission } = useMutation(
    ['startNextProject'],
    async projectId => request(CREATE_PROJECT_SUBMISSION_MUTATION, { createProjectSubmissionInput: { projectId } }),
    { onSuccess: data => navigate(`submissions/${data.createProjectSubmission.projectSubmission.id}`) }
  )

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label="Projects" to={`/${pathPrefix}/projects?tab=0`} />
        <BreadCrumbs.Text label={unitName} />
      </BreadCrumbs>

      <div className="flex flex-row items-center justify-between px-5 border-b-gray-200 border-b bg-white h-[60px]">
        <h1 className="font-heading text-2xl font-bold my-2">{unitName}</h1>
      </div>

      <div className="sm:m-5">
        <div className="flex flex-row gap-10 flex-wrap lg:flex-nowrap">
          <Card className="p-0 mb-3 basis-full lg:basis-2/5 grow h-fit shrink-0">
            <img className="object-cover h-[200px] w-full" src={imageUrl} />
            <div className="p-5">
              <p>{unitSummary}</p>

              <span className="flex flex-row items-center mt-3">
                <CodeBracketSquareIcon className="w-5 h-5 mr-2" />
                <p className="font-semibold">{projectCount} projects</p>
              </span>

              <If condition={role !== 'student'}>
                <Link className="flex flex-row items-center mt-3 text-blue-500" to={`lesson_plans`}>
                  <ClipboardDocumentIcon className="w-5 h-5 mr-2" />
                  <span className="font-semibold">Lesson plans</span>
                </Link>
              </If>
            </div>
          </Card>

          <div className="grow-0 basis-full">
            <For each="section" of={sections}>
              <h2 key={section.id} className="text-xl font-bold mb-3 sm:ml-0 ml-5">{section.name}</h2>

              <For index="index" each="project" of={section.projects}>
                <div key={project.id} className="flex flex-row mb-3">
                  <Choose>
                    <When condition={project.id === nextProject?.id}>
                      <Project
                        key={project.id}
                        name={project.name}
                        summary={project.summary}
                        theme="success"
                        icon={<PlayIcon className="h-8 w-8 mr-3" />}
                        onClick={() => createProjectSubmission(project.id)}
                      />
                    </When>

                    <When condition={project.projectSubmission?.status === 'IN_PROGRESS'}>
                      <Project
                        key={project.id}
                        name={project.name}
                        summary={project.summary}
                        theme="success"
                        icon={<PlayIcon className="h-8 w-8 mr-3" />}
                        onClick={() => navigate(`submissions/${project.projectSubmission.id}`)}
                      />
                    </When>

                    <When condition={project.projectSubmission?.status === 'COMPLETED'}>
                      <Project
                        key={project.id}
                        name={project.name}
                        summary={project.summary}
                        theme="light"
                        icon={<CheckIcon className="h-8 w-8 mr-3" />}
                        onClick={() => navigate(`submissions/${project.projectSubmission.id}`)}
                      />
                    </When>

                    <Otherwise>
                      <Project
                        key={project.id}
                        name={project.name}
                        summary={project.summary}
                        theme="light"
                        icon={<Icon index={index} />}
                      />
                    </Otherwise>
                  </Choose>
                </div>
              </For>
            </For>
          </div>
        </div>
      </div>
    </>
  )
}

export default Overview
