import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import CodeBlock from './CodeBlock'

const Markdown = ({ content }) => (
  <ReactMarkdown
    linkTarget="_blank"
    className="markdown-content"
    children={content}
    remarkPlugins={[remarkGfm]}
    rehypePlugins={[rehypeRaw]}
    components={{ code: CodeBlock }}
  />
)

export default Markdown
