import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline'
import { TourProvider, useTour } from '@reactour/tour'

const Tour = ({ steps, beforeClose, children }) => (
  <TourProvider
    disableWhenSelectorFalsy
    className="rounded-lg mt-3 leading-tight text-lg"
    showCloseButton={false}
    showBadge={false}
    showDots={false}
    steps={steps}
    beforeClose={beforeClose}
    prevButton={({
      currentStep,
      setCurrentStep
    }) => {
      const first = currentStep === 0

      return (
        <button
          className="p-3 rounded-md hover:bg-gray-100 disabled:cursor-not-allowed disabled:hover:bg-white disabled:text-gray-400"
          disabled={first}
          onClick={() => setCurrentStep(s => s - 1)}
        >
          <ArrowLeftIcon className="h-6 w-6" />
        </button>
      )
    }}
    nextButton={({
      currentStep,
      stepsLength,
      setIsOpen,
      setCurrentStep,
      steps,
    }) => {
      const last = currentStep === stepsLength - 1

      return (
        <button
          className="p-3 rounded-md hover:bg-gray-100"
          onClick={() => {
            if (last) {
              setIsOpen(false)
            } else {
              setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
            }
          }}
        >
          <Choose>
            <When condition={last}>
              <CheckIcon className="h-6 w-6" />
            </When>

            <Otherwise>
              <ArrowRightIcon className="h-6 w-6" />
            </Otherwise>
          </Choose>
        </button>
      )
    }}
  >
    {children}
  </TourProvider>
)

export default Tour

export { useTour }
