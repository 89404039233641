export default [
  {
    selector: '[data-tutorial="message-input-step"]',
    content: () => (
      <div>
        <p>⌨️ Type your message here. Hit <strong>Enter</strong> to send.</p>
        <p>Need to type more than one line? <strong>Shift&nbsp;+&nbsp;Enter</strong> is your friend.</p>
      </div>
    )
  },
  {
    selector: '[data-tutorial="start-recording-step"]',
    content: 'Prefer to talk? Hit the microphone button and start speaking, we\'ll type what you say. 🗣️'
  },
  {
    selector: '[data-tutorial="copy-message-step"]',
    content: 'Copy any message to your clipboard with this handy button. 📋'
  },
  {
    selector: '[data-tutorial="chat-text-to-speech-step"]',
    content: 'Prefer to listen? Click here to hear the message read aloud. 🎧'
  },
  {
    selector: '[data-tutorial="delete-chat-step"]',
    content: 'You can delete a conversation by clicking this button.'
  },
  {
    selector: '[data-tutorial="assign-tutor-step"]',
    content: () => (
      <div>
        <p>Ready to try it with your class? Click here to push your Tutor out to your students 👥</p>
        <p>Let us know what you do with it! 🚀</p>
      </div>
    )
  }
]
