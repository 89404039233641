import { useState, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { DocumentDuplicateIcon, CheckIcon, PlayIcon, PauseIcon } from '@heroicons/react/24/outline'

import { formatShortTimestamp } from '@helpers/format'
import SecureMarkdown from '@components/SecureMarkdown'
import Image from '@components/Image'

const Message = ({
  id,
  status,
  from,
  text,
  sourceName,
  sourceLink,
  createdAt,
  trackAudioPlayed,
  attachments = []
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const spokenText = useRef(null)
  const latestText = useRef(null)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
  }

  const playAudio = (text) => {
    setIsPlaying(true)
    spokenText.current = latestText.current

    const utterance = new SpeechSynthesisUtterance(text)

    utterance.rate = 0.9
    utterance.onend = () => {
      // Check whether text read out is different from the latest text
      if (spokenText.current !== latestText.current) {
        // Get the difference between the two texts from the end
        const diff = latestText.current.slice(spokenText.current.length)

        // Play the difference
        spokenText.current = latestText.current
        playAudio(diff)
      } else {
        // Stop playing and reset
        setIsPlaying(false)
        spokenText.current = null
      }
    }

    window.speechSynthesis.cancel()
    window.speechSynthesis.speak(utterance)

    if (trackAudioPlayed) trackAudioPlayed()
  }

  const pauseAudio = () => {
    window.speechSynthesis.cancel()
    setIsPlaying(false)
    spokenText.current = null
  }

  useEffect(() => {
    latestText.current = text
  }, [text])

  return (
    <div className={twMerge('flex flex-col max-w-[75%] mb-3', from === 'USER' ? 'self-end' : 'self-start')}>
      <Choose>
        <When condition={from === 'ASSISTANT'}>
          <div className='flex'>
            <button
              aria-label='Copy text to clipboard'
              className='mb-1 w-fit rounded-md p-1 cursor-pointer hover:bg-gray-100'
              onClick={() => {
                copyToClipboard()
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 1000)
              }}
            >
              {isCopied ? <CheckIcon className='h-5 w-5' /> : <DocumentDuplicateIcon data-tutorial='copy-message-step' className='h-5 w-5' />}
            </button>

            <Choose>
              <When condition={isPlaying}>
                <button
                  aria-label='Pause audio'
                  className='mb-1 w-fit rounded-md p-1 cursor-pointer hover:bg-gray-100'
                  onClick={pauseAudio}
                >
                  <PauseIcon className='h-5 w-5' />
                </button>
              </When>

              <Otherwise>
                <button
                  id={`play-audio-${id}`}
                  aria-label='Play audio'
                  className='relative mb-1 w-fit group rounded-md p-1 cursor-pointer hover:bg-gray-100 disabled:cursor-not-allowed'
                  onClick={() => playAudio(text)}
                >
                  <PlayIcon
                    data-tutorial='chat-text-to-speech-step'
                    className='h-5 w-5 group-disabled:text-gray-400'
                  />
                </button>
              </Otherwise>
            </Choose>
          </div>

          <div className='w-full flex flex-row self-end'>
            <div className='w-full border shadow rounded-lg p-2 overflow-x-auto bg-blue-100 border-blue-900'>
              <SecureMarkdown content={text} />
            </div>
          </div>

          <If condition={sourceName && sourceLink}>
            <div className='flex justify-between'>
              <a href={sourceLink} className='mt-1 text-blue-500 text-sm'>
                Computed by {sourceName}
              </a>
            </div>
          </If>

          <small className='self-end mt-1 ml-auto'>{formatShortTimestamp(createdAt)}</small>
        </When>

        <Otherwise>
          <If condition={attachments.length > 0}>
            <div className='flex gap-3 mb-3'>
              <For each='attachment' of={attachments}>
                <Image
                  className='ml-auto object-cover w-auto max-h-[200px] rounded-lg'
                  placeholder={<div className='w-[300px] h-[200px] bg-gray-200 animate-pulse rounded-lg' />}
                  key={attachment.filename}
                  src={attachment.url}
                  alt={attachment.filename}
                />
              </For>
            </div>
          </If>

          <If condition={text}>
            <div className='w-auto ml-auto flex flex-row self-end'>
              <div className={twMerge(
                'w-auto border shadow rounded-lg p-2 overflow-x-auto',
                status === 'MODERATION' ? 'bg-orange-100 border-gray-500' : 'bg-white border-gray-500'
              )}
              >
                <SecureMarkdown content={text} />
              </div>
            </div>
          </If>

          <If condition={status === 'MODERATION'}>
            <small className='self-end mt-1'>Sorry, I can't respond to this message</small>
          </If>

          <small className='self-end mt-1 ml-auto'>{formatShortTimestamp(createdAt)}</small>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default Message
