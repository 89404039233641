import BreadCrumbs from '@components/Breadcrumbs'
import PageHeading from '@components/PageHeading'
import Spinner from '@components/Spinner'

const Skeleton = () => (
  <>
    <BreadCrumbs>
      <BreadCrumbs.Link label="Projects" to="/educators/projects?tab=1" />
      <div className="w-20 h-4 bg-gray-200" />
      <div className="w-20 h-4 bg-gray-200" />
    </BreadCrumbs>

    <PageHeading title={
      <span className="flex items-center">
        <span className="w-32 h-6 bg-gray-200 mr-2" />

        <div className="w-16 h-6 bg-gray-200 rounded-full" />
      </span>
    }
    />

    <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />
  </>
)

export default Skeleton
