import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const Checkbox = forwardRef(({ label, labelClassName, onChange, required, className, ...props }, ref) => (
  <div className={twMerge("flex flex-row justify-start items-center", className)}>
    <input
      className="mr-3 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
      type="checkbox"
      onChange={onChange}
      ref={ref}
      {...props}
    />
    <label className={twMerge("leading-tight", labelClassName)} labelfor={props.id}>{label}</label>
  </div>
))

export default Checkbox
