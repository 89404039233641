import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAnalytics } from '@contexts/analytics'
import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import Card from '@components/Card'
import Button from '@components/Button'

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation completeOnboarding {
    completeOnboarding(input: {}) {
      educatorProfile {
        onboardingWelcomeCompleted
      }
    }
  }
`

const Welcome = () => {
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const { setUser, user } = useCurrentUser()
  const { mutate: completeOnboarding } = useMutation({
    mutationFn: async () => request(COMPLETE_ONBOARDING_MUTATION),
    onSuccess: () => {
      setUser({ ...user, onboarding_welcome_completed: true })
      track('Educator Onboarding Step Completed', { step: 'welcome' })

      const tutorId = localStorage.getItem('signUpTutorId')

      if (tutorId) {
        localStorage.removeItem('signUpTutorId')
        navigate(`/educators/tutors/${tutorId}`)
      } else {
        navigate('/educators/tutors?audience=community')
      }
    }
  })

  const next = () => completeOnboarding()

  return (
    <div className='w-full flex justify-center'>
      <Card className='flex flex-col sm:w-[600px] p-5 gap-5 mt-10'>
        <h3 className='font-heading text-2xl font-bold'>Welcome to Mindjoy 👋</h3>

        <iframe
          width='100%'
          height='300'
          src='https://www.youtube-nocookie.com/embed/Ky_vg7cZSc4?si=VQN6TTVJEyByxSqF'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        />

        <Button
          onClick={next}
          className='ml-auto motion-safe:animate-[bounce_1s_ease-in-out_3.5]'
          theme='primary'
          label='Get started'
        />
      </Card>
    </div>
  )
}

export default Welcome
