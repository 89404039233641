import { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'

import { useChannel } from '@contexts/actionCable'
import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'

import Message from '../../educators/components/Message'

const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation sendChatMessage($sendChatMessageInput: SendChatMessageInput!) {
    sendChatMessage(input: $sendChatMessageInput) {
      chatMessages(perPage: 10) {
        nodes {
          id
          status
          from
          text
          createdAt
        }
      }
    }
  }
`

const CHAT_MESSAGES_QUERY = gql`
  query projectSubmission($projectSubmissionId: ID!, $page: Int!) {
    projectSubmission(id: $projectSubmissionId) {
      chatMessages(page: $page, perPage: 10) {
        pagesCount
        nodesCount
        nodes {
          id
          status
          from
          text
          createdAt
        }
      }
    }
  }
`

const handleResize = event => {
  event.target.style.height = '50px'
  event.target.style.height = `${event.target.scrollHeight}px`
}

const ChatPlayground = ({ projectSubmissionId }) => {
  const { subscribe, unsubscribe } = useChannel()
  const queryClient = useQueryClient()
  const messageThreadRef = useRef(null)
  const { register, handleSubmit, reset } = useForm({ mode: 'onTouched' })

  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['chatMessages', projectSubmissionId],
    queryFn: async ({ pageParam = 1 }) => request(CHAT_MESSAGES_QUERY, { projectSubmissionId, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.projectSubmission.chatMessages.pagesCount > pages.length) {
        return pages.length + 1
      }

      return false
    },
    select: (data) => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse(),
    }),
  })

  const {
    mutate: sendChatMessage,
    isLoading: isSending,
  } = useMutation({
    mutationFn: async variables => request(CREATE_CHAT_MESSAGE_MUTATION, variables),
    onSuccess: newData => {
      queryClient.setQueryData(['chatMessages', projectSubmissionId], oldData => {
        const oldNodes = oldData.pages[0].projectSubmission.chatMessages.nodes
        const newNodes = newData.sendChatMessage.chatMessages.nodes
        const mergedNodes = [...newNodes]

        // Add old nodes that are not in the new nodes, excluding temp
        oldNodes.forEach(node => {
          const foundNode = mergedNodes.find(n => n.id === node.id)

          if (!foundNode && !node.temp) {
            mergedNodes.push(node)
          }
        })

        return {
          ...oldData,
          pages: [
            {
              ...oldData.pages[0],
              projectSubmission: {
                ...oldData.pages[0].projectSubmission,
                chatMessages: {
                  nodes: mergedNodes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                },
              },
            },
            ...oldData.pages.slice(1),
          ],
        }
      })

      setTimeout(() => messageThreadRef.current.scrollTo(0, messageThreadRef.current.scrollHeight))
    }
  })

  const submitMessage = data => {
    sendChatMessage({ sendChatMessageInput: { projectSubmissionId, text: data.text } })
    reset()

    queryClient.setQueryData(['chatMessages', projectSubmissionId], oldData => ({
      ...oldData,
      pages: [
        {
          ...oldData.pages[0],
          projectSubmission: {
            ...oldData.pages[0].projectSubmission,
            chatMessages: {
              ...oldData.pages[0].projectSubmission.chatMessages,
              nodes: [
                {
                  ...data,
                  from: 'USER',
                  createdAt: new Date().toISOString(),
                  temp: true
                },
                ...oldData.pages[0].projectSubmission.chatMessages.nodes,
              ],
            },
          },
        },
        ...oldData.pages.slice(1),
      ],
    }))

    setTimeout(() => messageThreadRef.current.scrollTo(0, messageThreadRef.current.scrollHeight))
  }

  useEffect(() => {
    if (isSuccess) {
      messageThreadRef.current.scrollTo(0, messageThreadRef.current.scrollHeight)
    }
  }, [isSuccess])

  useEffect(() => {
    subscribe({
      channel: 'ProjectSubmissionChannel',
      project_submission_id: projectSubmissionId
    }, {
      received: chatMessage => {
        queryClient.setQueryData(['chatMessages', projectSubmissionId], oldData => {
          const oldNodes = oldData.pages[0].projectSubmission.chatMessages.nodes
          const newNodes = oldNodes.map(node =>
            node.id === chatMessage.id
              ? { ...node, text: chatMessage.text }
              : node
          )

          if (!newNodes.find(n => n.id === chatMessage.id)) {
            newNodes.unshift(chatMessage)
          }

          return {
            ...oldData,
            pages: [
              {
                ...oldData.pages[0],
                projectSubmission: {
                  ...oldData.pages[0].projectSubmission,
                  chatMessages: { nodes: newNodes },
                },
              },
              ...oldData.pages.slice(1),
            ],
          }
        })

        messageThreadRef.current.scrollTo(0, messageThreadRef.current.scrollHeight)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <div className="md:w-1/2 relative flex flex-col h-full md:pl-5 px-3">
      <div className="absolute top-0 right-0 left-0 h-10 z-10 bg-gradient-to-b from-gray-50 to-transparent" />

      <div className="relative h-full grow">
        <div ref={messageThreadRef} className="absolute flex flex-col top-0 bottom-0 left-0 right-0 overflow-auto pt-10">
          <Choose>
            <When condition={status === 'loading'}>
              <Spinner className="self-center" />
            </When>

            <Otherwise>
              <Choose>
                <When condition={isFetchingNextPage}>
                  <Spinner className="self-center mb-3" />
                </When>

                <When condition={hasNextPage}>
                  <button
                    type="button"
                    className="self-center text-blue-500 mb-3"
                    onClick={fetchNextPage}>
                    load more
                  </button>
                </When>
              </Choose>

              <If condition={data?.pages?.[0]?.projectSubmission?.chatMessages?.pagesCount == 0}>
                <div className="h-full flex flex-col justify-center items-center">
                  <div className="bg-purple-100 text-center p-3 rounded-xl w-2/3">
                    <h3 className="font-bold">Welcome to JoyBot 📚</h3>
                    <p className="mt-3 text-sm">
                      JoyBot is a software program created to help you learn with AI. Responses are aren't always factually accurate. When working with AI, your job is to be curious, responsible and have fun!
                    </p>
                  </div>
                </div>
              </If>

              <For each="page" of={data.pages} index="index">
                <div key={`page-${index}`} className="flex flex-col-reverse">
                  <For each="message" of={page.projectSubmission.chatMessages.nodes}>
                    <Message
                      key={message.id}
                      status={message.status}
                      text={message.text}
                      from={message.from}
                      createdAt={message.createdAt}
                    />
                  </For>
                </div>
              </For>
            </Otherwise>
          </Choose>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(submitMessage)}
        className="flex flex-col relative">
        <textarea
          onKeyDown={ev => {
            if (ev.key === 'Enter' && !ev.shiftKey) {
              ev.preventDefault()
              handleSubmit(submitMessage)()
            }
          }}
          className="w-full h-[40px] block font-normal rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6 resize-none"
          {...register('text', { required: true, onChange: handleResize })}
        />

        <div className="absolute right-0 top-0 bottom-0 flex flex-row items-center">
          <button
            className="focus:outline-none group"
            disabled={isSending}
            type="submit">
            <PaperAirplaneIcon className="h-9 w-9 p-1 mr-2 hover:bg-grey-100 rounded-lg group-focus:bg-gray-200" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChatPlayground
