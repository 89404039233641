import { useEffect, useMemo, useRef } from 'react'
import { debounce } from 'lodash'

const useDebounce = (callback, delay = 1000) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, delay)
  }, [])

  return debouncedCallback
}

export {
  useDebounce
}
