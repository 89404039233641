import { gql } from 'graphql-request'
import { useParams, Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { capitalize } from 'lodash'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Card from '@components/Card'
import Pill from '@components/Pill'
import Breadcrumbs from '@components/Breadcrumbs'
import PageHeading from '@components/PageHeading'

import Question from './Question'
import Explanation from './Explanation'
import CompletionHistory from './CompletionHistory'
import ChatMessageHistory from './ChatMessageHistory'

const PROJECT_SUBMISSION_QUERY = gql`
  query projectSubmission($id: ID!) {
    projectSubmission(id: $id) {
      id
      status
      profile {
        fullName
      }
      project {
        id
        name
        playground
        contents {
          id
          contentSubmission {
            id
            createdAt
            updatedAt
            status
          }
          ... on Explanation {
            heading
            text
            __typename
          }
          ... on MultipleChoiceQuestion {
            id
            question
            text
            answers {
              id
              text
              correct
            }
            submissions {
              id
              createdAt
              correct
              answer {
                id
                text
                correct
              }
            }
            __typename
          }
        }
      }
    }
  }
`

const CLASSROOM_QUERY = gql`
  query classroom($id: ID!) {
    classroom(id: $id) {
      id
      name
      studentProfiles {
        id
        fullName
        email
      }
      educatorProfile {
        id
        fullName
      }
    }
  }
`

const Submission = () => {
  const { id, classroomId } = useParams()
  const { data: { classroom = {} } = {} } = useQuery(['classroom', classroomId], async () => request(CLASSROOM_QUERY, { id: classroomId }))

  const { isLoading, data: { projectSubmission: { project, status, profile } = {} } = {} } = useQuery({
    queryKey: ['projectSubmission', id],
    queryFn: async () => request(PROJECT_SUBMISSION_QUERY, { id })
  })

  if (isLoading) return <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />

  const statusLabel = capitalize(status.replace('_', ' '))
  const completedSectionsCount = project.contents.filter(content => content.contentSubmission?.status === 'COMPLETED').length
  const totalSectionsCount = project.contents.length

  const totalQuestionsCount = project.contents.filter(content => content.__typename === 'MultipleChoiceQuestion').length
  const correctQuestionsCount = project.contents.filter(content => content.__typename === 'MultipleChoiceQuestion' && content.submissions?.[0]?.correct).length

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.Link label="Classrooms" to="/educators/classrooms" />
        <Breadcrumbs.Link label={classroom.name} to={`/educators/classrooms/${classroomId}`} />
        <Breadcrumbs.Text label="Submission" />
      </Breadcrumbs>

      <PageHeading
        title={
          <span className="flex items-center text-sm md:text-2xl">
            {project.name}
            <Pill className="ml-2" theme={status === 'COMPLETED' ? 'success' : ''} label={statusLabel} />
          </span>
        }
      />

      <div className="sm:p-5">
        <p className="p-3 md:p-0">{profile.fullName} in {classroom.name}</p>

        <div className="flex sm:flex-row flex-col gap-10">
          <div className="sm:basis-1/2">
            <div className="flex sm:flex-row flex-col">
              <Card className="mb-6 mt-3 p-3 sm:mr-3">
                <p><span className="font-bold">{completedSectionsCount}/{totalSectionsCount}</span> sections completed</p>
              </Card>

              <Card className="mb-6 mt-3 p-3 sm:mr-3">
                <p><span className="font-bold">{correctQuestionsCount}/{totalQuestionsCount}</span> questions correct</p>
              </Card>
            </div>

            <For each="content" of={project.contents}>
              <If condition={content.__typename === 'Explanation'}>
                <Explanation
                  status={content.contentSubmission?.status}
                  heading={content.heading}
                  createdAt={content?.contentSubmission?.createdAt}
                />
              </If>

              <If condition={content.__typename === 'MultipleChoiceQuestion'}>
                <Question
                  status={content.contentSubmission?.status}
                  question={content.question}
                  createdAt={content?.contentSubmission?.createdAt}
                  submissions={content.submissions}
                />
              </If>
            </For>
          </div>

          <Choose>
            <When condition={project.playground === 'COMPLETION'}>
              <CompletionHistory projectSubmissionId={id} />
            </When>

            <When condition={project.playground === 'CHAT'}>
              <ChatMessageHistory projectSubmissionId={id} />
            </When>
          </Choose>
        </div>
      </div>
    </>
  )
}

export default Submission
