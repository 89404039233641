export default [
  {
    selector: '[data-tutorial="new-tutor-name-step"]',
    content: 'Start off by giving your tutor a name.'
  },
  {
    selector: '[data-tutorial="new-tutor-description-step"]',
    content: 'Write the prompt for your tutor by describing how the AI should respond.'
  },
  {
    selector: '[data-tutorial="new-tutor-knowledge-base-step"]',
    content: 'Turn on knowledge base to upload documents that the tutor can use to respond to questions.'
  },
  {
    selector: '[data-tutorial="new-tutor-socratic-step"]',
    content: 'Turn on socratic mode if you\'d like the tutor to ask questions instead of giving direct answers.'
  },
  {
    selector: '[data-tutorial="new-tutor-suggestion-step"]',
    content: 'Turn on suggested questions if you\'d like the tutor to suggest followup questions to ask.'
  },
  {
    selector: '[data-tutorial="new-tutor-visibility-step"]',
    content: 'Choose who your tutor is available to. You can keep it private, share it with educators in your organization or make it available publicly. You can always change this later.'
  },
  {
    selector: '[data-tutorial="new-tutor-create-tutor-button"]',
    content: 'When you\'re ready, go ahead and save your tutor.'
  }
]
