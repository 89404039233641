import { useEffect, useState, useRef } from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { tokyoNightStorm } from '@uiw/codemirror-theme-tokyo-night-storm'
import { PlayIcon, ChatBubbleLeftIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { Transition } from '@headlessui/react'
import { usePython } from 'react-py'
import { twMerge } from 'tailwind-merge'

import { useAnalytics } from '@contexts/analytics'
import { useDebounce } from '@hooks/debounce'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Spinner from '@components/Spinner'
import ChatPlayground from '../ChatPlayground'

const SAVE_CODE_FILE_MUTATION = gql`
  mutation saveCodeFile($input: SaveCodeFileInput!) {
    saveCodeFile(input: $input) {
      codeFile {
        content
        language
      }
    }
  }
`

const CODE_FILE_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        codeFile {
          content
          language
        }
      }
    }
  }
`

const CodingPlayground = ({ submissionId }) => {
  const { mutate: saveCodeFile } = useMutation({
    mutationFn: async variables => await request(SAVE_CODE_FILE_MUTATION, { input: { educatorProjectSubmissionId: submissionId, ...variables } }),
  })
  useQuery({
    queryKey: ['codeFile', submissionId],
    queryFn: async () => await request(CODE_FILE_QUERY, { id: submissionId }),
    onSuccess: data => {
      const codeFile = data.node.codeFile
      if (codeFile) setCode(data.node.codeFile.content)
    }
  })
  const { track } = useAnalytics()
  const inputRef = useRef(null)
  const [code, setCode] = useState('')
  const [input, setInput] = useState('')
  const [isChatOpen, setIsChatOpen] = useState(false)

  const {
    runPython,
    stdout,
    stderr,
    isLoading,
    isRunning,
    sendInput,
    isAwaitingInput,
    prompt,
  } = usePython()

  useEffect(() => {
    if (isAwaitingInput) {
      inputRef.current.focus()
    }
  }, [isAwaitingInput])

  const debouncedSave = useDebounce(() => {
    saveCodeFile({ content: code })
  }, 2000)

  const run = async () => {
    await runPython(code)

    track('Code Playground Run', { educatorProjectSubmissionId: submissionId })
  }

  const send = async () => {
    if (isAwaitingInput) {
      sendInput(input)
    } else {
      await runPython(input)
    }

    setInput('')
  }

  return (
    <div className="w-full flex flex-col sm:pl-3 pl-5 pr-5 h-full">
      <Button
        disabled={isLoading || isRunning}
        theme="success"
        className="my-3 self-end"
        onClick={run}
        label={
          <Choose>
            <When condition={isLoading}>
              <span className="flex items-center"><Spinner className="mr-3 [&_*]:bg-white" />Loading environment</span>
            </When>

            <When condition={isRunning}>
              <span className="flex items-center"><Spinner className="mr-3 [&_*]:bg-white" />{isAwaitingInput ? 'Awaiting input' : 'Running'}</span>
            </When>

            <Otherwise>
              <span className="flex items-center"><PlayIcon className="w-5 h-5 mr-3" />Run</span>
            </Otherwise>
          </Choose>
        }
      />

      <div className="h-full overflow-clip rounded-lg">
        <CodeMirror
          value={code}
          onChange={value => {
            setCode(value)
            debouncedSave()
          }}
          className="text-base h-full"
          theme={tokyoNightStorm}
          height="100%"
          extensions={[python()]}
          basicSetup={{ highlightActiveLine: false, foldGutter: false }}
          placeholder="Write your code here..."
        />
      </div>

      <div className="my-3 b-gray-300 border rounded-lg bg-gray-100 lg:h-[400px] h-[200px] max-h-[300px] grow overflow-scroll">
        <pre className="w-full h-full p-3 text-sm">
          <code className={twMerge("whitespace-pre")}>
            {stdout}
          </code>

          <code className={twMerge("whitespace-pre text-red-500")}>
            {stderr}
          </code>

          <div className="flex text-sm">
            <Choose>
              <When condition={isAwaitingInput}>
                {prompt + ' '}
              </When>

              <Otherwise>
                <span className="text-purple-500 font-bold">&gt;&gt;&gt; </span>
              </Otherwise>
            </Choose>

            <input
              ref={inputRef}
              style={{ boxShadow: 'none' }}
              className="w-full h-5 bg-gray-100 outline-none p-0 border-0 caret-bl text-sm"
              value={input}
              onChange={e => setInput(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && send()}
            />
          </div>
        </pre>
      </div>

      <Transition
        show={isChatOpen}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="absolute bottom-0 right-0 h-full sm:w-11/12 w-full px-5 pt-3 pb-[80px]">
          <div className="bg-white rounded-md h-full shadow-lg shadow-gray-400 border-gray-200 border overflow-auto w-full">
            <ChatPlayground submissionId={submissionId} />
          </div>
        </div>
      </Transition >

      <Button
        onClick={() => setIsChatOpen(!isChatOpen)}
        className="shadow-lg shadow-gray-400 absolute bottom-0 right-0 mr-5 mb-3 rounded-full h-[50px] w-[50px] flex items-center justify-center"
        label={isChatOpen ? <XMarkIcon className="w-5 h-5" /> : <ChatBubbleLeftIcon className="w-5 h-5" />}
        theme="secondary" />
    </div >
  )
}

export default CodingPlayground
