import { forwardRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link'
]

const RichTextEditor = forwardRef(({ onChange, readOnly, ...props }, ref) => (
  <ReactQuill
    modules={modules}
    formats={formats}
    ref={ref}
    readOnly={readOnly}
    className="h-full quill-editor-content"
    theme="snow"
    onChange={onChange}
    {...props}
  />
))

export default RichTextEditor
