const ProgressBar = ({ blocks, blockSubmissions, currentBlockIndex }) => {
  return (
    <div className="flex flex-row items-center mb-3">
      <For each="block" index="index" of={blocks}>
        <With blockSubission={blockSubmissions.find((s) => s.blockId === block.blockId)}>
          <Choose>
            <When condition={blockSubission?.status === "COMPLETED"}>
              <span key={block.id} className={index === currentBlockIndex ? 'rounded-2xl flex-grow h2 border-gray-400 border p-1' : 'rounded-2xl flex-grow h3 p-1 border-white'}>
                <span className="bg-blue-500 rounded-md w-full h-2 block" />
              </span>
            </When>

            <Otherwise>
              <span key={block.id} className={index === currentBlockIndex ? 'rounded-2xl flex-grow h2 border-gray-400 border p-1' : 'rounded-2xl flex-grow h3 p-1 border-white'}>
                <span className="bg-gray-300 rounded-md w-full h-2 block" />
              </span>
            </Otherwise>
          </Choose>
        </With>
      </For>
    </div>
  )
}

export default ProgressBar
