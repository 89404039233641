import { useParams } from 'react-router-dom'

import BreadCrumbs from '@components/Breadcrumbs'

import { useEducatorProject } from './hooks'
import Overview from './Overview'

const EducatorProjectDetails = () => {
  const { id } = useParams()
  const { project, status, refetch } = useEducatorProject({ id })

  if (status === 'loading') return

  const projectName = project?.name || 'Untitled project'
  const seriesName = project?.series?.name || 'Untitled series'

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='My courses' to='/educators/courses' />
        <BreadCrumbs.Link label={seriesName} to={`/educators/courses/${project.series.id}`} />
        <BreadCrumbs.Text label={projectName} />
      </BreadCrumbs>

      <Overview {...project} refetch={refetch} />
    </>
  )
}

export default EducatorProjectDetails
