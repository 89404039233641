import { forwardRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

const TextInput = forwardRef(({ submitForm, ...props }, ref) => {
  return (
    <TextareaAutosize
      ref={ref}
      {...props}
      className='w-full min-h-[40px] block font-normal rounded-md border-0 px-0 py-1.5 leading-tight text-gray-900 placeholder:text-gray-400 border-none focus:ring-0 focus:border-none focus-within:outline-none text-lg sm:leading-6 resize-none'
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault()

          submitForm()
        }
      }}
    />
  )
})

export default TextInput
