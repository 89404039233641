import { TrashIcon } from '@heroicons/react/24/outline'
import { ArrowUpCircleIcon } from '@heroicons/react/24/solid'

const formatCounter = (seconds) => `0:${seconds.toString().padStart(2, '0')}`

const AudioRecorder = ({ cancelRecording, stopRecording, secondsRecorded }) => {
  return (
    <div className='w-full flex items-center justify-start h-min-[40px]'>
      <button
        aria-label='Cancel recording'
        onClick={cancelRecording}
        className='mr-3'
        type='button'
      >
        <TrashIcon className='h-8 w-8 p-1 text-gray-600 rounded-lg' />
      </button>

      <div className='flex rounded-full items-center bg-gray-200 px-3 py-1'>
        <div class='w-3 h-3 bg-red-500 rounded-full mr-2' />

        <p className='leading-none mt-1'>
          {formatCounter(secondsRecorded)}
        </p>
      </div>

      <div className='flex ml-auto items-center'>
        <button
          onClick={stopRecording}
          className='focus:outline-none group ml-auto'
          type='button'
        >
          <ArrowUpCircleIcon className='h-10 w-10 text-gray-600' />
        </button>
      </div>
    </div>
  )
}

export default AudioRecorder
