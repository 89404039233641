import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextArea from '@components/TextArea'
import { useFlashMessage } from '@components/FlashMessage'
import PageHeading from '@components/PageHeading'

const INVITE_EDUCATORS_TO_SIGN_UP_MUTATION = gql`
  mutation inviteEducatorsToSignUp($input: InviteEducatorsToSignUpInput!) {
    inviteEducatorsToSignUp(input: $input) {
      success
      errors
    }
  }
`

const Invitations = () => {
  const { setFlashMessage } = useFlashMessage()
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm({ mode: 'onTouched' })
  const { mutateAsync: inviteEducators, isLoading: sending } = useMutation({
    mutationFn: async variables => request(INVITE_EDUCATORS_TO_SIGN_UP_MUTATION, variables),
    onSettled: response => {
      const data = response?.inviteEducatorsToSignUp

      if (data.success === true) {
        setFlashMessage('Invitations sent', 'success')
        reset()
        closeModal()
      } else {
        const message = data.errors[0] || 'Something went wrong'
        setError('emailAddresses', { message })
      }
    }
  })

  const submit = ({ emailAddresses }) => {
    inviteEducators({ input: { emailAddresses } })
  }

  return (
    <>
      <PageHeading title="Invitations" />

      <div className="md:p-5 pt-3">
        <Card className="p-5">
          <h2 className="font-heading text-2xl font-bold mb-3">Invite educators to the beta</h2>

          <form
            onSubmit={handleSubmit(submit)}
            className="flex flex-col">
            <TextArea
              id="email-addresses"
              label="Educator email addresses"
              className="h-20"
              autoFocus
              required
              {...register('emailAddresses', { required: "Email addresses are required", pattern: { value: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}(\s*,?\s*)*)+$/i, message: "Email addresses must be valid" } })}
            />

            <If condition={errors.emailAddresses}>
              <p className="text-sm mt-1 text-red-500 font-semibold" role="alert">{errors.emailAddresses.message}</p>
            </If>

            <p className="text-sm mt-1 inline flex-row items-center leading-3">
              Invite multiple educators by listing their email addresses separated by a comma, or by pasting the contents of a CSV file.
            </p>

            <Button
              disabled={sending}
              className="w-fit ml-auto"
              type="submit"
              label={sending ? "Sending..." : "Send invitations"}
            />
          </form>
        </Card>
      </div>
    </>
  )
}

export default Invitations
