import { useQuery } from '@tanstack/react-query'
import { useCurrentUser } from '@contexts/currentUser'
import { Link, useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import Table from '@components/Table'
import { capitalize, orderBy, round } from 'lodash'
import Heading from '@components/Heading'
import Card from '../../../components/Card'
import { formatTimestamp, formatDuration } from '@helpers/format'
import {
  ArrowTrendingUpIcon,
  BellAlertIcon,
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  XMarkIcon,
  InformationCircleIcon,
  CloudArrowDownIcon
} from '@heroicons/react/24/outline'
import { ResponsiveContainer, BarChart, ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Cell, Scatter } from 'recharts'
import jsonToCsvExport from 'json-to-csv-export'
import Select from '@components/Select'
import Button from '@components/Button'

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      privateStudentChatsEnabled
    }
  }
`

const CLASSROOM_INSIGHTS_QUERY = gql`
  query anonymousClassroomInsights($classroomId: ID!, $insightableId: ID!) {
    anonymousClassroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      __typename
      ... on Insight {
        totalTimeSeconds
        activeTimeSeconds
        questionsAsked
        questionsAnswered
        answerQuality
        group
        misconceptionsConcepts
        misconceptionsDetails
        misconceptionsSyllabusConnection
      }
      ... on AnonymousInsight {
        totalTimeSeconds
        activeTimeSeconds
        questionsAsked
        questionsAnswered
        answerQuality
        group
      }
    }
  }
`

const TUTOR_ASSIGNMENT_QUERY = gql`
  query tutorAssignment($id: ID!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        tutor {
          name
        }
        classroom {
          id
        }
      }
    }
  }
`

const STUDENT_INSIGHTS_QUERY = gql`
  query studentInsights($insightableId: ID!) {
    studentInsights(insightableId: $insightableId) {
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      questionCountDefinition
      questionCountConceptual
      questionCountProcedural
      questionCountVerification
      questionCountOther
      questionExampleDefinition
      questionExampleConceptual
      questionExampleProcedural
      questionExampleVerification
      questionExampleOther
      questionAdvice
      aiAdvice
      answerCountIncorrect
      answerCountPartiallyCorrect
      answerCountCorrect
      answerCountSingleWord
      answerCountOther
      answerExampleIncorrect
      answerExamplePartiallyCorrect
      answerExampleCorrect
      answerExampleSingleWord
      answerExampleOther
      answerAdvice
      recommendationsFocusedStudyAreas
      recommendationsEngagement
      recommendationsCriticalThinking
      recommendationsBehaviorIndicators
      recommendationsImprovements
      skillsDemonstrated
      areasForImprovement
      misconceptionsDetails
      misconceptionsConcepts
      misconceptionsEvidence
      misconceptionsSyllabusConnection
    }
  }
`

const TutorInsights = () => {
  const { id } = useParams()

  const { data: { organization = {} } = {}, isFetched } = useQuery({
    queryKey: ['organization'],
    queryFn: async () => request(ORGANIZATION_QUERY)
  })

  const { isLoading, data: { node: assignment = {} } = {} } = useQuery({
    queryKey: ['assignment', id],
    queryFn: async () => request(TUTOR_ASSIGNMENT_QUERY, { id })
  })

  const { insightIsLoading, data: { studentInsights = {} } = {} } = useQuery({
    queryKey: ['studentInsights', id],
    queryFn: async () => request(STUDENT_INSIGHTS_QUERY, { insightableId: id })
  })

  const classroom = assignment?.classroom || {}
  const { data: { anonymousClassroomInsights = [] } = {} } = useQuery(
    ['anonymousClassroomInsights', classroom.id, assignment.id],
    async () => request(CLASSROOM_INSIGHTS_QUERY, { classroomId: classroom.id, insightableId: assignment.id }),
    { enabled: !!classroom.id && !!assignment.id }
  )

  const questionData = studentInsights && [
    { type: 'Definition Question', count: studentInsights.questionCountDefinition, example: studentInsights.questionExampleDefinition },
    { type: 'Conceptual Question', count: studentInsights.questionCountConceptual, example: studentInsights.questionExampleConceptual },
    { type: 'Procedural Question', count: studentInsights.questionCountProcedural, example: studentInsights.questionExampleProcedural },
    { type: 'Verification Question', count: studentInsights.questionCountVerification, example: studentInsights.questionExampleVerification },
    { type: 'Other', count: studentInsights.questionCountOther, example: studentInsights.questionExampleOther }
  ]

  const totalQuestions = studentInsights && questionData.reduce((sum, question) => sum + question.count, 0)

  const questionDataWithPercentages = studentInsights && questionData.map(question => ({
    ...question,
    percentage: question.count && ((question.count / totalQuestions) * 100).toFixed(2),
    example: question.example
  }))

  const answerData = studentInsights && [
    { type: 'Incorrect', count: studentInsights.answerCountIncorrect, example: studentInsights.answerExampleIncorrect },
    { type: 'Partially Correct', count: studentInsights.answerCountPartiallyCorrect, example: studentInsights.answerExamplePartiallyCorrect },
    { type: 'Correct', count: studentInsights.answerCountCorrect, example: studentInsights.answerExampleCorrect },
    { type: 'Single Word', count: studentInsights.answerCountSingleWord, example: studentInsights.answerExampleSingleWord },
    { type: 'Other', count: studentInsights.answerCountOther, example: studentInsights.answerExampleOther }
  ]

  const totalAnswers = studentInsights && answerData.reduce((sum, answer) => sum + answer.count, 0)

  const answerDataWithPercentages = studentInsights && answerData.map(answer => ({
    ...answer,
    percentage: answer.count && ((answer.count / totalAnswers) * 100).toFixed(2),
    example: answer.example
  }))

  const misconceptionData = studentInsights && studentInsights.misconceptionsDetails &&
  studentInsights.misconceptionsDetails.map((misconception, index) => ({
    concept: studentInsights.misconceptionsConcepts[index],
    detail: misconception,
    evidence: studentInsights.misconceptionsEvidence[index]
  }))

  const { user: { first_name: currentUserFirstName, last_name: currentUserLastName } } = useCurrentUser()

  const scatterDataFunction = (acc, student) => {
    let key
    const value = {
      y: student.questionsAsked,
      x: round(student.activeTimeSeconds / 60, 2),
      tooltipData: {
      }
    }

    // The backend should only return an Insight (rather than an AnonymousInsight) if the student is the current user.
    if (student.__typename == 'Insight') {
      key = 'currentUser'
      value.tooltipData.Name = `${currentUserFirstName} ${currentUserLastName}`
    } else {
      key = student.answerQuality
    }

    value.tooltipData.Questions = student.questionsAsked
    value.tooltipData.Quality = capitalize(student.answerQuality)
    value.tooltipData.Time = formatDuration(student.activeTimeSeconds)

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(value)
    return acc
  }
  const scatterData = anonymousClassroomInsights.reduce(scatterDataFunction, {})

  const CustomTooltip = ({ active, payload, _label }) => {
    if (active && payload && payload.length) {
      return (
        <Card className='p-3'>
          {Object.entries(payload[0].payload.tooltipData || [])
            .filter(([_, value]) => value)
            .map(([key, value]) => (
              <div key={key} className='flex flex-col'>
                <span className='text-sm font-medium text-gray-500'>{key}</span>
                <span className='text-lg font-semibold text-gray-900'>{value}</span>
              </div>
            ))}

        </Card>
      )
    }

    return null
  }

  const cardData = [
    {
      title: 'Student Learning Analysis',
      type: 'cards',
      data: [
        { name: 'Total Time', value: formatDuration(studentInsights.totalTimeSeconds) },
        { name: 'Active Engagement Time', value: formatDuration(studentInsights.activeTimeSeconds) },
        { name: 'Questions Asked', value: studentInsights.questionsAsked },
        { name: 'Questions Answered', value: studentInsights.questionsAnswered },
        { name: 'Quality of Answers', value: capitalize(studentInsights.group) },
        { name: 'AI Usage Level', value: capitalize(studentInsights.group) }
      ]
    },
    {
      title: 'Active Time by Student',
      type: 'barChart',
      labels: {
        x: 'Student',
        y: 'Active Time (mins)'
      },
      angle: {
        x: -45
      },
      data: orderBy(
        anonymousClassroomInsights.map((student) => {
          const currentStudent = student.__typename == 'Insight'
          return {
            name: currentStudent ? currentUserFirstName : null,
            value: round(student.activeTimeSeconds / 60, 2),
            color: currentStudent ? '#8b2be2' : null, // Purple for current user.
            tooltipData: {
              Name: currentStudent
                ? `${currentUserFirstName} ${currentUserLastName}`
                : null,
              Time: formatDuration(student.activeTimeSeconds)
            }
          }
        }),
        ['value'],
        ['desc']
      )
    },
    {
      title: 'Questions Asked vs. Total Active Engagement Time',
      type: 'scatterChart',
      units: {
        x: ' min'
      },
      data: scatterData
    },
    {
      title: 'Quality Ratings Distribution',
      type: 'barChart',
      data: (() => {
        const currentUserInsight = anonymousClassroomInsights.find(student => student.__typename == 'Insight')
        const currentUserQuality = currentUserInsight ? currentUserInsight.answerQuality : null

        return [
          { name: 'Poor', value: anonymousClassroomInsights.filter((student) => student.answerQuality === 'poor').length },
          { name: 'Satisfactory', value: anonymousClassroomInsights.filter((student) => student.answerQuality === 'satisfactory').length },
          { name: 'Good', value: anonymousClassroomInsights.filter((student) => student.answerQuality === 'good').length },
          { name: 'Excellent', value: anonymousClassroomInsights.filter((student) => student.answerQuality === 'excellent').length }
        ].map((item) => ({
          ...item,
          color: item.name.toLowerCase() === currentUserQuality ? '#8b2be2' : null, // Purple for current user.
          tooltipData: { Quality: item.name, Answers: item.value }
        }))
      })()
    },
    {
      title: 'Questions Asked',
      type: 'barChart',
      labels: {
        x: 'Question Type',
        y: 'Percentage (%)'
      },
      angle: {
        x: -45
      },
      data: (() => {
        // Ensure questionDataWithPercentages exists and has data
        if (!questionDataWithPercentages || questionDataWithPercentages.length === 0) {
          return []
        }

        return questionDataWithPercentages.map(question => ({
          name: question.type,
          value: parseFloat(question.percentage), // Ensure percentage is a number
          tooltipData: {
            Type: question.type,
            Percentage: `${round(question.percentage)}%`,
            Example: question.example
          }
        }))
      })()
    }
  ]

  const renderCardContent = (card) => {
    switch (card.type) {
      case 'cards':
        // Don't make dynamic classes (like grid-cols-${card.data.length}) or Tailwind will purge them. Tailwind Safelist not working for some reason, so:
        if (card.data.length === 4) {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        } else {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        }
      case 'barChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart data={card.data} margin={{ bottom: card.labels && card.labels.x && 85 }} style={{ cursor: 'pointer' }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis interval={0} dataKey='name' angle={card.angle && card.angle.x} textAnchor={(card.angle && card.angle.x && 'end') || 'middle'} label={{ value: card.labels && card.labels.x, offset: -80, position: 'insideBottom' }} />
                  <YAxis label={{ value: card.labels && card.labels.y, angle: -90, offset: 20, position: 'insideBottomLeft' }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey='value'>
                    {card.data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color || '#3b82f6'} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )
      case 'scatterChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 10,
                    left: 10
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='x' type='number' unit={card.units && card.units.x} />
                  <YAxis dataKey='y' type='number' />
                  <Tooltip content={<CustomTooltip />} />
                  <Scatter data={card.data.null} fill='#64748b' />
                  <Scatter data={card.data.poor} fill='#ef4444' />
                  <Scatter data={card.data.satisfactory} fill='#eab308' />
                  <Scatter data={card.data.good} fill='#22c55e' />
                  {/* Override colour to highlight the current student. */}
                  <Scatter data={card.data.currentUser} fill='#8b2be2' />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )
      default:
        return null
    }
  }

  if (isLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Tutor Insights' to='/students/tutor_insights' />
        <BreadCrumbs.Text label={assignment.tutor.name} />
      </BreadCrumbs>

      <Choose>
        <When condition={!studentInsights}>
          <div className='mt-3 mx-5'>
            No insights available yet!
          </div>
        </When>

        <Otherwise>
          <div className='my-6 mx-8 space-y-16'>
            <div class='space-y-16'>
              {cardData.map((card, index) => (
                <div key={index} className='space-y-1'>
                  <Heading title={card.title} />
                  {renderCardContent(card)}
                </div>
              ))}
            </div>

            <div class='space-y-8'>
              <If condition={studentInsights.questionAdvice}>
                <div>
                  <Heading title='How to write better questions' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.questionAdvice}
                    </p>
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.aiAdvice}>
                <div>
                  <Heading title='How to use AI more effectively' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.aiAdvice}
                    </p>
                  </Card>
                </div>
              </If>
            </div>

            <div class='space-y-8'>
              <If condition={answerDataWithPercentages}>
                <div>
                  <Heading title='Answers Given' />
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header width='33%'>Answer Type</Table.Header>
                        <Table.Header width='33%'>Percentage</Table.Header>
                        <Table.Header width='33%'>Example</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {answerDataWithPercentages.map((answer, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='whitespace-normal'>{answer.type}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{round(answer.percentage)}%</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{answer.example}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </If>

              <If condition={studentInsights.answerAdvice}>
                <div>
                  <Heading title='How to structure better answers' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.answerAdvice}
                    </p>
                  </Card>
                </div>
              </If>
            </div>

            <div class='space-y-8'>
              <If condition={misconceptionData}>
                <div>
                  <Heading title='Misconceptions' />
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header width='33%'>Concept</Table.Header>
                        <Table.Header width='33%'>Detail</Table.Header>
                        <Table.Header width='33%'>Evidence</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {misconceptionData.map((misconception, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='whitespace-normal'>{misconception.concept}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{misconception.detail}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{misconception.evidence}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </If>

              <If condition={studentInsights.misconceptionsSyllabusConnection}>
                <div>
                  <Heading title='Connection to Syllabus' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.misconceptionsSyllabusConnection}
                    </p>
                  </Card>
                </div>
              </If>
            </div>

            <div className='space-y-8'>
              <Heading title='Metacognition' />

              <If condition={studentInsights.skillsDemonstrated}>
                <div>
                  <Heading title='Skills Demonstrated' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.skillsDemonstrated.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.areasForImprovement}>
                <div>
                  <Heading title='Areas for Improvement' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.areasForImprovement.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>
            </div>

            <div class='space-y-8'>
              <Heading title='Recommendations for Improvement' />

              <If condition={studentInsights.recommendationsFocusedStudyAreas}>
                <div>
                  <Heading title='Focused Study Areas' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.recommendationsFocusedStudyAreas.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsEngagement}>
                <div>
                  <Heading title='Engagement' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    {studentInsights.recommendationsEngagement}
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsCriticalThinking}>
                <div>
                  <Heading title='Critical Thinking' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    {studentInsights.recommendationsCriticalThinking}
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsBehaviorIndicators}>
                <div>
                  <Heading title='Behavior Indicators' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    {studentInsights.recommendationsBehaviorIndicators}
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsImprovements}>
                <div>
                  <Heading title='Improvements' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.recommendationsImprovements.map((improvement, index) => (
                        <li key={index}>{improvement}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>
            </div>

          </div>

        </Otherwise>
      </Choose>
    </>
  )
}

export default TutorInsights
