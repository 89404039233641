import { DocumentIcon, CheckIcon } from '@heroicons/react/24/outline'

import { formatTimestamp } from '@helpers/format'
import Badge from '@components/Badge'

const Explanation = ({ status, heading, createdAt }) => (
  <div className="flex flex-row mb-2 ml-3 md:ml-0">
    <div className="flex flex-col mr-2">
      <Badge theme="light">
        <DocumentIcon className="h-5 w-5" />
      </Badge>
      <div className="h-full bg-grey-100 w-2 rounded-md mt-2 self-center"></div>
    </div>

    <div className="ml-3 pb-3">
      <h4 className="text-lg font-semibold inline">{heading}</h4>
      <If condition={status === 'COMPLETED'}>
        <CheckIcon className="h-5 w-5 text-green-700 inline ml-1" />
      </If>

      <p className="text-sm">Project content</p>

      <If condition={createdAt}>
        <p className="text-sm">Submitted at {formatTimestamp(createdAt)}</p>
      </If>

      <If condition={status !== 'COMPLETED' && status !== 'IN_PROGRESS'}>
        <p className="text-sm">Not started</p>
      </If>
    </div>
  </div>
)

export default Explanation
