import React, { useState, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'
import { MicrophoneIcon } from '@heroicons/react/24/outline'
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { MathfieldElement } from 'mathlive'

import Spinner from '@components/Spinner'

import TextInput from './TextInput'
import MathInput from './MathInput'
import ModeToggle from './ModeToggle'
import AudioRecorder from './AudioRecorder'
import FileInput from './FileInput'
import Attachment from './Attachment'

MathfieldElement.soundsDirectory = null

const MessageForm = ({
  noticeMessage,
  mathsInputEnabled,
  visionEnabled,
  transcribeAudio,
  isTranscribing,
  isUploading,
  sendMessage,
  uploadAttachments
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    formState: { errors },
    clearErrors
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments'
  })
  const { ref, ...textProps } = register('text')
  const attachments = useWatch({
    control,
    name: 'attachments'
  })
  const messageText = useWatch({
    control,
    name: 'text'
  })

  const [inputMode, setInputMode] = useState('text')
  const [isRecording, setIsRecording] = useState(false)
  const [secondsRecorded, setSecondsRecorded] = useState(0)
  const [fileUploadError, setFileUploadError] = useState(null)

  const textFieldRef = useRef(null)
  const virtualKeyboardRef = useRef(null)
  const timerRef = useRef(null)
  const counterRef = useRef(null)
  const mediaRecorder = useRef(null)

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      })
      mediaRecorder.current = new MediaRecorder(stream, { type: 'audio/webm' })
      mediaRecorder.current.ondataavailable = handleDataAvailable
      mediaRecorder.current.start()
      setIsRecording(true)
      timerRef.current = setTimeout(stopRecording, 30000)
      counterRef.current = setInterval(
        () => setSecondsRecorded((elapsed) => elapsed + 1),
        1000
      )
    } catch (err) {
      // Ignore if user denies permission
      if (err.name !== 'NotAllowedError') {
        throw err
      }
    }
  }

  const cancelRecording = () => {
    clearTimeout(timerRef.current)
    clearInterval(counterRef.current)
    setSecondsRecorded(0)
    mediaRecorder.current.ondataavailable = null
    mediaRecorder.current.stop()
    mediaRecorder.current.stream.getTracks().forEach((track) => track.stop())
    setIsRecording(false)
    mediaRecorder.current = null
  }

  const stopRecording = () => {
    clearTimeout(timerRef.current)
    clearInterval(counterRef.current)
    setSecondsRecorded(0)
    mediaRecorder.current.stop()
    mediaRecorder.current.stream.getTracks().forEach((track) => track.stop())
    setIsRecording(false)
    mediaRecorder.current = null
  }

  const handleDataAvailable = async (event) => {
    if (event.data.size > 0) {
      const file = new File([event.data], 'blob.webm', { type: 'audio/webm' })
      transcribeAudio(file)
    }
  }

  const handleFilesAttached = async event => {
    clearErrors()
    setFileUploadError(null)

    const files = Array.from(event.target.files)

    // File size limit
    if (files.some(file => file.size > 10000000)) { // 10mb limit
      setFileUploadError('Files must be less than 10MB in size.')

      return
    }

    // File count limit
    if (files.length > 3) {
      setFileUploadError('You can only upload up to 3 files at a time.')

      return
    }

    if (textFieldRef.current) textFieldRef.current.focus()

    const { attachments = [], errors = [] } = await uploadAttachments(files)

    if (errors.length > 0) {
      setFileUploadError(errors[0].message)
    } else {
      append(attachments)
    }
  }

  const submitForm = data => {
    if (data.text.trim() === '' && data.attachments.length < 1) {
      setError('text', { required: true, message: 'Message is required' })

      return
    }

    const message = inputMode === 'math' ? `$${data.text}$` : data.text

    sendMessage({ text: message, attachmentIds: data.attachments.map(attachment => attachment.id) })
    reset()
    setValue('text', '')
  }

  useEffect(() => {
    if (!textFieldRef.current) return

    if (inputMode === 'math') {
      textFieldRef.current.addEventListener('focus', () => {
        window.mathVirtualKeyboard.show()
        window.mathVirtualKeyboard.container = virtualKeyboardRef.current

        const keyboardHeight = window.mathVirtualKeyboard.boundingRect.height
        virtualKeyboardRef.current.style.height = `${keyboardHeight}px`
      })

      textFieldRef.current.addEventListener('blur', () => {
        window.mathVirtualKeyboard.hide()
        virtualKeyboardRef.current.style.height = '0px'
      })

      textFieldRef.current.focus()
    } else {
      window.mathVirtualKeyboard.hide()
      virtualKeyboardRef.current.style.height = '0px'
    }

    textFieldRef.current.focus()
  }, [textFieldRef, inputMode])

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
      clearInterval(counterRef.current)
    }
  }, [])

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={twMerge(
        'w-full box-border flex flex-col pt-3 mb-3 md:mb-5 rounded-md border-2 border-x-2 border-gray-300 bg-white drop-shadow-[0_0_10px_rgba(0,0,0,0.15)]',
        inputMode === 'math' ? 'has-[:focus]:border-purple-500' : 'has-[:focus]:border-blue-500'
      )}
      data-tutorial='message-input-step'
    >
      <If condition={errors.text}>
        <p className='text-red-500 leading-tight shrink-0 p-3 text-sm'>
          {errors.text.message}
        </p>
      </If>

      <If condition={!!fileUploadError}>
        <p className='text-red-500 leading-tight shrink-0 p-3 text-sm'>
          {fileUploadError}
        </p>
      </If>

      <If condition={fields.length > 0}>
        <div className='flex flex-wrap gap-3 pb-3 px-3'>
          <For each='attachment' of={fields}>
            <Attachment
              key={attachment.id}
              id={attachment.id}
              filename={attachment.filename}
              url={attachment.url}
              remove={() => remove(attachment.id)}
            />
          </For>
        </div>
      </If>

      <div className='flex items-start px-3'>
        <Choose>
          <When condition={isTranscribing}>
            <Spinner className='text-gray-600 min-h-[40px]' />
          </When>

          <When condition={isRecording}>
            <AudioRecorder
              cancelRecording={cancelRecording}
              stopRecording={stopRecording}
              secondsRecorded={secondsRecorded}
            />
          </When>

          <Otherwise>
            <Choose>
              <When condition={isUploading}>
                <Spinner className='shrink-0 text-gray-600 min-h-[40px] w-9 mr-3' />
              </When>

              <When condition={visionEnabled}>
                <FileInput onChange={handleFilesAttached} />
              </When>
            </Choose>

            <Choose>
              <When condition={inputMode === 'math'}>
                <MathInput
                  setValue={value => setValue('text', value)}
                  {...textProps}
                  ref={e => {
                    ref(e)
                    textFieldRef.current = e
                  }}
                  submitForm={() => handleSubmit(submitForm)()}
                />
              </When>

              <Otherwise>
                <TextInput
                  {...textProps}
                  ref={e => {
                    ref(e)
                    textFieldRef.current = e
                  }}
                  submitForm={() => handleSubmit(submitForm)()}
                />
              </Otherwise>
            </Choose>

            <Choose>
              <When condition={!!messageText || attachments.length > 0}>
                <button
                  className={twMerge('flex items-center rounded-full ml-3')}
                  type='submit'
                >
                  <ArrowRightCircleIcon
                    className={twMerge('h-9 w-9', inputMode === 'math' ? 'text-purple-600 hover:text-purple-500' : 'text-blue-600 hover:text-blue-500')}
                  />
                </button>
              </When>

              <Otherwise>
                <button
                  id='start-recording'
                  onClick={startRecording}
                  className='flex items-center rounded-full hover:bg-gray-200 p-1'
                  type='button'
                >
                  <MicrophoneIcon
                    data-tutorial='start-recording-step'
                    className='h-7 w-7 group-disabled:text-gray-400'
                  />
                </button>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>

      <div className='flex items-center p-3'>
        <If condition={mathsInputEnabled}>
          <ModeToggle inputMode={inputMode} setInputMode={setInputMode} />
        </If>

        <p
          title={noticeMessage}
          className='text-sm text-center mt-1 pl-3 leading-tight truncate ml-auto text-gray-900'
        >
          {noticeMessage}
        </p>
      </div>

      <div
        ref={virtualKeyboardRef}
        className='transition-height duration-300 ease-in-out'
      />
    </form>
  )
}

export default MessageForm
