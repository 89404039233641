import { LightBulbIcon } from '@heroicons/react/24/outline'

import { formatTimestamp } from '@helpers/format'
import Badge from '@components/Badge'

const ReflectionBlock = ({ status, question, answer, submittedAt }) => {

  return (
    <div className="flex flex-row mb-2">
      <div className="flex flex-col mr-2">
        <Badge theme={status === 'COMPLETED' ? 'success' : 'light'}>
          <LightBulbIcon className="h-5 w-5" />
        </Badge>

        <div className="h-full bg-gray-200 w-2 rounded-md mt-2 self-center"></div>
      </div>

      <div className="ml-3 pb-3">
        <h4 className="text-lg font-semibold inline">{question}</h4>

        <If condition={submittedAt}>
          <p className="text-sm">{formatTimestamp(submittedAt)}</p>
        </If>

        <p className="text-lg">{answer}</p>

        <If condition={status !== 'COMPLETED' && status !== 'IN_PROGRESS'}>
          <p className="text-sm">Not started</p>
        </If>
      </div>
    </div>
  )
}

export default ReflectionBlock
