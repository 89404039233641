import React from 'react'
import { Tab } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

const List = ({ children, className, theme, ...props }) => (
  <Tab.List as="div" className={twMerge('flex flex-row items-center justify-between border-b-gray-200 border-b bg-white', className)}>
    <div className="w-full flex flex-row text-base h-[50px] leading-none box-border">
      {React.Children.map(children, (child, index) => React.cloneElement(child, {
        theme: (child.type.name === 'Tab' && !child.props.theme) ? theme : child.props.theme
      }))}
    </div>
  </Tab.List>
)

export default List
