import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Pagination from '@components/Pagination'

import SeriesCard from '../../components/SeriesCard'

const ASSIGNED_SERIES_QUERY = gql`
  query assignedSeries($page: Int) {
    assignedSeries(page: $page, perPage: 6) {
      nodes {
        id
        name
        description
        createdAt
        coverImage {
          url
        }
        educatorProjects {
          id
          name
        }
        educatorName
        organizationName
      }
      pagesCount
      nodesCount
    }
  }
`

const Library = () => {
  const [page, setPage] = useState(1)

  const { isLoading, data: { assignedSeries: { nodes: series = [], pagesCount } = {} } = {} } = useQuery({
    queryKey: ['assignedEducatorProjects', page],
    queryFn: async () => request(ASSIGNED_SERIES_QUERY, { page }),
    keepPreviousData: true
  })

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />
        </When>

        <Otherwise>
          <div className="flex flex-row flex-wrap px-5 pt-3 md:grid md:grid-cols-3 gap-5">
            <If condition={series.length < 1}>
              <p>You don't have any projects assigned to you just yet 😀</p>
            </If>

            <For index="index" each="s" of={series}>
              <Link className="w-full" to={s.id} key={s.id}>
                <SeriesCard
                  id={s.id}
                  name={s.name}
                  description={s.description}
                  projectCount={s.educatorProjects.length}
                  coverImageUrl={s.coverImage?.url}
                  educatorName={s.educatorName}
                  organizationName={s.organizationName}
                />
              </Link>
            </For>
          </div>

          <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />
        </Otherwise>
      </Choose>
    </>
  )
}

export default Library
