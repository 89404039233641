import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import {
  BuildingLibraryIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  LockClosedIcon,
  TrashIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { startCase } from 'lodash'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import Toggle from '@components/Toggle'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import Spinner from '@components/Spinner'
import SingleFileUpload from '@components/SingleFileInput'
import MultipleFileInput from '@components/MultipleFileInput'
import Listbox from '@components/Listbox'
import Label from '@components/Label'
import Image from '@components/Image'
import Pill from '@components/Pill'
import Notification from '@components/Notification'
import Tooltip from '@components/Tooltip'

import { useSubscription } from '../../../hooks/subscription'
import Collapsible from './Collapsible'
import DeleteTutorModal from './DeleteTutorModal'

const UPDATE_TUTOR_MUTATION = gql`
  mutation updateTutor($input: UpdateInput!) {
    updateTutor(input: $input) {
      tutor {
        id
        name
        createdAt
        description
        summary
        socratic
        suggestionsEnabled
        stemModeEnabled
        tags {
          id
          name
        }
      }
    }
  }
`

const CREATE_IMAGE_MUTATION = gql`
  mutation createImage($input: CreateImageInput!) {
    createImage(input: $input) {
      image {
        url
        filename
      }
    }
  }
`

const UPLOAD_DOCUMENTS_MUTATION = gql`
  mutation uploadDocuments($input: UploadDocumentsInput!) {
    uploadDocuments(input: $input) {
      files {
        url
      }
    }
  }
`

const DELETE_DOCUMENT_MUTATION = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      success
    }
  }
`

const DELETE_IMAGE_MUTATION = gql`
  mutation deleteImage($input: DeleteImageInput!) {
    deleteImage(input: $input) {
      success
    }
  }
`

const TAGS_QUERY = gql`
  query tutorTags {
    tutorTags {
      id
      slug
      name
      color
    }
  }
`

const Settings = ({
  tutorId,
  name,
  visibility,
  description,
  summary,
  socratic,
  suggestionsEnabled,
  stemModeEnabled,
  knowledgeBaseEnabled,
  tagIds,
  isOwner,
  refetch,
  imageUrl,
  documents
}) => {
  const { user: { organization: { features } } } = useCurrentUser()
  const { verificationStatus } = useSubscription()
  const publicTutorEnabled = features.includes('tutor_public_visibility')
  const documentUploadTrial = features.includes('tutor_document_upload')
  const [showImageSizeError, setshowImageSizeError] = useState(false)
  const [showFileSizeError, setShowFileSizeError] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { register, handleSubmit, watch, formState: { errors }, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      visibility,
      name,
      description,
      summary,
      socratic,
      stemModeEnabled,
      knowledgeBaseEnabled,
      suggestionsEnabled,
      tagIds
    }
  })

  const { mutate: updateTutor, isLoading: isUpdating, isSuccess } = useMutation({
    mutationFn: async data => request(UPDATE_TUTOR_MUTATION, { input: { ...data, id: tutorId } }),
    onSuccess: () => refetch()
  })

  const { mutate: createImage, isLoading: isCreating } = useMutation({
    mutationFn: async data => request(CREATE_IMAGE_MUTATION, { input: { id: tutorId, ...data } }),
    onSuccess: () => refetch()
  })

  const { mutate: deleteImage, isLoading: isDeleting } = useMutation({
    mutationFn: async () => request(DELETE_IMAGE_MUTATION, { input: { id: tutorId } }),
    onSuccess: () => refetch()
  })

  const { mutate: uploadDocuments, isLoading: isUploading } = useMutation({
    mutationFn: async data => request(UPLOAD_DOCUMENTS_MUTATION, { input: { id: tutorId, ...data } }),
    onSuccess: () => refetch()
  })

  const { mutate: deleteDocument, isLoading: isDeletingDocument } = useMutation({
    mutationFn: async data => request(DELETE_DOCUMENT_MUTATION, { input: { ...data } }),
    onSuccess: () => refetch()
  })

  const { data: { tutorTags: allTags = [] } = {} } = useQuery({ queryKey: ['tutorTags'], queryFn: async () => request(TAGS_QUERY) })

  const onSubmit = data => updateTutor(data)

  const knowledgeBaseEnabledInForm = watch('knowledgeBaseEnabled')
  const visibilityForm = watch('visibility')

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex md:flex-row flex-col gap-5 p-5'
      >
        <Card className='flex flex-col p-0 mb-3 h-fit overflow-crop sm:w-[300px]'>
          <Choose>
            <When condition={isCreating}>
              <Spinner className='py-16 self-center' />
            </When>

            <When condition={imageUrl}>
              <div className='relative group'>
                <Image
                  className='object-cover w-full h-[200px] md:rounded-lg rounded-none'
                  src={imageUrl}
                  placeholder={<div className='md:rounded-lg rounded-none h-[200px] w-full bg-gray-200 animate-pulse border shadow' />}
                />

                <If condition={isOwner}>
                  <Button
                    onClick={deleteImage}
                    className='absolute group-hover:block hidden bottom-0 right-0 m-3'
                    theme='error'
                    aria-label='Delete image'
                    label={
                      <Choose>
                        <When condition={isDeleting}>
                          <Spinner className='[&_*]:bg-white' />
                        </When>

                        <Otherwise>
                          <TrashIcon className='h-5 w-5' />
                        </Otherwise>
                      </Choose>
                    }
                  />
                </If>
              </div>
            </When>

            <When condition={isOwner}>
              <div className='bg-gray-200 flex flex-col items-center justify-center py-16 px-5'>
                <SingleFileUpload
                  label='Upload an image'
                  className='mt-3 text-center'
                  tabIndex={0}
                  theme='secondary'
                  accept='.png,.jpg,.jpeg'
                  onChange={ev => {
                    const file = ev.target.files[0]

                    if (file && file.size > 10000000) { // 10mb limit
                      setshowImageSizeError(true)

                      return
                    }

                    createImage({ file })
                  }}
                />
                <small className='mt-3 leading-tight'>
                  Supported file formats: .png, .jpg and .gif.
                  Files must be smaller than 10mb.
                </small>

                <If condition={showImageSizeError}>
                  <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>File size must be less than 10mb</p>
                </If>
              </div>
            </When>

            <Otherwise>
              <div className='bg-gray-200 h-[200px]' />
            </Otherwise>
          </Choose>
        </Card>

        <div className='flex flex-col grow'>
          <If condition={!isOwner}>
            <p className='w-fit flex mb-3 items-center leading-tight rounded-lg p-2 bg-gray-200'>
              <LockClosedIcon className='h-5 w-5 mr-1' /> Only the owner can make changes
            </p>
          </If>

          <div className='mb-3'>
            <TextInput
              id='name'
              label='Name'
              disabled={!isOwner}
              {...register('name', { required: 'Name is required' })}
            />
            <If condition={errors.name}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
            </If>
          </div>

          <div className='mb-5'>
            <TextArea
              className='h-[100px]'
              id='description'
              label='Prompt'
              disabled={!isOwner}
              {...register('description')}
            />
            <If condition={errors.description}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.description.message}</p>
            </If>

            <p className='mt-1 text-sm leading-tight'>
              You can describe a subject to focus on, the contents of a particular lesson, or even the style of the tutor. Be creative!
            </p>
          </div>

          <If condition={documentUploadTrial}>
            <div className='mb-3'>
              <Controller
                name='knowledgeBaseEnabled'
                control={control}
                rules={{ validate: value => !value || (value && documents.length > 0) || 'Upload documents to enable knowledge base' }}
                render={({ field }) => (
                  <Toggle
                    {...field}
                    id='knowledge-base-enabled'
                    disabled={!isOwner}
                    label='Knowledge base'
                    description='Upload documents to add custom knowledge to your tutor'
                  />
                )}
              />

              <If condition={errors.knowledgeBaseEnabled}>
                <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.knowledgeBaseEnabled.message}</p>
              </If>

              <If condition={knowledgeBaseEnabledInForm}>
                <div className='flex flex-col mt-5 ml-16'>
                  <If condition={isOwner}>
                    <MultipleFileInput
                      className='w-fit bg-gray-50'
                      onChange={ev => {
                        setShowFileSizeError(false)

                        const files = Array.from(ev.target.files)

                        if (files.some(file => file.size > 10000000)) { // 10mb limit
                          setShowFileSizeError(true)

                          return
                        }

                        uploadDocuments({ files })
                      }}
                      theme='light'
                      variant='outlined'
                      disabled={isUploading}
                      label={isUploading ? 'Uploading...' : 'Upload documents'}
                      helpText='Files must be in .pdf format and smaller than 10mb. If you upload files, chats with a tutor may include file contents. '
                      accept='.pdf'
                    />
                  </If>

                  <If condition={showFileSizeError}>
                    <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>File size must be less than 10mb</p>
                  </If>

                  <div className='flex mt-3 flex-wrap gap-x-3 gap-y-1'>
                    <For each='document' of={documents}>
                      <Pill
                        theme='light'
                        className='flex items-center mx-0 w-fit rounded-md text-sm border border-gray-300'
                        label={
                          <>
                            {document?.file?.filename}

                            <If condition={isOwner}>
                              <button type='button' onClick={() => deleteDocument({ documentId: document.id })}>
                                <XMarkIcon className='h-5 w-5 ml-2 cursor-pointer' />
                              </button>
                            </If>
                          </>
                        }
                      />
                    </For>
                  </div>
                </div>
              </If>
            </div>
          </If>

          <div className='mb-3'>
            <Controller
              name='socratic'
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  disabled={!isOwner}
                  label='Socratic mode'
                  description='This turns on probing questions instead of direct answers'
                />
              )}
            />
          </div>

          <div className='mb-3'>
            <Controller
              name='stemModeEnabled'
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  id='stem-mode-enabled'
                  disabled={!isOwner}
                  label={
                    <>
                      STEM mode
                      <Pill id="new-feature-pill" theme="secondary" label="New" className='ml-2'/>
                      <Tooltip anchorSelect="#new-feature-pill">Mathematics mode is now STEM mode, give it a try ✨</Tooltip>
                    </>
                  }
                  description='This turns on accurate calculations and visualisations for STEM related subjects (e.g. mathematics, science, physics, chemistry, etc.)'
                />
              )}
            />
          </div>

          <div className='mb-3'>
            <Controller
              name='suggestionsEnabled'
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  id='suggestions-enabled'
                  disabled={!isOwner}
                  label='Suggested questions'
                  description='Followup questions will be suggested based on recent messages'
                />
              )}
            />
          </div>

          <If condition={isOwner}>
            <div className='mb-5'>
              <Label labelFor='visbility'>Visibility</Label>
              <Controller
                name='visibility'
                control={control}
                render={({ field }) => (
                  <Listbox
                    id='visibility'
                    className='w-[350px]'
                    {...field}
                    theme='secondary'
                    placeholder='Select visibility'
                    label={field.value ? startCase(field.value.toLowerCase()) : 'Select visibility'}
                  >

                    <Listbox.Option
                      value='PRIVATE'
                      label={
                        <div className='flex mt-1'>
                          <LockClosedIcon className='h-6 w-6 mr-3 shrink-0' />
                          <div>
                            <p className='leading-none'>Private</p>
                            <small className='leading-none'>Only available to you</small>
                          </div>
                        </div>
                      }
                    />

                    <Listbox.Option
                      value='ORGANIZATION'
                      label={
                        <div className='flex mt-1'>
                          <BuildingLibraryIcon className='h-6 w-6 mr-3 shrink-0' />
                          <div>
                            <p className='leading-none'>Organization</p>
                            <small className='leading-none'>Available to educators within your organization</small>
                          </div>
                        </div>
                      }
                    />

                    <If condition={publicTutorEnabled && verificationStatus === 'VERIFIED'}>
                      <Listbox.Option
                        value='PUBLIC'
                        label={
                          <div className='flex mt-1'>
                            <GlobeAltIcon className='h-6 w-6 mr-3 shrink-0' />
                            <div>
                              <p className='leading-none'>Public</p>
                              <small className='leading-none'>Publicly available online for others to discover</small>
                            </div>
                          </div>
                        }
                      />
                    </If>
                  </Listbox>
                )}
              />

              <If condition={knowledgeBaseEnabledInForm && visibilityForm !== 'PRIVATE'}>
                <Notification className='mt-5 flex items-center font-normal border-0' theme='warning'>
                  <ExclamationTriangleIcon className='w-5 h-5 mr-3' />
                  Documents you upload will visible to educators {visibilityForm === 'ORGANIZATION' ? 'within your organization' : 'publicly'}
                </Notification>
              </If>
            </div>

            <Collapsible>
              <div className='mb-5 mt-3'>
                <TextArea
                  className='h-[60px]'
                  id='summary'
                  label='Description'
                  disabled={!isOwner}
                  placeholder='Write a short description of your tutor'
                  {...register('summary', { maxLength: { value: 200, message: 'Description can\'t be longer than 200 characters' } })}
                />
                <p className='text-sm mt-1 leading-tight flex items-center'>
                  Visible when you share your tutor or assign it to a classroom.
                </p>

                <If condition={errors.summary}>
                  <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.summary.message}</p>
                </If>
              </div>

              <div className='mb-5'>
                <Label>Tags</Label>
                <Controller
                  name='tagIds'
                  control={control}
                  render={({ field }) => (
                    <Listbox
                      {...field}
                      className='w-[350px]'
                      theme='secondary'
                      placeholder='Select tags'
                      label='Selected tags'
                      multiple
                    >
                      <For each='tag' of={allTags}>
                        <Listbox.Option key={tag.id} value={tag.id} label={tag.name} />
                      </For>
                    </Listbox>
                  )}
                />

                <p className='text-sm mt-1 leading-tight'>Add tags to make your tutor more discoverable. Note: doesn't apply if visibility is set to private.</p>
              </div>
            </Collapsible>

            <div className='ml-auto'>
              <If condition={isSuccess}>
                <CheckIcon className='h-5 w-5 mr-2 inline-block' />
              </If>

              <Button
                className='w-fit mb-5'
                theme='secondary'
                disabled={isUpdating}
                type='submit'
                label={isUpdating ? 'Saving...' : 'Save'}
              />
            </div>

            <div className='border border-red-300 rounded-lg'>
              <div className='p-5 flex flex-row gap-3 items-center justify-between'>
                <div>
                  <h4 className='font-semibold'>Delete tutor</h4>
                  <p className='text-sm leading-tight'>
                    Delete this tutor and all its associated messages, sessions and student data. This cannot be undone.
                  </p>
                </div>

                <Button
                  onClick={() => setShowDeleteModal(true)}
                  className='w-fit bg-gray-50 hover:bg-gray-100'
                  theme='error'
                  variant='outlined'
                  type='button'
                  label='Delete tutor'
                />
              </div>
            </div>

            <DeleteTutorModal
              id={tutorId}
              closeModal={() => setShowDeleteModal(false)}
              isOpen={showDeleteModal}
            />
          </If>
        </div>
      </form>
    </>
  )
}

export default Settings
