import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useNavigate } from 'react-router-dom'

import { request } from '@helpers/graphql'
import EducatorDetails from './EducatorDetails'
import OrganizationDetails from './OrganizationDetails'
import QualificationQuestions from './QualificationQuestions'
import Welcome from './Welcome'
import Skeleton from './Skeleton'

const ONBOARDING_QUERY = gql`
  query onboarding {
    organization {
      id
      name
      organizationType
    }

    me {
      profile {
        ... on EducatorProfile {
          firstName
          lastName
          organizationRole
          phoneCountryCode
          phoneNumber
          locationCountryCode
          signUpPurpose
          deviceUsage
          subscriptionBasedServices
          marketingSource
          subjects {
            id
            name
          }
          onboardingWelcomeCompleted
        }
      }
    }
  }
`

const Onboarding = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(null)
  const nextStep = () => { setStep(step + 1) }

  const {
    data: {
      organization = {},
      me: { profile = {} } = {}
    } = {},
    isLoading
  } = useQuery({
    queryKey: ['onboardingData'],
    queryFn: async () => request(ONBOARDING_QUERY),
    onSuccess: data => {
      // Onboarding already completed
      if (data.me.profile.onboardingWelcomeCompleted) {
        navigate('/educators')
      }
      // User already has an organization with a name
      else if (data.organization.name) {
        setStep(2)
      } else {
        setStep(1)
      }
    }
  })

  return (
    <Choose>
      <When condition={isLoading}>
        <Skeleton />
      </When>

      <When condition={step === 1}>
        <OrganizationDetails
          organizationId={organization.id}
          organizationName={organization.name}
          organizationType={organization.organizationType}
          nextStep={nextStep}
        />
      </When>

      <When condition={step === 2}>
        <EducatorDetails
          firstName={profile.firstName}
          lastName={profile.lastName}
          organizationRole={profile.organizationRole}
          subjects={profile.subjects}
          phoneCountryCode={profile.phoneCountryCode}
          phoneNumber={profile.phoneNumber}
          locationCountryCode={profile.locationCountryCode}
          nextStep={nextStep}
        />
      </When>

      <When condition={step === 3}>
        <QualificationQuestions
          signUpPurpose={profile.signUpPurpose}
          deviceUsage={profile.deviceUsage}
          subscriptionBasedServices={profile.subscriptionBasedServices}
          marketingSource={profile.marketingSource}
          nextStep={nextStep}
        />
      </When>

      <When condition={step === 4}>
        <Welcome />
      </When>
    </Choose>
  )
}

export default Onboarding
