import { createContext, useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Transition } from '@headlessui/react'
import { XMarkIcon, CheckCircleIcon, InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

const flashTheme = theme => {
  switch (theme) {
    case 'success':
      return 'bg-green-100 border-green-800'
    case 'error':
      return 'bg-red-100 border-red-800'
    case 'secondary':
      return 'bg-purple-100 border-purple-800'
    default:
      return 'bg-blue-100 border-blue-800'
  }
}

const FlashIcon = ({ theme }) => {
  switch (theme) {
    case 'success':
      return <CheckCircleIcon className="h-5 w-5 inline-block mr-2" />
    case 'error':
      return <ExclamationCircleIcon className="h-5 w-5 inline-block mr-2" />
    default:
      return <InformationCircleIcon className="h-5 w-5 inline-block mr-2" />
  }
}

const FlashMessageContext = createContext()

const FlashMessageProvider = ({ children }) => {
  const [message, setMessage] = useState(null)

  const setFlashMessage = (message, theme = 'primary') => {
    setMessage({ message, theme })
    setTimeout(() => setMessage(null), 5000)
  }

  const clearFlashMessage = () => {
    setMessage(null)
  }

  return (
    <FlashMessageContext.Provider value={{ setFlashMessage, clearFlashMessage, flashMessage: message }}>
      {children}
    </FlashMessageContext.Provider>
  )
}

const useFlashMessage = () => {
  const context = useContext(FlashMessageContext)

  if (!context) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider')
  }

  return context
}

const FlashMessage = ({ className }) => {
  const { flashMessage, clearFlashMessage } = useFlashMessage()

  return (
    <Transition
      role="alert"
      className={
        twMerge(
          'flex flex-row items-center font-semibold border-b-2 py-4 px-10 absolute top-0 right-0 left-0 lg:ml-[240px]',
          flashTheme(flashMessage?.theme),
          className
        )
      }
      show={!!flashMessage}
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <FlashIcon theme={flashMessage?.theme} />

      {flashMessage?.message}

      <button className="ml-auto" onClick={() => clearFlashMessage()}>
        <XMarkIcon className="h-5 w-5" />
      </button>
    </Transition>
  )
}

export default FlashMessage

export { FlashMessageProvider, useFlashMessage }
