import { CheckIcon } from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

import { formatTimestamp } from '@helpers/format'
import Badge from '@components/Badge'

const Question = ({ status, question, createdAt, submissions }) => {
  const correctAnswers = submissions.filter(answer => answer.correct).length
  const incorrectAnswers = submissions.filter(answer => !answer.correct).length
  const totalAnswers = submissions.length

  return (
    <div className="flex flex-row mb-2 ml-3 md:ml-0">
      <div className="flex flex-col mr-2">
        <Badge theme="light">
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </Badge>

        <div className="h-full bg-grey-100 w-2 rounded-md mt-2 self-center"></div>
      </div>

      <div className="ml-3">
        <h4 className="text-lg font-semibold inline">{question}</h4>
        <If condition={status === 'COMPLETED'}>
          <CheckIcon className="h-5 w-5 text-green-700 inline ml-1" />
        </If>

        <p className="text-sm">Multiple choice question</p>

        <If condition={createdAt}>
          <p className="text-sm">Submitted at {formatTimestamp(createdAt)}</p>
        </If>

        <If condition={totalAnswers > 0}>
          <p className="text-sm">{correctAnswers} correct, {totalAnswers} submissions</p>
        </If>

        <If condition={status !== 'COMPLETED' && status !== 'IN_PROGRESS'}>
          <p className="text-sm">Not started</p>
        </If>
      </div>
    </div>
  )
}

export default Question
