import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { tokyoNightStorm } from '@uiw/codemirror-theme-tokyo-night-storm'

import { request } from '@helpers/graphql'
import Card from '@components/Card'

const CODE_FILE_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        codeFile {
          content
          language
        }
      }
    }
  }
`

const CodingHistory = ({ id }) => {
  const { isLoading, data: { node: { codeFile } = {} } = {} } = useQuery({
    queryKey: ['codeFile', id],
    queryFn: async () => request(CODE_FILE_QUERY, { id })
  })

  if (isLoading) return

  return (
    <Card className="h-fit mt-3 p-5">
      <h2 className="text-xl font-bold mb-3">Coding history</h2>

      <div className="h-full overflow-clip rounded-lg">
        <CodeMirror
          editable={false}
          value={codeFile?.content || ''}
          className="text-base h-full"
          theme={tokyoNightStorm}
          height="100%"
          extensions={[python()]}
          basicSetup={{ highlightActiveLine: false, foldGutter: false }}
        />
      </div>

    </Card>
  )
}

export default CodingHistory
