import Button from '@components/Button'
import Modal from '@components/Modal'
import LinkButton from '@components/LinkButton'

const DeleteBlockModal = ({ closeModal, isOpen, deleteBlock, isDeleting }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}>
      <div className="px-5 py-5 h-full">
        <h3 className="font-heading text-2xl font-bold">Would you like to delete this block?</h3>

        <div className="flex flex-row justify-between items-center mt-3">
          <LinkButton onClick={closeModal}>
            back
          </LinkButton>

          <Button
            theme="error"
            disabled={isDeleting}
            label={isDeleting ? 'Deleting...' : 'Delete'}
            onClick={deleteBlock}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteBlockModal
