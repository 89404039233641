import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import RadioGroup from '@components/RadioGroup'
import SecureMarkdown from '@components/SecureMarkdown'

const CREATE_QUESTION_SUBMISSION_MUTATION = gql`
  mutation createQuestionSubmission($input: CreateQuestionSubmissionInput!) {
    createQuestionSubmission(input: $input) {
      errors {
        message
      }
      blockSubmission {
        content {
          ... on QuestionSubmission {
            question {
              id
              label
            }
            answer {
              id
              label
            }
          }
        }
      }
    }
  }
`

const QuestionBlock = ({
  blockId,
  submissionId,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  submitDisabled,
  submissionStatus,
  question,
  options,
  answerId
}) => {
  const queryClient = useQueryClient()

  const { mutate: createQuestionSubmission } = useMutation({
    mutationFn: async variables => request(CREATE_QUESTION_SUBMISSION_MUTATION, { input: { blockId, educatorProjectSubmissionId: submissionId, ...variables } }),
    onSuccess: () => { queryClient.invalidateQueries('educatorProjectSubmission') }
  })

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { answerId }
  })

  const submit = data => {
    if (submissionStatus === 'COMPLETED') {
      moveToNextBlock()
    } else if (isLastBlock) {
      createQuestionSubmission(data)
      openSubmitModal()
    } else {
      createQuestionSubmission(data)
      moveToNextBlock()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col mt-5'
      >
        <Controller
          name='answerId'
          control={control}
          rules={{ required: 'Choose an option to move to the next section' }}
          render={({ field }) => (
            <RadioGroup
              {...field}
              disabled={submissionStatus === 'COMPLETED'}
              theme='secondary'
              label={<SecureMarkdown content={question} />}
            >
              <For each='option' of={options}>
                <RadioGroup.Option
                  className='bg-white'
                  label={<SecureMarkdown content={option.label} />}
                  value={option.id}
                />
              </For>
            </RadioGroup>
          )}
        />

        <If condition={errors.answerId}>
          <p className='text-sm mt-2 text-red-500 font-semibold' role='alert'>{errors.answerId.message}</p>
        </If>

        <div className='flex flex-row items-center justify-between mt-3'>
          <LinkButton
            type='button'
            disabled={isFirstBlock}
            onClick={moveToPreviousBlock}
          >
            back
          </LinkButton>

          <Choose>
            <When condition={isLastBlock}>
              <Button
                type='submit'
                className='self-end'
                disabled={submissionStatus === 'COMPLETED' || submitDisabled}
                label={submissionStatus === 'COMPLETED' ? 'Submitted' : 'Submit'}
              />
            </When>

            <Otherwise>
              <Button
                type='submit'
                className='self-end'
                label='Next'
              />
            </Otherwise>
          </Choose>
        </div>
      </form>
    </>
  )
}

export default QuestionBlock
