import { Children, cloneElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

const BreadCrumbsComponent = ({ children, className }) => {
  const childrenWithSeparators = []
  const childCount = Children.count(children)

  Children.forEach(children, (child, index) => {
    childrenWithSeparators.push(cloneElement(child, { key: index }))

    // Add the separator between children, but not after the last child
    if (index < childCount - 1) {
      childrenWithSeparators.push(
        <ChevronRightIcon key={`separator-${index}`} className="mx-1 w-4 h-4" />
      )
    }
  })

  return (
    <div className={twMerge("flex flex-row items-center text-base py-3 px-5 leading-tight border-b-gray-200 border-b bg-white", className)}>
      {childrenWithSeparators}
    </div>
  )
}

const LinkCrumb = ({ label, to }) => (
  <Link className="font-semibold inline-block" to={to}>{label}</Link>
)

const TextCrumb = ({ label }) => (
  <p className="inline-block">{label}</p>
)

let BreadCrumbs = BreadCrumbsComponent

BreadCrumbs.Link = LinkCrumb
BreadCrumbs.Text = TextCrumb

export default BreadCrumbs
