import { useState } from 'react'
import { gql } from 'graphql-request'
import { useMutation, useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { ClockIcon } from '@heroicons/react/24/outline'
import { useForm, FormProvider } from 'react-hook-form'

import { request } from '@helpers/graphql'
import { useBreakpoint } from '@contexts/breakpoints'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Tour, { useTour } from '@components/Tour'

import ChatList from './ChatList'
import ChatDetails from './ChatDetails'
import ChatTourSteps from './ChatTourSteps'

import { useOnboardingChecklist } from '../../../hooks/onboardingChecklist'

const CHATS_QUERY = gql`
  query chats($page: Int, $tutorId: ID!) {
    chats(page: $page, perPage: 10, tutorId: $tutorId) {
      nodes {
        id
        createdAt
      }
      pagesCount
      nodesCount
    }
  }
`

const CREATE_CHAT_MUTATION = gql`
  mutation createChat($tutorId: ID!) {
    createChat(input: { tutorId: $tutorId }) {
      chat {
        id
        createdAt
      }
    }
  }
`

const EDUCATOR_PROFILE_QUERY = gql`
  query profile {
    me {
      profile {
        ... on EducatorProfile {
         onboardingTutorDetailsCompleted
        }
      }
    }
  }
`

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateEducatorProfile($updateEducatorProfileInput: UpdateEducatorProfileInput!) {
    updateEducatorProfile(input: $updateEducatorProfileInput) {
      educatorProfile {
        onboardingTutorDetailsCompleted
      }
      errors {
        message
      }
    }
  }
`

const EmptyChat = ({ createChat, name }) => (
  <div className='flex flex-col justify-center items-center w-full h-full border-l border-r-gray-200'>
    <p className='text-2xl font-semibold mb-3'>No chats yet</p>
    <p className='text-gray-600 mb-3 p-3'>Start a new chat with <strong>{name}</strong> by clicking the button below</p>
    <Button theme='secondary' className='mt-3 motion-safe:animate-[bounce_1s_ease-in-out_3.5]' onClick={createChat} label='Start chat' />
  </div>
)

const TutorConversation = ({ tutorId, name, imageUrl }) => {
  const [selectedChatId, setSelectedChatId] = useState(null)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const isMobile = useBreakpoint('sm')

  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })

  const { markOnboardingItemCompleted } = useOnboardingChecklist()

  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch,
    data: { pages = [] } = {}
  } = useInfiniteQuery({
    queryKey: ['chats', tutorId],
    queryFn: async ({ pageParam = 1 }) => request(CHATS_QUERY, { tutorId, page: pageParam }),
    onSuccess: (data) => {
      const recentChat = data.pages[0].chats.nodes[0]

      if (!selectedChatId) {
        setSelectedChatId(recentChat?.id)
      }
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.chats.pagesCount > pages.length) {
        return pages.length + 1
      }

      return false
    }
  })

  const { data: { me: { profile: { onboardingTutorDetailsCompleted = false } = {} } = {} } = {} } = useQuery({
    queryKey: ['educatorProfileOnboardingTutorDetails'],
    queryFn: async () => request(EDUCATOR_PROFILE_QUERY)
  })

  const {
    mutate: createChat,
    isLoading: isCreating
  } = useMutation({
    mutationFn: async () => request(CREATE_CHAT_MUTATION, { tutorId }),
    onSuccess: data => {
      refetch()
      setSelectedChatId(data.createChat.chat.id)
      setTimeout(() => setIsOpen(!onboardingTutorDetailsCompleted), 2000)
      markOnboardingItemCompleted('chatWithTutor')
    }
  })

  const { setIsOpen } = useTour()

  return (
    <>
      <Choose>
        <When condition={isMobile}>
          <div className='relative flex flex-col h-full bg-white'>
            <Button
              label={<span className='flex items-center'><ClockIcon className='h-6 w-6 mr-2' />History</span>}
              variant='outlined'
              theme='light'
              className='shadow-lg absolute z-20 top-0 left-0 w-fit m-3'
              onClick={() => setIsHistoryModalOpen(true)}
            />

            <Choose>
              <When condition={selectedChatId}>
                <FormProvider {...methods}>
                  <ChatDetails
                    name={name}
                    tutorId={tutorId}
                    chatId={selectedChatId}
                  />
                </FormProvider>
              </When>

              <When condition={!isLoading && pages?.[0]?.chats?.pagesCount == 0}>
                <EmptyChat name={name} createChat={createChat} />
              </When>
            </Choose>
          </div>

          <Modal
            title='Chat history'
            isOpen={isHistoryModalOpen}
            onClose={() => setIsHistoryModalOpen(false)}
          >
            <ChatList
              tutorId={tutorId}
              selectedChatId={selectedChatId}
              setSelectedChatId={setSelectedChatId}
              pages={pages}
              createChat={createChat}
              isCreating={isCreating}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              refetch={refetch}
            />
          </Modal>
        </When>

        <Otherwise>
          <div className='flex h-full'>
            <ChatList
              tutorId={tutorId}
              imageUrl={imageUrl}
              selectedChatId={selectedChatId}
              setSelectedChatId={setSelectedChatId}
              pages={pages}
              createChat={createChat}
              isCreating={isCreating}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              refetch={refetch}
            />

            <Choose>
              <When condition={selectedChatId}>
                <FormProvider {...methods}>
                  <ChatDetails
                    name={name}
                    tutorId={tutorId}
                    chatId={selectedChatId}
                  />
                </FormProvider>
              </When>

              <When condition={!isLoading && pages?.[0]?.chats?.pagesCount == 0}>
                <EmptyChat name={name} createChat={createChat} />
              </When>
            </Choose>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

const TutorConversationWithTour = (props) => {
  const { mutate: updateEducatorProfile } = useMutation({
    mutationFn: async () => request(UPDATE_EDUCATOR_PROFILE_MUTATION, { updateEducatorProfileInput: { onboardingTutorDetailsCompleted: true } })
  })

  return (
    <Tour steps={ChatTourSteps} beforeClose={updateEducatorProfile}>
      <TutorConversation {...props} />
    </Tour>
  )
}

export default TutorConversationWithTour
