import { useRef, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { FormProvider, useForm } from 'react-hook-form'

import { useBreakpoint } from '@contexts/breakpoints'
import Tab from '@components/Tab'
import {
  ChatPlayground,
  CompletionPlayground,
  CodingPlayground,
  DocumentPlayground,
  useEducatorProjectSubmission,
  ProjectBlocks
} from '@pages/EducatorProjectSubmission'

const Preview = ({ id }) => {
  const contentPanelRef = useRef(null)
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const { project, submission, isLoading, updateSubmission } = useEducatorProjectSubmission({ educatorProjectId: id })
  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })
  const isMobile = useBreakpoint('sm')

  const currentBlock = project?.blocks[currentBlockIndex]
  const currentBlockSubmission = submission?.blockSubmissions?.find(blockSubmission => blockSubmission.blockId === currentBlock?.blockId)

  if (isLoading || !project || !submission) return

  const moveToNextBlock = () => {
    setCurrentBlockIndex(currentBlockIndex + 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const moveToPreviousBlock = () => {
    setCurrentBlockIndex(currentBlockIndex - 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Choose>
      <When condition={isMobile}>
        <Tab.Group className='h-full w-full'>
          <Tab.List className='sticky top-0 w-full'>
            <Tab>
              Project
            </Tab>

            <Tab>
              Playground
            </Tab>
          </Tab.List>

          <Tab.Panels className='h-[calc(100%-51px)]'>
            <Tab.Panel className='h-full'>
              <div ref={contentPanelRef} className='py-3 px-5 flex flex-col'>
                <ProjectBlocks
                  blocks={project.blocks}
                  blockSubmissions={submission.blockSubmissions}
                  submissionId={submission.id}
                  submissionStatus={submission.status}
                  currentBlock={currentBlock}
                  currentBlockSubmission={currentBlockSubmission}
                  currentBlockIndex={currentBlockIndex}
                  moveToNextBlock={moveToNextBlock}
                  moveToPreviousBlock={moveToPreviousBlock}
                  submitDisabled
                />
              </div>
            </Tab.Panel>

            <Tab.Panel className='h-full w-full bg-white'>
              <Choose>
                <When condition={project.playground === 'CHAT'}>
                  <FormProvider {...methods}>
                    <ChatPlayground submissionId={submission.id} />
                  </FormProvider>
                </When>

                <When condition={project.playground === 'COMPLETION'}>
                  <CompletionPlayground submissionId={submission.id} />
                </When>

                <When condition={project.playground === 'CODE'}>
                  <CodingPlayground submissionId={submission.id} />
                </When>

                <When condition={project.playground === 'DOC'}>
                  <DocumentPlayground submissionId={submission.id} />
                </When>
              </Choose>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </When>

      <Otherwise>
        <PanelGroup className='flex grow bg-white' style={{ overflow: 'auto', height: 'auto' }} direction='horizontal' autoSaveId='studentProjectPlayground'>
          <Panel
            style={{ overflow: 'auto' }}
            className='flex flex-col pl-5 pr-3 bg-gray-50'
            defaultSize={50}
            minSize={30}
          >
            <div className='py-3' ref={contentPanelRef}>
              <ProjectBlocks
                blocks={project.blocks}
                blockSubmissions={submission.blockSubmissions}
                submissionId={submission.id}
                submissionStatus={submission.status}
                currentBlock={currentBlock}
                currentBlockSubmission={currentBlockSubmission}
                currentBlockIndex={currentBlockIndex}
                moveToNextBlock={moveToNextBlock}
                moveToPreviousBlock={moveToPreviousBlock}
                submitDisabled
              />
            </div>
          </Panel>

          <PanelResizeHandle className='flex items-center justify-center h-full'>
            <div className='bg-gray-50 px-1 h-full' />
            <div className='w-[1px] h-full bg-gray-200' />
            <div className='bg-white px-1 h-full' />
          </PanelResizeHandle>

          <Panel className='relative' defaultSize={50} minSize={30}>
            <Choose>
              <When condition={project.playground === 'CHAT'}>
                <FormProvider {...methods}>
                  <ChatPlayground submissionId={submission.id} />
                </FormProvider>
              </When>

              <When condition={project.playground === 'COMPLETION'}>
                <CompletionPlayground submissionId={submission.id} />
              </When>

              <When condition={project.playground === 'CODE'}>
                <CodingPlayground submissionId={submission.id} />
              </When>

              <When condition={project.playground === 'DOC'}>
                <DocumentPlayground submissionId={submission.id} />
              </When>
            </Choose>
          </Panel>
        </PanelGroup>
      </Otherwise>
    </Choose>
  )
}

export default Preview
