import { useState, useRef } from 'react'

import { ChatBubbleLeftIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { Transition } from '@headlessui/react'

import { useDebounce } from '@hooks/debounce'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import RichTextEditor from '@components/RichTextEditor'

import ChatPlayground from './ChatPlayground'

const SAVE_RICH_TEXT_DOCUMENT_MUTATION = gql`
  mutation saveRichTextDocument($input: SaveRichTextDocumentInput!) {
    saveRichTextDocument(input: $input) {
      richTextDocument {
        content
      }
    }
  }
`

const RICH_TEXT_DOCUMENT_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        richTextDocument {
          content
        }
      }
    }
  }
`

const DocumentPlayground = ({ submissionId }) => {
  const richTextEditorRef = useRef(null)
  const [isChatOpen, setIsChatOpen] = useState(false)

  const { mutate: saveRichTextDocument } = useMutation({
    mutationFn: async variables => await request(SAVE_RICH_TEXT_DOCUMENT_MUTATION, { input: { educatorProjectSubmissionId: submissionId, ...variables } }),
  })
  useQuery({
    queryKey: ['richTextDocument', submissionId],
    queryFn: async () => await request(RICH_TEXT_DOCUMENT_QUERY, { id: submissionId }),
    onSuccess: data => {
      const doc = data.node.richTextDocument
      if (doc) {
        const content = JSON.parse(doc.content)
        richTextEditorRef.current.editor.setContents(content)
      }
    }
  })

  const debouncedSave = useDebounce(() => {
    if (!richTextEditorRef.current) return

    const content = richTextEditorRef.current.editor.getContents()
    saveRichTextDocument({ content: JSON.stringify(content) })
  }, 2000)

  return (
    <div className="w-full sm:h-[calc(100%-45px)] h-[calc(100%-95px)] flex flex-col sm:pl-3 p-5">
      <RichTextEditor
        ref={richTextEditorRef}
        onChange={debouncedSave}
      />

      <Transition
        show={isChatOpen}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="absolute bottom-0 right-0 h-full sm:w-11/12 w-full px-5 pt-3 pb-[80px]">
          <div className="bg-white rounded-md h-full shadow-lg shadow-gray-400 border-gray-200 border overflow-auto w-full">
            <ChatPlayground submissionId={submissionId} />
          </div>
        </div>
      </Transition>

      <Button
        onClick={() => setIsChatOpen(!isChatOpen)}
        className="shadow-lg shadow-gray-400 absolute bottom-0 right-0 mr-5 mb-3 rounded-full h-[50px] w-[50px] flex items-center justify-center"
        label={isChatOpen ? <XMarkIcon className="w-5 h-5" /> : <ChatBubbleLeftIcon className="w-5 h-5" />}
        theme="secondary" />
    </div >
  )
}

export default DocumentPlayground
