import { twMerge } from 'tailwind-merge'

const ModeToggle = ({ inputMode, setInputMode }) => {
  return (
    <div className='inline-flex gap-2' role='group'>
      <button
        type='button'
        onClick={() => setInputMode('text')}
        className={twMerge(
          'flex items-center gap-2 px-2 text-sm font-xs rounded-md border-2 border-gray-200',
          inputMode === 'text'
            ? 'border-blue-500'
            : 'text-gray-700 hover:bg-gray-50'
        )}
      >
        Text
      </button>

      <button
        type='button'
        onClick={() => setInputMode('math')}
        className={twMerge(
          'flex items-center gap-2 px-2 py-1 text-sm font-xs rounded-md border-2 border-gray-200',
          inputMode === 'math'
            ? 'border-purple-500'
            : 'text-gray-700 hover:bg-gray-50'
        )}
      >
        Math
      </button>
    </div>
  )
}

export default ModeToggle
