import {
  CpuChipIcon,
  CodeBracketSquareIcon,
  BoltIcon,
  CommandLineIcon,
  CursorArrowRaysIcon
} from '@heroicons/react/24/outline'

const Icon = ({ index }) => {
  const icons = [
    <CommandLineIcon className="h-8 w-8 mr-3" />,
    <CpuChipIcon className="h-8 w-8 mr-3" />,
    <BoltIcon className="h-8 w-8 mr-3" />,
    <CodeBracketSquareIcon className="h-8 w-8 mr-3" />,
    <CursorArrowRaysIcon className="h-8 w-8 mr-3" />,
  ]

  return icons[index % icons.length]
}

export default Icon
