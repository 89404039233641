import { Bars2Icon } from '@heroicons/react/24/outline'

import Card from '@components/Card'

const BlockCard = ({
  title,
  icon,
  onEdit,
  onDelete,
  children,
  dragHandleProps
}) => (
  <Card>
    <div className='flex items-center justify-between px-5 py-2 border-b border-b-gray-200'>
      <div
        {...dragHandleProps}
        className='p-2 bg-gray-100 border-gray-200 border rounded-md mr-5'
      >
        <Bars2Icon className='h-4 w-4' />
      </div>

      <h4 className='flex flex-row items-center text-xl font-semibold'>
        <If condition={icon}>
          <span className='mr-2'>{icon}</span>
        </If>

        {title}
      </h4>

      <div className='flex flex-row items-center'>
        <button
          aria-label='Edit block'
          type='button'
          onClick={onEdit}
          className='flex flex-row items-center ml-auto text-sm text-blue-500 hover:bg-gray-100 p-2 rounded-md'
        >
          edit
        </button>

        <button
          aria-label='Delete block'
          type='button'
          onClick={onDelete}
          className='flex flex-row items-center ml-auto text-sm text-red-500 hover:bg-gray-100 p-2 rounded-md'
        >
          delete
        </button>
      </div>
    </div>

    {children}
  </Card>
)

export default BlockCard
