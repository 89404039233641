import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'

import { request } from '@helpers/graphql'
import { formatShortTimestamp } from '@helpers/format'

import Card from '@components/Card'

import ShowMore from '@components/ShowMore'

const COMPLETIONS_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        completions {
          status
          createdAt
          prompt
          result
        }
      }
    }
  }
`

const CompletionHistory = ({ id }) => {
  const { isLoading, data: { node: { completions = [] } = {} } = {} } = useQuery({
    queryKey: ['completions', id],
    queryFn: async () => request(COMPLETIONS_QUERY, { id })
  })

  if (isLoading) return

  return (
    <Card className="h-fit mt-3 p-5">
      <h2 className="text-xl font-bold mb-3">Completion history</h2>

      <Choose>
        <When condition={completions.length === 0}>
          <p className="text-sm mb-[200px] font-semibold">There are no completions for this project just yet 💬</p>
        </When>

        <Otherwise>
          <For each="completion" of={completions}>
            <div className="flex flex-row mb-2">
              <p className="text-sm whitespace-nowrap mr-3 font-semibold">
                {formatShortTimestamp(completion.createdAt)}
              </p>

              <div className="text-sm">
                <p className="whitespace-pre-wrap">
                  {completion.prompt}

                  <If condition={completion.status === 'SUCCESS'}>
                    <mark className="bg-green-100 whitespace-pre-wrap">
                      <ShowMore content={completion.result} />
                    </mark>
                  </If>
                </p>

                <If condition={completion.status === 'ERROR'}>
                  <mark className="bg-red-100">Unsafe content detected</mark>
                </If>
              </div>
            </div>
          </For>
        </Otherwise>
      </Choose>
    </Card>
  )
}

export default CompletionHistory
