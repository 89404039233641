import { useState } from 'react'
import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

const CopyToClipboardButton = ({ label, value, className }) => {
  const [codeCopied, setCodeCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value)
  }

  return (
    <button
      className={twMerge('flex items-center text-gray-900 rounded-md p-2 hover:bg-gray-200 bg-gray-100 border border-gray-300', className)}
      aria-label='Copy to clipboard'
      type='button'
      onClick={() => {
        copyToClipboard()
        setCodeCopied(true)
        setTimeout(() => setCodeCopied(false), 1000)
      }}
    >
      <span className='mr-3'>
        {label}
      </span>

      <Choose>
        <When condition={codeCopied}>
          <CheckIcon className='h-6 w-6 text-gray-500' />
        </When>

        <Otherwise>
          <DocumentDuplicateIcon className='h-6 w-6 text-gray-500' />
        </Otherwise>
      </Choose>
    </button>
  )
}

export default CopyToClipboardButton
