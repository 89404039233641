import { twMerge } from 'tailwind-merge'
import { truncate } from 'lodash'

import Card from '@components/Card'
import { BeakerIcon, CalculatorIcon, ChatBubbleLeftRightIcon, CodeBracketIcon, CursorArrowRippleIcon, HomeModernIcon, PuzzlePieceIcon, RocketLaunchIcon, SunIcon, VariableIcon } from '@heroicons/react/24/outline'

const backgroundColors = [
  'bg-blue-100',
  'bg-green-100',
  'bg-orange-100',
  'bg-purple-100',
  'bg-amber-100',
  'bg-cyan-100',
  'bg-pink-100',
  'bg-red-100',
  'bg-teal-100',
  'bg-yellow-100'
]

const icons = [
  <CodeBracketIcon className="h-20 w-20 text-blue-300" />,
  <CursorArrowRippleIcon className="h-20 w-20 text-green-300" />,
  <ChatBubbleLeftRightIcon className="h-20 w-20 text-orange-300" />,
  <BeakerIcon className="h-20 w-20 text-purple-300" />,
  <CalculatorIcon className="h-20 w-20 text-amber-300" />,
  <HomeModernIcon className="h-20 w-20 text-cyan-300" />,
  <RocketLaunchIcon className="h-20 w-20 text-pink-300" />,
  <SunIcon className="h-20 w-20 text-red-300" />,
  <VariableIcon className="h-20 w-20 text-teal-300" />,
  <PuzzlePieceIcon className="h-20 w-20 text-yellow-300" />
]

const stringToNumber = (s) => {
  var hash = 0
  for (var i = 0; i < s.length; i++) {
    hash += s.charCodeAt(i)
  }

  return hash % 10
}

const organizationInitials = name => name.split(' ').map(word => word[0]).join('')

const SeriesCard = ({ id, className, organizationName, educatorName, name, description, projectCount, truncateDescription = false, coverImageUrl, organization }) => {
  const index = stringToNumber(id)
  const backgroundColor = backgroundColors[index]
  const icon = icons[index]

  return (
    <Card className={twMerge('p-0 mb-3 grow h-fit', className)}>
      <Choose>
        <When condition={coverImageUrl}>
          <img className="object-cover w-full h-[200px]" src={coverImageUrl} />
        </When>

        <Otherwise>
          <div className={twMerge('flex justify-center items-center bg-orange-100 h-[200px] w-full', backgroundColor)}>
            {icon}
          </div>
        </Otherwise>
      </Choose>


      <div className="p-5">
        <div className="flex items-start gap-3">
          <span className="inline-flex shrink-0 h-10 w-10 items-center justify-center rounded-full bg-blue-500">
            <span className="font-medium leading-none text-white">{organizationInitials(organizationName)}</span>
          </span>

          <div>
            <h2 className="text-xl font-bold leading-none">{name}</h2>

            <p className="text-gray-500">
              {organizationName} &bull; {educatorName}
            </p>
          </div>
        </div>

        <If condition={projectCount > 0}>
          <p className="font-semibold mt-3">{projectCount} {projectCount > 1 ? 'projects' : 'project'}</p>
        </If>

        <p className="leading-tight mt-3">
          {truncateDescription ? truncate(description, { length: 100 }) : description}
        </p>
      </div>
    </Card>
  )
}

export default SeriesCard
