import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { BuildingLibraryIcon, GlobeAltIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { LockClosedIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { startCase } from 'lodash'

import { useCurrentUser } from '@contexts/currentUser'
import { useAnalytics } from '@contexts/analytics'
import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import Listbox from '@components/Listbox'
import Label from '@components/Label'
import Tour, { useTour } from '@components/Tour'
import Toggle from '@components/Toggle'
import MultipleFileInput from '@components/MultipleFileInput'
import Pill from '@components/Pill'
import Notification from '@components/Notification'
import Tooltip from '@components/Tooltip'

import { useSubscription } from '../../hooks/subscription'
import NewTutorSteps from './NewTutorSteps'
import tutorExamples from './tutorExamples'

const CREATE_TUTOR_MUTATION = gql`
  mutation createTutor($input: CreateTutorInput!) {
    createTutor(input: $input) {
      tutor {
        id
        name
        createdAt
        description
        socratic
        stemModeEnabled
        knowledgeBaseEnabled
        suggestionsEnabled
      }
    }
  }
`

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateEducatorProfile($updateEducatorProfileInput: UpdateEducatorProfileInput!) {
    updateEducatorProfile(input: $updateEducatorProfileInput) {
      educatorProfile {
        onboardingNewTutorCompleted
      }
      errors {
        message
      }
    }
  }
`

const EDUCATOR_PROFILE_QUERY = gql`
  query profile {
    me {
      profile {
        ... on EducatorProfile {
         onboardingNewTutorCompleted
        }
      }
    }
  }
`

const UPLOAD_DOCUMENTS_MUTATION = gql`
  mutation uploadDocuments($input: UploadDocumentsInput!) {
    uploadDocuments(input: $input) {
      files {
        url
      }
    }
  }
`

const NewTutor = () => {
  const { track } = useAnalytics()
  const { user: { organization: { features } } } = useCurrentUser()
  const { verificationStatus } = useSubscription()
  const [showFileSizeError, setShowFileSizeError] = useState(false)
  const publicTutorEnabled = features.includes('tutor_public_visibility')
  const documentUploadTrial = features.includes('tutor_document_upload')
  const navigate = useNavigate()
  const { setIsOpen } = useTour()
  const { register, handleSubmit, setValue, getValues, watch, trigger, formState: { errors }, control } = useForm({
    mode: 'onTouched',
    defaultValues: { visibility: 'PRIVATE', socratic: false, suggestionsEnabled: false }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'documents'
  })

  const { mutateAsync: createTutor, isLoading: isCreating } = useMutation({
    mutationFn: async data => request(CREATE_TUTOR_MUTATION, { input: data })
  })

  const { mutateAsync: uploadDocuments, isLoading: isUploading } = useMutation({
    mutationFn: async data => request(UPLOAD_DOCUMENTS_MUTATION, { input: { ...data } })
  })

  useQuery({
    queryKey: ['educatorProfile'],
    queryFn: async () => request(EDUCATOR_PROFILE_QUERY),
    onSuccess: data => setIsOpen(!data.me.profile.onboardingNewTutorCompleted)
  })

  const selectExample = () => {
    track('Tutor Example Selected')
    let randomIndex = Math.floor(Math.random() * tutorExamples.length)

    // Don't choose the same example twice in a row
    if (tutorExamples[randomIndex].name === getValues('name')) {
      randomIndex === 0 ? randomIndex++ : randomIndex--
    }

    const example = tutorExamples[randomIndex]

    setValue('name', example.name)
    setValue('description', example.description)
    setValue('socratic', example.socratic)
  }

  const handleAddDocuments = event => {
    setShowFileSizeError(false)

    const uploadedFiles = Array.from(event.target.files)

    if (uploadedFiles.some(file => file.size > 10000000)) { // 10mb limit
      setShowFileSizeError(true)

      return
    }

    const files = uploadedFiles.map(file => ({
      file
    }))

    append(files)
    trigger()
  }

  const onSubmit = async ({ documents, ...formData }) => {
    // Create tutor then upload documents
    const data = await createTutor(formData)
    const { id, knowledgeBaseEnabled } = data.createTutor.tutor

    if (knowledgeBaseEnabled && documents.length > 0) {
      await uploadDocuments({ id, files: documents.map(document => document.file) })
    }

    navigate(`/educators/tutors/${id}`)
  }

  const knowledgeBaseEnabledInForm = watch('knowledgeBaseEnabled')
  const visibilityForm = watch('visibility')

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Tutors' to='/educators/tutors' />
        <BreadCrumbs.Text label='New tutor' />
      </BreadCrumbs>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col m-5'
      >
        <h3 className='text-2xl font-semibold mb-3'>New tutor</h3>
        <p className='mb-3 leading-tight'>
          If you're not sure what to write,&nbsp;
          <button
            data-tutorial='new-tutor-example-step'
            type='button'
            onClick={selectExample}
            className='text-purple-500 underline'
          >
            try an example
          </button> ✨
        </p>

        <div data-tutorial='new-tutor-name-step' className='mb-3'>
          <TextInput
            id='name'
            label='Name'
            placeholder='Socratic Tutor'
            {...register('name', { required: 'Name is required' })}
          />
          <If condition={errors.name}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
          </If>
        </div>

        <div data-tutorial='new-tutor-description-step' className='flex flex-col mb-5'>
          <TextArea
            className='h-[100px]'
            id='description'
            label='Prompt'
            placeholder='Write the prompt for your tutor here. You could describe a subject to focus on, a particular lesson, or even the style of the tutor. Be creative!'
            {...register('description')}
          />
          <p className='mt-1 text-sm leading-tight'>
            You can describe a subject to focus on, the contents of a particular lesson, or even the style of the tutor. Be creative!
          </p>
          <If condition={errors.description}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.description.message}</p>
          </If>
        </div>

        <If condition={documentUploadTrial}>
          <div data-tutorial='new-tutor-knowledge-base-step' className='mb-3'>
            <Controller
              rules={{ validate: value => !value || (value && fields.length > 0) || 'Upload documents to enable knowledge base' }}
              name='knowledgeBaseEnabled'
              control={control}
              render={({ field }) => (
                <Toggle
                  {...field}
                  id='knowledge-base-enabled'
                  label='Knowledge base'
                  description='Upload documents to add custom knowledge to your tutor'
                />
              )}
            />

            <If condition={errors.knowledgeBaseEnabled}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.knowledgeBaseEnabled.message}</p>
            </If>

            <If condition={knowledgeBaseEnabledInForm}>
              <div className='flex flex-col mt-5 ml-16'>
                <MultipleFileInput
                  disabled={isUploading}
                  className='w-fit bg-gray-50'
                  onChange={handleAddDocuments}
                  theme='light'
                  variant='outlined'
                  label='Upload documents'
                  helpText='Files must be in .pdf format and smaller than 10mb. If you upload files, chats with a tutor may include file contents. '
                  accept='.pdf'
                />

                <If condition={showFileSizeError}>
                  <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>File size must be less than 10mb</p>
                </If>

                <div className='flex mt-3 flex-wrap gap-x-3 gap-y-1'>
                  <For each='document' of={fields} index='i'>
                    <Controller
                      control={control}
                      name={`documents.${i}`}
                      render={() => (
                        <Pill
                          theme='light'
                          className='flex items-center mx-0 w-fit rounded-md text-sm border border-gray-300'
                          label={
                            <>
                              {document.file.name}

                              <button type='button' onClick={() => remove(i)}>
                                <XMarkIcon className='h-5 w-5 ml-2 cursor-pointer' />
                              </button>
                            </>
                          }
                        />
                      )}
                    />
                  </For>
                </div>
              </div>
            </If>
          </div>
        </If>

        <div data-tutorial='new-tutor-socratic-step' className='mb-3'>
          <Controller
            name='socratic'
            control={control}
            render={({ field }) => (
              <Toggle
                {...field}
                label='Socratic mode'
                description='This turns on probing questions instead of direct answers.'
              />
            )}
          />
        </div>

        <div className='mb-3'>
          <Controller
            name='stemModeEnabled'
            control={control}
            render={({ field }) => (
              <Toggle
                {...field}
                id='stem-mode-enabled'
                label={
                  <>
                    STEM mode
                    <Pill id="new-feature-pill" theme="secondary" label="New" className='ml-2'/>
                    <Tooltip anchorSelect="#new-feature-pill">Mathematics mode is now STEM mode, give it a try ✨</Tooltip>
                  </>
                }
                description='This turns on accurate calculations and visualisations for STEM related subjects (e.g. mathematics, science, physics, chemistry, etc.)'
              />
            )}
          />
        </div>

        <div data-tutorial='new-tutor-suggestion-step' className='mb-3'>
          <Controller
            name='suggestionsEnabled'
            control={control}
            render={({ field }) => (
              <Toggle
                {...field}
                id='suggestions-enabled'
                className='w-fit'
                label='Suggested questions'
                description='Followup questions will be suggested based on recent messages'
              />
            )}
          />
        </div>

        <div data-tutorial='new-tutor-visibility-step' className='mb-5'>
          <Label labelFor='visbility'>Visibility</Label>
          <Controller
            name='visibility'
            control={control}
            render={({ field }) => (
              <Listbox
                id='visibility'
                className='w-[350px]'
                {...field}
                theme='secondary'
                placeholder='Select visibility'
                label={field.value ? startCase(field.value.toLowerCase()) : 'Select visibility'}
              >

                <Listbox.Option
                  value='PRIVATE'
                  label={
                    <div className='flex mt-1'>
                      <LockClosedIcon className='h-6 w-6 mr-3 shrink-0' />
                      <div>
                        <p className='leading-none'>Private</p>
                        <small className='leading-none'>Only available to you</small>
                      </div>
                    </div>
                  }
                />

                <Listbox.Option
                  value='ORGANIZATION'
                  label={
                    <div className='flex mt-1'>
                      <BuildingLibraryIcon className='h-6 w-6 mr-3 shrink-0' />
                      <div>
                        <p className='leading-none'>Organization</p>
                        <small className='leading-none'>Available to educators within your organization</small>
                      </div>
                    </div>
                  }
                />

                <If condition={publicTutorEnabled && verificationStatus === 'VERIFIED'}>
                  <Listbox.Option
                    value='PUBLIC'
                    label={
                      <div className='flex mt-1'>
                        <GlobeAltIcon className='h-6 w-6 mr-3 shrink-0' />
                        <div>
                          <p className='leading-none'>Public</p>
                          <small className='leading-none'>Publicly available online for others to discover</small>
                        </div>
                      </div>
                    }
                  />
                </If>
              </Listbox>
            )}
          />

          <If condition={knowledgeBaseEnabledInForm && visibilityForm !== 'PRIVATE'}>
            <Notification className='mt-5 flex items-center font-normal border-0' theme='warning'>
              <ExclamationTriangleIcon className='w-5 h-5 mr-3' />
              Documents you upload will visible to educators within {visibilityForm === 'ORGANIZATION' ? 'your organization' : 'the Mindjoy community'}
            </Notification>
          </If>
        </div>

        <Button
          data-tutorial='new-tutor-create-tutor-button'
          theme='secondary'
          className='w-fit ml-auto'
          disabled={isCreating}
          type='submit'
          label={isCreating || isUploading ? 'Creating...' : 'Create tutor'}
        />
      </form>
    </>
  )
}

const TutorWithTour = () => {
  const { mutate: updateEducatorProfile } = useMutation({
    mutationFn: async () => request(UPDATE_EDUCATOR_PROFILE_MUTATION, { updateEducatorProfileInput: { onboardingNewTutorCompleted: true } })
  })

  return (
    <Tour steps={NewTutorSteps} beforeClose={updateEducatorProfile}>
      <NewTutor />
    </Tour>
  )
}

export default TutorWithTour
