import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'
import TextAreaWithMathInput from '@components/TextAreaWithMathInput'

const CREATE_REFLECTION_SUBMISSION_MUTATION = gql`
  mutation CreateReflectionSubmission($input: CreateReflectionSubmissionInput!) {
    createReflectionSubmission(input: $input) {
      errors {
        message
      }

      blockSubmission {
        content {
          ... on ReflectionSubmission {
            answer
          }
        }
      }
    }
  }
`

const ReflectionBlock = ({
  blockId,
  submissionId,
  question,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  submitDisabled,
  submissionStatus,
  answer
}) => {
  const queryClient = useQueryClient()

  const { mutate: createReflectionSubmission } = useMutation({
    mutationFn: async variables => request(CREATE_REFLECTION_SUBMISSION_MUTATION, { input: { blockId, educatorProjectSubmissionId: submissionId, ...variables } }),
    onSuccess: () => { queryClient.invalidateQueries('educatorProjectSubmission') }
  })

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { answer }
  })

  const submit = data => {
    if (submissionStatus === 'COMPLETED') {
      moveToNextBlock()
    } else if (isLastBlock) {
      createReflectionSubmission(data)
      openSubmitModal()
    } else {
      createReflectionSubmission(data)
      moveToNextBlock()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col mt-5'
      >
        <TextAreaWithMathInput
          label={<SecureMarkdown content={question} />}
          setValue={value => setValue('answer', value)}
          readOnly={submissionStatus === 'COMPLETED'}
          {...register('answer', { required: 'Answer the question to move to the next section' })}
        />

        <If condition={errors.answer}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
        </If>

        <div className='flex flex-row mt-3 items-center justify-between'>
          <LinkButton
            type='button'
            disabled={isFirstBlock}
            onClick={moveToPreviousBlock}
          >
            back
          </LinkButton>

          <Choose>
            <When condition={isLastBlock}>
              <Button
                type='submit'
                className='self-end'
                disabled={submissionStatus === 'COMPLETED' || submitDisabled}
                label={submissionStatus === 'COMPLETED' ? 'Submitted' : 'Submit'}
              />
            </When>

            <Otherwise>
              <Button
                type='submit'
                className='self-end'
                label='Next'
              />
            </Otherwise>
          </Choose>
        </div>
      </form>
    </>
  )
}

export default ReflectionBlock
