import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, useFieldArray } from 'react-hook-form'
import { QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import TextInput from '@components/TextInput'
import Checkbox from '@components/Checkbox'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Label from '@components/Label'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'

import BlockCard from './BlockCard'

const UPDATE_QUESTION_BLOCK_MUTATION = gql`
  mutation updateQuestionBlock($input: UpdateQuestionBlockInput!) {
    updateQuestionBlock(input: $input) {
      question {
        label
        options {
          label
          correct
        }
      }
      errors {
        message
      }
    }
  }
`

const QuestionBlock = ({ id, label, options, showDeleteModal, refetch, dragHandleProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      label,
      options
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options'
  })

  const { mutate: updateQuestionBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_QUESTION_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => {
      refetch()
      setIsModalOpen(false)
    }
  })

  const onSubmit = ({ label, options }) => {
    updateQuestionBlock({ label, options })
  }

  return (
    <>
      <BlockCard
        title='Question'
        icon={<QuestionMarkCircleIcon className='h-5 w-5' />}
        onEdit={setIsModalOpen}
        onDelete={showDeleteModal}
        dragHandleProps={dragHandleProps}
      >
        <p className='px-5 py-3 text-lg'>
          <Choose>
            <When condition={label}>
              <SecureMarkdown content={label} />
            </When>

            <Otherwise>
              <em>You haven't set a question just yet</em>
            </Otherwise>
          </Choose>
        </p>
      </BlockCard>

      <Modal
        size='md'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col px-5 py-5'>
          <h3 className='font-heading text-2xl font-bold mb-3'>Edit question</h3>

          <TextInput
            className='mb-5'
            label='Question'
            placeholder='Write your question'
            {...register('label')}
          />

          <Label className=''>Answers</Label>

          <For each='option' index='index' of={fields}>
            <div className='w-full flex flex-row items-center mb-3'>
              <Checkbox
                aria-label='Correct answer'
                {...register(`options.${index}.correct`)}
              />

              <TextInput
                aria-label='Answer'
                className='w-full'
                placeholder='Write your answer'
                {...register(`options.${index}.label`)}
              />

              <button
                aria-label='Remove answer'
                type='button'
                onClick={() => remove(index)}
                className='ml-1 hover:bg-gray-200 p-2 rounded-md'
              >
                <TrashIcon className='h-5 w-5' />
              </button>
            </div>
          </For>

          <LinkButton
            className='w-fit'
            type='button'
            onClick={() => append({ label: '', correct: false })}
          >
            Add answer
          </LinkButton>

          <Button
            className='ml-auto'
            type='submit'
            disabled={isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </form>
      </Modal>
    </>
  )
}

export default QuestionBlock
