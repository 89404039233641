import { useRef } from 'react'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'

import { request } from '@helpers/graphql'
import Card from '@components/Card'
import RichTextEditor from '@components/RichTextEditor'

const RICH_TEXT_DOCUMENT_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        richTextDocument {
          content
        }
      }
    }
  }
`

const DocumentHistory = ({ id }) => {
  const richTextEditorRef = useRef(null)

  useQuery({
    queryKey: ['richTextDocument', id],
    queryFn: async () => request(RICH_TEXT_DOCUMENT_QUERY, { id }),
    onSuccess: data => {
      const doc = data.node.richTextDocument

      if (doc) {
        const content = JSON.parse(doc.content)
        richTextEditorRef.current.editor.setContents(content)
      }
    }
  })

  return (
    <Card className="h-fit mt-3 p-5">
      <RichTextEditor
        ref={richTextEditorRef}
        readOnly={true}
      />
    </Card>
  )
}

export default DocumentHistory
