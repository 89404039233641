import { Link } from 'react-router-dom'
import Card from '@components/Card'

const NoResults = ({ showClosedSessions }) => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No student activity just yet</h4>
    <p>
      <button className='inline text-blue-500' onClick={showClosedSessions}>
        Update your filters
      </button>

      &nbsp;to view all sessions, or&nbsp;

      <Link to='/educators/tutors' className='inline text-blue-500'>
        assign a tutor
      </Link>

      &nbsp;to get started.
    </p>
  </Card>
)

export default NoResults
