import { useEditor, EditorContent } from '@tiptap/react'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Markdown } from 'tiptap-markdown'

import ToolBar from './ToolBar'
import TableBubbleMenu from './TableBubbleMenu'
import MathExtension from './MathExtension'

const extensions = [
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    heading: false,
    horizontalRule: false,
    blockquote: false
  }),
  Markdown.configure({
    html: false, // Allow HTML input/output
    tightLists: false, // No <p> inside <li> in markdown output
    tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
    bulletListMarker: '*', // <li> prefix in markdown output
    linkify: true, // Create links from "https://..." text
    breaks: true, // New lines (\n) in markdown input are converted to <br>
    transformPastedText: true, // Allow to paste markdown text in the editor
    transformCopiedText: true // Copied text is transformed to markdown
  }),
  Table.configure({
    resizable: false,
    allowTableNodeSelection: false
  }),
  TableRow,
  TableHeader,
  TableCell,
  MathExtension
]

const TextEditor = ({ value, onChange, disabled }) => {
  const editor = useEditor({
    extensions,
    content: value,
    editable: !disabled,
    editorProps: {
      attributes: {
        class: 'text-editor w-full overflow-x-scroll min-h-[100px] bg-white font-normal rounded-b-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 focus:outline-none sm:text-base sm:leading-6'
      },
      transformPastedHTML: html => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const table = doc.querySelector('table')

        if (table) {
          // Check for complex HTML not supported by GFM
          const complexElements = table.querySelectorAll('img, a, strong, em, code, pre, blockquote, ul, ol, li')
          if (complexElements.length > 0) {
            // Complex HTML found, return an empty string to prevent pasting
            return ''
          }

          const headers = table.querySelectorAll('th')

          if (headers.length === 0) {
            const firstRow = table.querySelector('tr')

            firstRow.querySelectorAll('td').forEach((cell) => {
              const header = document.createElement('th')
              header.textContent = cell.textContent
              firstRow.replaceChild(header, cell)
            })
          }

          // Remove any nested tables
          const nestedTables = table.querySelectorAll('table')
          nestedTables.forEach(nestedTable => nestedTable.remove())

          // Remove any block elements inside table cells
          const blockElements = table.querySelectorAll('div, p')
          blockElements.forEach(element => {
            const textContent = element.textContent
            element.parentNode.replaceChild(document.createTextNode(textContent), element)
          })

          // Return only the table HTML
          return table.outerHTML
        }

        // If no table is found, return the original HTML
        return html
      }
    },
    onUpdate: ({ editor }) => {
      if (onChange) {
        onChange(editor.storage.markdown.getMarkdown())
      }
    }
  })

  return (
    <>
      <ToolBar editor={editor} />
      <TableBubbleMenu editor={editor} />
      <EditorContent
        editor={editor}
      />
    </>
  )
}

export default TextEditor
