import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import { AnalyticsBrowser } from '@segment/analytics-next'
import * as Sentry from '@sentry/react'
import { PythonProvider } from 'react-py'
import { HelmetProvider } from 'react-helmet-async'

import { AnalyticsProvider } from './contexts/analytics'
import { useActionCable, ActionCableProvider } from './contexts/actionCable'
import { BreakpointProvider } from './contexts/breakpoints'
import { CurrentUserProvider } from './contexts/currentUser'
import { FlashMessageProvider } from './components/FlashMessage'

import Educators from './educators/Educators'
import Students from './students/Students'
import Administrators from './administrators/Administrators'
import Authenticated from './pages/Authenticated'
import NotFound from './pages/NotFound'
import Layout from './pages/Layout'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import StudentSignUp from './pages/StudentSignUp'
import StudentJoinClassroom from './pages/StudentJoinClassroom'
import StudentJoinShortCode from './pages/StudentJoinShortCode'
import EducatorSignUp from './pages/EducatorSignUp'
import EducatorAcceptInvitation from './pages/EducatorAcceptInvitation'
import ResetPassword from './pages/ResetPassword'
import RequestPasswordReset from './pages/RequestPasswordReset'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import Purchase from './pages/Purchase'
import AuthenticationError from './pages/AuthenticationError'
import Error from './pages/Error'
import PublicTutorDetails from './pages/PublicTutorDetails'

const App = () => {
  const analytics = AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY })
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false
      }
    }
  })

  const { actionCable } = useActionCable(import.meta.env.VITE_WEBSOCKET_URL)

  useEffect(() => {
    navigator.serviceWorker
      .register('/react-py-sw.js')
      .catch((err) => console.log('Service Worker registration failed: ', err))
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <AnalyticsProvider analytics={analytics}>
        <ActionCableProvider actionCable={actionCable}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <BreakpointProvider>
                <CurrentUserProvider>
                  <FlashMessageProvider>
                    <PythonProvider>
                      <BrowserRouter>
                        <Routes>
                          <Route element={<Layout />}>
                            <Route path='/student_sign_up' element={<StudentSignUp />} />
                            <Route path='/join_short_code' element={<StudentJoinShortCode />} />
                            <Route path='/join_classroom/:shortCode' element={<StudentJoinClassroom />} />
                            <Route path='/educator_accept_invitation' element={<EducatorAcceptInvitation />} />
                            <Route element={<Authenticated />}>
                              <Route path='/educators/*' element={<Educators />} />
                              <Route path='/students/*' element={<Students />} />
                              <Route path='/admin/*' element={<Administrators />} />
                            </Route>
                            <Route path='/' element={<Navigate to='/sign_in' />} />
                            <Route path='/sign_in' element={<SignIn />} />
                            <Route path='/reset_password' element={<ResetPassword />} />
                            <Route path='/request_password_reset' element={<RequestPasswordReset />} />
                            <Route path='/sign_up' element={<SignUp />} />
                            <Route path='/educator_sign_up' element={<EducatorSignUp />} />
                            <Route path='/privacy' element={<Privacy />} />
                            <Route path='/terms' element={<Terms />} />
                            <Route path='/purchase' element={<Purchase />} />
                            <Route path='/authentication_error' element={<AuthenticationError />} />
                            <Route path='/tutors/:id' element={<PublicTutorDetails />} />

                            <Route path='*' element={<NotFound />} />
                          </Route>
                        </Routes>
                      </BrowserRouter>
                    </PythonProvider>
                  </FlashMessageProvider>
                </CurrentUserProvider>
              </BreakpointProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ActionCableProvider>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
