import PageHeading from '@components/PageHeading'

import Library from './Library'

const Projects = ({ pageHeading }) => (
  <>
    <PageHeading title={pageHeading} />

    <Library />
  </>
)

export default Projects
