import { useState } from 'react'

import {
  AdjustmentsHorizontalIcon,
  EyeIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'

import Tab from '@components/Tab'

import Setup from './Setup'
import Edit from './Edit'
import Preview from './Preview'

const Overview = ({
  id,
  name,
  readme,
  blocks,
  status,
  playground,
  classrooms,
  document,
  documentText,
  mode,
  prompt,
  socraticModeEnabled,
  stemModeEnabled,
  processing,
  submission,
  refetch
}) => {
  const [selectedTab, setSelectedTab] = useState(1)

  if (status === 'loading') return

  return (
    <>
      <Tab.Group className='h-[calc(100%-45px-51px)]' selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List>
          <Tab>
            <AdjustmentsHorizontalIcon className='h-6 w-6 mr-1' />
            <span className='md:block hidden'>Setup</span>
          </Tab>

          <Tab>
            <PencilSquareIcon className='h-6 w-6 mr-1' />
            <span className='md:block hidden'>Edit</span>
          </Tab>

          <Tab>
            <EyeIcon className='h-6 w-6 mr-1' />
            <span className='md:block hidden'>Preview</span>
          </Tab>
        </Tab.List>

        <Tab.Panels className='flex overflow-scroll h-full'>
          <Tab.Panel className='w-full md:mx-5'>
            <Setup
              id={id}
              name={name}
              readme={readme}
              playground={playground}
              classrooms={classrooms}
              document={document}
              documentText={documentText}
              mode={mode}
              prompt={prompt}
              socraticModeEnabled={socraticModeEnabled}
              stemModeEnabled={stemModeEnabled}
              processing={processing}
              submission={submission}
            />
          </Tab.Panel>

          <Tab.Panel className='w-full'>
            <Edit
              id={id}
              readme={readme}
              blocks={blocks}
              refetch={refetch}
            />
          </Tab.Panel>

          <Tab.Panel className='flex grow overflow-auto'>
            <Preview id={id} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default Overview
