import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { DocumentTextIcon, LightBulbIcon, PhotoIcon, QuestionMarkCircleIcon, VideoCameraIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Modal from '@components/Modal'
import Spinner from '@components/Spinner'

const CREATE_BLOCK_MUTATION = gql`
  mutation createBlock($input: CreateBlockInput!) {
    createBlock(input: $input) {
      block {
        blockId
        position
      }
      errors {
        message
      }
    }
  }
`

const AddBlockModal = ({ id, index, closeModal, isOpen, refetch }) => {
  const { mutate: createBlock, isLoading: isCreating } = useMutation({
    mutationFn: async variables => request(CREATE_BLOCK_MUTATION, { input: { educatorProjectId: id, position: index, ...variables } }),
    onSuccess: () => {
      refetch()
      closeModal()
    }
  })

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={closeModal}>
      <div className="flex flex-col px-5 py-5 h-full">
        <h3 className="font-heading text-2xl font-bold">Add a block</h3>

        <Choose>
          <When condition={isCreating}>
            <Spinner className="my-5 self-center" />
          </When>

          <Otherwise>
            <div className="grid grid-cols-3 gap-3 mt-3">
              <button
                type="button"
                onClick={() => createBlock({ blockType: 'TEXT' })}
                className="flex flex-row items-center mb-2 hover:bg-gray-100 p-2 rounded-md">
                <DocumentTextIcon className="h-5 w-5 shrink-0" />
                <span className="ml-2">Text</span>
              </button>

              <button
                type="button"
                onClick={() => createBlock({ blockType: 'VIDEO' })}
                className="flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 p-2 rounded-md">
                <VideoCameraIcon className="w-5 h-5 shrink-0" />
                <span className="">Video</span>
              </button>

              <button
                type="button"
                onClick={() => createBlock({ blockType: 'IMAGE' })}
                className="flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 p-2 rounded-md">
                <PhotoIcon className="w-5 h-5 shrink-0" />
                <span className="">Image</span>
              </button>

              <button
                type="button"
                onClick={() => createBlock({ blockType: 'QUESTION' })}
                className="flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 p-2 rounded-md">
                <QuestionMarkCircleIcon className="w-5 h-5 shrink-0" />
                <span className="shrink-0">Multiple choice</span>
              </button>

              <button
                type="button"
                onClick={() => createBlock({ blockType: 'FREE_RESPONSE' })}
                className="flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 p-2 rounded-md">
                <QuestionMarkCircleIcon className="w-5 h-5 shrink-0" />
                <span className="shrink-0">Free response</span>
              </button>

              <button
                type="button"
                onClick={() => createBlock({ blockType: 'REFLECTION' })}
                className="flex flex-row items-center gap-2 mb-2  hover:bg-gray-100 p-2 rounded-md">
                <LightBulbIcon className="h-5 w-5 shrink-0" />
                <span className="">Reflection</span>
              </button>

            </div>
          </Otherwise>
        </Choose>
      </div>
    </Modal>
  )
}

export default AddBlockModal
