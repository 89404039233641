import { useQuery } from '@tanstack/react-query'
import { Link, useSearchParams } from 'react-router-dom'

import { gql } from 'graphql-request'
import {
  EllipsisHorizontalIcon,
  CheckIcon
} from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import Card from '@components/Card'
import Select from '@components/Select'
import Table from '@components/Table'
import Spinner from '@components/Spinner'

import NoResults from './NoResults'

const SERIES_SUBMISSION_QUERY = gql`
  query seriesSubmissions($classroomId: ID!, $seriesId: ID!) {
    seriesSubmissions(classroomId: $classroomId, seriesId: $seriesId) {
      studentProfile {
        id
        fullName
      }
      submissions {
        id
        status
        score
        educatorProject {
          id
        }
      }
      score
    }
  }
`

const Submissions = ({ id, classrooms, educatorProjects, totalPossibleScore }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedClassroomId = searchParams.get('classroomId')

  const setSelectedClassroomId = classroomId => {
    setSearchParams({ classroomId })
  }

  const { data: { seriesSubmissions = [] } = {}, isInitialLoading: isLoading } = useQuery(
    ['classroomSubmissions', selectedClassroomId],
    async () => request(SERIES_SUBMISSION_QUERY, { classroomId: selectedClassroomId, seriesId: id }),
    { enabled: !!selectedClassroomId }
  )

  return (
    <div className='mx-5 my-3 h-full'>
      <Card className="flex flex-row items-center p-3 mb-5">
        <Select
          label="Select a classroom"
          value={selectedClassroomId}
          onChange={e => setSelectedClassroomId(e.target.value)}>
          <Select.Option key="" value="">Select a classroom</Select.Option>
          <For each="classroom" of={classrooms}>
            <Select.Option key={classroom.id} value={classroom.id}>{classroom.name}</Select.Option>
          </For>
        </Select>
      </Card>


      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-24' />
        </When>

        <When condition={!selectedClassroomId || seriesSubmissions.length < 1}>
          <NoResults classroomSelected={!!selectedClassroomId} />
        </When>

        <Otherwise>
          {/**
           * Complex height calculation for table scrolling:
           * On desktop: height = full height - breadcrumbs - tabs - toolbar - padding
           * On mobile: height = full height - navbar - breadcrumbs - tabs - toolbar - padding
           **/}
          <div className="lg:max-h-[calc(100vh-84px-50px-45px-2.75rem)] max-h-[calc(100vh-57px-84px-50px-45px-2.75rem)] flex overflow-auto whitespace-nowrap w-full rounded-lg border border-gray-200 bg-white">
            <Table className="table-fixed w-auto min-w-min">
              <Table.Head>
                <Table.Row>
                  <Table.Header className="text-base align-top min-w-[200px] sticky left-0 top-0 z-10 bg-gray-50 ">Students</Table.Header>
                  <Table.Header className="text-base align-top sticky top-0 bg-gray-50">Total</Table.Header>

                  <For each="project" of={educatorProjects}>
                    <Table.Header key={project.id} className="sticky top-0 bg-gray-50 w-text-base min-w-[200px] w-full p-2 align-top flex-nowrap overflow-ellipsis whitespace-nowrap overflow-hidden">
                      {project.name}
                    </Table.Header>
                  </For>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each="seriesSubmission" of={seriesSubmissions}>
                  <Table.Row key={seriesSubmission.studentProfile.id}>
                    <Table.Cell className='border-r border-gray-300 sticky left-0 bg-white'>{seriesSubmission.studentProfile.fullName}</Table.Cell>
                    <Table.Cell>{seriesSubmission.score} / {totalPossibleScore}</Table.Cell>

                    <For each="project" of={educatorProjects} index="index">
                      <With submission={seriesSubmission.submissions.find(s => s.educatorProject.id === project.id)}>
                        <Choose>
                          <When condition={submission?.status === 'IN_PROGRESS'}>
                            <Table.Cell key={index} className="whitespace-nowrap bg-blue-200">
                              <Link to={`/educators/submissions/${submission.id}`}>
                                <EllipsisHorizontalIcon className="w-5 h-5 mx-auto" />
                              </Link>
                            </Table.Cell>
                          </When>

                          <When condition={submission?.status === 'COMPLETED'}>
                            <Table.Cell key={index} className="whitespace-nowrap bg-green-200">
                              <Link to={`/educators/submissions/${submission.id}`}>
                                <Choose>
                                  <When condition={project.totalPossibleScore > 0}>
                                    <div className='flex justify-center'>
                                      {submission.score} / {project.totalPossibleScore}
                                    </div>
                                  </When>

                                  <Otherwise>
                                    <CheckIcon className="w-5 h-5 mx-auto" />
                                  </Otherwise>
                                </Choose>
                              </Link>
                            </Table.Cell>
                          </When>

                          <Otherwise>
                            <Table.Cell key={index} />
                          </Otherwise>
                        </Choose>
                      </With>
                    </For>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default Submissions
