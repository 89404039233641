import { twMerge } from 'tailwind-merge'

import { formatShortTimestamp } from '@helpers/format'
import SecureMarkdown from '@components/SecureMarkdown'

const messageTheme = (status, from) => {
  if (status === 'UNSAFE_INPUT') {
    return 'bg-red-100 border-gray-500'
  }

  if (from === 'ASSISTANT') {
    return 'bg-blue-100 border-blue-900'
  }

  return 'bg-white border-gray-500'
}

const Message = ({ status, from, text, createdAt }) => (
  <div className={twMerge('flex flex-col max-w-[75%] mb-3', from === 'USER' ? 'self-end' : 'self-start')}>
    <div className="w-full flex flex-row self-end">
      <div className={twMerge("w-full border shadow rounded-lg p-2 overflow-x-auto", messageTheme(status, from))}>
        <SecureMarkdown content={text} />
      </div>
    </div>

    <If condition={status === 'UNSAFE_INPUT'}>
      <small className="self-end mt-1">Unsafe content detected</small>
    </If>

    <small className="self-end mt-1">{formatShortTimestamp(createdAt)}</small>
  </div>
)

export default Message
