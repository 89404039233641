import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { DocumentPlusIcon } from '@heroicons/react/24/solid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { twMerge } from 'tailwind-merge'

import { request } from '@helpers/graphql'

import TextBlock from './TextBlock'
import DeleteBlockModal from './DeleteBlockModal'
import AddBlockModal from './AddBlockModal'
import ReflectionBlock from './ReflectionBlock'
import QuestionBlock from './QuestionBlock'
import FreeResponseBlock from './FreeResponseBlock'
import VideoBlock from './VideoBlock'
import ImageBlock from './ImageBlock'
import { PlusIcon } from '@heroicons/react/24/outline'

const DELETE_BLOCK_MUTATION = gql`
  mutation deleteBlock($input: DeleteBlockInput!) {
    deleteBlock(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const UPDATE_BLOCK_MUTATION = gql`
  mutation UpdateBlock($input: UpdateBlockInput!) {
    updateBlock(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const Edit = ({ id, blocks, refetch }) => {
  const [blockIdToDelete, setBlockIdToDelete] = useState(null)
  const [blockIndexToAdd, setBlockIndexToAdd] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [sortedBlocks, setSortedBlocks] = useState([])
  const { mutate: deleteBlock, isLoading: isDeleting } = useMutation({
    mutationFn: async variables => request(DELETE_BLOCK_MUTATION, { input: variables }),
    onSuccess: () => {
      refetch()
      setBlockIdToDelete(null)
    }
  })
  const { mutate: updateBlock } = useMutation({
    mutationFn: async variables => request(UPDATE_BLOCK_MUTATION, { input: variables }),
    onSuccess: refetch
  })

  const onDragStart = () => {
    setIsDragging(true)
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      setIsDragging(false)
      return
    }

    if (result.destination.index === result.source.index) {
      setIsDragging(false)
      return
    }

    const updatedBlocks = Array.from(sortedBlocks)
    const [removed] = updatedBlocks.splice(result.source.index, 1)
    updatedBlocks.splice(result.destination.index, 0, removed)

    setSortedBlocks(updatedBlocks)
    setIsDragging(false)
    updateBlock({ blockId: removed.blockId, position: result.destination.index })
  }

  useEffect(() => {
    setSortedBlocks(blocks)
  }, [blocks, setSortedBlocks])

  return (
    <>
      <div className='flex flex-col sm:p-5 pt-5'>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId='block'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <For each='block' of={sortedBlocks} index='index'>
                  <Draggable key={block.id} draggableId={block.id} index={index}>
                    {provided => (
                      <div
                        className='flex flex-col group/block'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Choose>
                          <When condition={block.__typename === 'Text'}>
                            <TextBlock
                              key={block.blockId}
                              showDeleteModal={() => setBlockIdToDelete(block.blockId)}
                              deleteBlock={() => setBlockIdToDelete(block.blockId)}
                              refetch={refetch}
                              dragHandleProps={provided.dragHandleProps}
                              {...block}
                            />
                          </When>

                          <When condition={block.__typename === 'Reflection'}>
                            <ReflectionBlock
                              key={block.blockId}
                              showDeleteModal={() => setBlockIdToDelete(block.blockId)}
                              deleteBlock={() => setBlockIdToDelete(block.blockId)}
                              refetch={refetch}
                              dragHandleProps={provided.dragHandleProps}
                              {...block}
                            />
                          </When>

                          <When condition={block.__typename === 'Question'}>
                            <QuestionBlock
                              key={block.blockId}
                              showDeleteModal={() => setBlockIdToDelete(block.blockId)}
                              deleteBlock={() => setBlockIdToDelete(block.blockId)}
                              refetch={refetch}
                              dragHandleProps={provided.dragHandleProps}
                              {...block}
                            />
                          </When>

                          <When condition={block.__typename === 'FreeResponse'}>
                            <FreeResponseBlock
                              key={block.blockId}
                              showDeleteModal={() => setBlockIdToDelete(block.blockId)}
                              deleteBlock={() => setBlockIdToDelete(block.blockId)}
                              refetch={refetch}
                              dragHandleProps={provided.dragHandleProps}
                              {...block}
                            />
                          </When>

                          <When condition={block.__typename === 'Video'}>
                            <VideoBlock
                              key={block.blockId}
                              showDeleteModal={() => setBlockIdToDelete(block.blockId)}
                              deleteBlock={() => setBlockIdToDelete(block.blockId)}
                              refetch={refetch}
                              dragHandleProps={provided.dragHandleProps}
                              {...block}
                            />
                          </When>

                          <When condition={block.__typename === 'Image'}>
                            <ImageBlock
                              key={block.blockId}
                              showDeleteModal={() => setBlockIdToDelete(block.blockId)}
                              deleteBlock={() => setBlockIdToDelete(block.blockId)}
                              refetch={refetch}
                              dragHandleProps={provided.dragHandleProps}
                              {...block}
                            />
                          </When>
                        </Choose>

                        <div className={twMerge('h-4 w-1 self-center bg-gray-200', isDragging && 'invisible')} />
                        <button
                          aria-label={`Add block at position ${index + 1}`}
                          onClick={() => setBlockIndexToAdd(block.position + 1)}
                          className={twMerge('flex w-full group/button self-center', isDragging && 'invisible')}
                        >
                          <div className='h-0.5 w-full bg-blue-500 self-center mr-2 group-hover/button:visible invisible' />
                          <PlusIcon className='shrink-0 h-8 w-8 my-1 self-center rounded-full p-1 text-white bg-gray-400 group-hover/button:bg-blue-500' />
                          <div className='h-0.5 w-full bg-blue-500 self-center ml-2 group-hover/button:visible invisible' />
                        </button>
                        <div className={twMerge('h-4 w-1 self-center bg-gray-200', isDragging && 'invisible')} />
                      </div>
                    )}
                  </Draggable>
                </For>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <If condition={sortedBlocks.length === 0}>
          <button
            type='button'
            onClick={() => setBlockIndexToAdd(0)}
            className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            <DocumentPlusIcon className='mx-auto h-10 w-10 text-gray-400' />
            <span className='mt-2 block text text-gray-900'>Add a new block</span>
          </button>
        </If>
      </div>

      <DeleteBlockModal
        isOpen={!!blockIdToDelete}
        closeModal={() => setBlockIdToDelete(null)}
        deleteBlock={() => deleteBlock({ blockId: blockIdToDelete })}
        isDeleting={isDeleting}
      />

      <AddBlockModal
        id={id}
        index={blockIndexToAdd}
        isOpen={blockIndexToAdd !== null}
        closeModal={() => setBlockIndexToAdd(null)}
        refetch={refetch}
      />
    </>
  )
}

export default Edit
