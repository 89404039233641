import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { useFlashMessage } from '@components/FlashMessage'

const UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION = gql`
  mutation updateEducatorProjectSubmission($input: UpdateEducatorProjectSubmissionInput!) {
    updateEducatorProjectSubmission(input: $input) {
      educatorProjectSubmission {
        id
        status
      }
      errors {
        message
      }
    }
  }
`

const SubmitModal = ({ closeModal, isOpen, seriesId, educatorProjectId }) => {
  const navigate = useNavigate()
  const { setFlashMessage } = useFlashMessage()

  const { mutateAsync: submitProject, isLoading } = useMutation({
    mutationFn: async () => request(UPDATE_EDUCATOR_PROJECT_SUBMISSION_MUTATION, { input: { educatorProjectId, status: 'COMPLETED' } })
  })

  const submit = async () => {
    await submitProject()

    closeModal()
    setFlashMessage('Your project has been submitted.', 'success')
    navigate(`/students/courses/${seriesId}`)
  }


  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}>
      <div className="px-5 py-5 h-full">
        <h3 className="font-heading text-2xl font-bold">Would you like to submit your project?</h3>
        <p className="mt-3">You won't be able to make changes again.</p>

        <div className="flex flex-row justify-between items-center mt-3">
          <button
            className="text-red-500 cursor-pointer"
            onClick={closeModal}
          >
            cancel
          </button>

          <Button
            disabled={isLoading}
            label={isLoading ? 'Submitting...' : 'Submit'}
            onClick={submit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SubmitModal
