import React, { useRef } from 'react'
import { PhotoIcon } from '@heroicons/react/24/outline'

const FileInput = props => {
  const fileInputRef = useRef()

  return (
    <>
      <button
        type='button'
        htmlFor='upload-button'
        className='flex items-center rounded-full hover:bg-gray-200 p-1 mr-3'
        onClick={() => fileInputRef.current.click()}
      >

        <PhotoIcon
          data-tutorial='start-recording-step'
          className='h-7 w-7 group-disabled:text-gray-400'
        />
      </button>

      <input
        ref={fileInputRef}
        id='upload-button'
        type='file'
        accept='.jpg,.jpeg,.png'
        capture='environment'
        multiple
        className='hidden'
        {...props}
      />
    </>
  )
}

export default FileInput
