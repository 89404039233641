import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import MathNode from './MathNode.jsx'

const markdownMath = md => {
  const mathBlock = (state, silent) => {
    const start = state.pos
    const found = state.src.indexOf('$$', start)
    if (found === -1) return false

    const end = state.src.indexOf('$$', found + 2)
    if (end === -1) return false

    if (!silent) {
      const token = state.push('math_block', 'math', 0)
      token.content = state.src.slice(found + 2, end)
      token.markup = '$$'
    }

    state.pos = end + 2
    return true
  }

  md.inline.ruler.after('escape', 'math_block', mathBlock)

  md.renderer.rules.math_block = (tokens, idx) => {
    return `<math value="${tokens[idx].content}"></math>`
  }
}

const MathExtension = Node.create({
  name: 'math',
  group: 'block',
  atom: false,

  addAttributes: () => {
    return {
      value: {
        default: ''
      }
    }
  },

  addCommands: () => {
    return {
      toggleMath: () => ({ editor, tr, commands }) => {
        const { from, to } = editor.state.selection
        const selectedText = editor.state.doc.textBetween(from, to)

        return commands.insertContent({
          type: 'math',
          attrs: {
            value: selectedText || 'x^2' // Use selected text, or default to 'x^2' if no selection
          }
        })
      },
      insertMath: math => ({ commands }) => {
        return commands.insertContent({
          type: 'math',
          attrs: {
            value: math
          }
        })
      }
    }
  },

  addStorage: () => {
    return {
      markdown: {
        // HTML to markdown
        serialize: (state, node) => {
          state.write('$$' + node.attrs.value)
          state.renderContent(node)
          state.flushClose(1)
          state.write('$$')
          state.closeBlock(node)
        },
        // Markdown to HTML
        parse: {
          setup: markdownit => {
            markdownit.use(markdownMath)
          }
        }
      }
    }
  },

  parseHTML: () => {
    return [
      {
        tag: 'math'
      }
    ]
  },

  renderHTML: ({ HTMLAttributes }) => {
    return ['math', mergeAttributes(HTMLAttributes)]
  },

  addNodeView: () => {
    return ReactNodeViewRenderer(MathNode)
  }
})

export default MathExtension
