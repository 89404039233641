import { useForm, Controller } from 'react-hook-form'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

import Button from '@components/Button'
import TextInput from '@components/TextInput'
import ShowMore from '@components/ShowMore'
import TextArea from '@components/TextArea'
import Toggle from '@components/Toggle'
import Pill from '@components/Pill'
import Tooltip from '@components/Tooltip'

import { useEducatorProject, useDocument, useUpdateEducatorProject } from './hooks'

const Setup = ({ id, name, playground, documentText, document, prompt, socraticModeEnabled, stemModeEnabled }) => {
  const { refetch } = useEducatorProject({ id })
  const { updateProject, status: updateStatus } = useUpdateEducatorProject({ id })
  const { deleteDocument } = useDocument({ id, onDeleted: refetch })
  const { register, handleSubmit, formState: { errors }, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      name,
      playground,
      prompt,
      socraticModeEnabled,
      stemModeEnabled
    }
  })

  const submit = data => {
    updateProject(data)
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className='flex flex-col py-5'
    >
      <div className='mb-3'>
        <TextInput
          id='name'
          label='Name'
          required
          {...register('name', { required: 'Name is required' })}
        />
        <If condition={errors.name}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
        </If>
      </div>

      <If condition={document}>
        <button
          onClick={() => deleteDocument()}
          type='button'
          className='flex items-center bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg cursor-pointer w-fit'
        >
          <XMarkIcon className='h-6 w-6 inline-block mr-3' />
          {document.filename}
        </button>

        <If condition={documentText}>
          <p className='text-sm p-3 mt-3 [&_button]:bg-gray-100 bg-gray-100 rounded-md'>
            <ShowMore content={documentText} />
          </p>
        </If>
      </If>

      <div className='mb-5'>
        <TextArea
          className='h-[100px]'
          id='description'
          label='Prompt'
          {...register('prompt')}
        />
        <If condition={errors.prompt}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.prompt.message}</p>
        </If>

        <p className='mt-1 text-sm leading-tight'>
          You can describe a subject to focus on, the contents of a particular lesson, or even the style of the tutor. Be creative!
        </p>
      </div>

      <div className='mb-3'>
        <Controller
          name='socraticModeEnabled'
          control={control}
          render={({ field }) => (
            <Toggle
              {...field}
              label='Socratic mode'
              description='This turns on probing questions instead of direct answers'
            />
          )}
        />
      </div>

      <div className='mb-3'>
        <Controller
          name='stemModeEnabled'
          control={control}
          render={({ field }) => (
            <Toggle
              {...field}
              id='stem-mode-enabled'
              label={
                <>
                  STEM mode
                  <Pill id="new-feature-pill" theme="secondary" label="New" className='ml-2'/>
                  <Tooltip anchorSelect="#new-feature-pill">Mathematics mode is now STEM mode, give it a try ✨</Tooltip>
                </>
              }
              description='This turns on accurate calculations and visualisations for STEM related subjects (e.g. mathematics, science, physics, chemistry, etc.)'
            />
          )}
        />
      </div>

      <div className='flex flex-row items-center justify-end'>
        <If condition={updateStatus === 'success'}>
          <CheckIcon className='h-5 w-5 mr-2' />
        </If>

        <Button
          disabled={updateStatus === 'loading'}
          label={updateStatus === 'loading' ? 'Saving...' : 'Save'}
          type='submit'
          className='w-fit'
        />
      </div>
    </form>
  )
}

export default Setup
