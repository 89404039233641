import { DocumentIcon, CheckIcon, CheckBadgeIcon } from '@heroicons/react/24/outline'
import { truncate } from 'lodash'

import { formatTimestamp } from '@helpers/format'
import Badge from '@components/Badge'
import SecureMarkdown from '@components/SecureMarkdown'

const removeMarkdown = text => {
  const inline = /\*{1,2}(.*?)\*{1,2}/g
  const strikeThrough = /~{2}(.*?)~{2}/g
  const links = /\[(.*?)\]\((.*?)\)/g
  const images = /!\[(.*?)\]\((.*?)\)/g
  const headers = /#{1,6}\s+(.*?)(?=\n|$)/g
  const blockquotes = />\s+(.*?)(?=\n|$)/g
  const horizontalRules = /\n-{3,}\n/g
  const codeBlocks = /```[^`]*```/g
  const inlineCode = /`(.*?)`/g

  return text
    .replace(inline, '$1')
    .replace(strikeThrough, '$1')
    .replace(links, '$1')
    .replace(images, '')
    .replace(headers, '$1')
    .replace(blockquotes, '$1')
    .replace(horizontalRules, '')
    .replace(codeBlocks, '')
    .replace(inlineCode, '$1')
}

const TextBlock = ({ status, content, submittedAt }) => {
  const truncatedContent = truncate(content, { length: 50 })
  const formattedContent = removeMarkdown(truncatedContent)
  return (
    <div className="flex flex-row mb-2">
      <div className="flex flex-col mr-2">
        <Badge theme={status === 'COMPLETED' ? 'success' : 'light'}>
          <DocumentIcon className="h-5 w-5" />
        </Badge>
        <div className="h-full bg-gray-200 w-2 rounded-md mt-2 self-center" />
      </div>

      <div className="ml-3 pb-5">
        <h4 className="text-lg font-semibold inline">{formattedContent}</h4>

        <If condition={submittedAt}>
          <p className="text-sm">{formatTimestamp(submittedAt)}</p>
        </If>

        <If condition={status !== 'COMPLETED' && status !== 'IN_PROGRESS'}>
          <p className="text-sm">Not started</p>
        </If>
      </div>
    </div>
  )
}

export default TextBlock
