import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams, useNavigate, Routes, Route } from 'react-router-dom'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import ProjectSubmission from '@pages/ProjectSubmission'

import Overview from './Overview'
import LessonPlanList from './LessonPlanList'

const UNIT_QUERY = gql`
  query unit($slug: String!) {
    unit(slug: $slug) {
      id
      slug
      name
      imageUrl
      summary
      projectCount
      nextProject {
        id
      }
      sections {
        id
        name
        projects {
          id
          slug
          name
          summary
          lessonPlan
          projectSubmission {
            status
            id
          }
        }
      }
    }
  }
`

const UnitDetails = ({ pathPrefix }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { slug } = useParams()
  const {
    isLoading,
    data: { unit } = {}
  } = useQuery(['unit', slug], async () => request(UNIT_QUERY, { slug }))

  if (isLoading) {
    return <Spinner className="relative top-0 left-0 flex items-center justify-center w-full h-screen" />
  }

  return (
    <Routes>
      <Route index element={
        <Overview
          pathPrefix={pathPrefix}
          unitName={unit.name}
          unitSummary={unit.summary}
          projectCount={unit.projectCount}
          sections={unit.sections}
          nextProject={unit.nextProject}
          imageUrl={unit.imageUrl}
        />
      }
      />
      <Route path="/lesson_plans" element={
        <LessonPlanList
          pathPrefix={pathPrefix}
          unitSlug={slug}
          unitName={unit.name}
          sections={unit.sections} />
      } />
      <Route path="/submissions/:id" element={
        <ProjectSubmission
          onProjectExit={() => {
            queryClient.invalidateQueries(['unit', slug])
            navigate(`/${pathPrefix}/units/${slug}`)
          }}
        />}
      />
    </Routes>
  )
}

export default UnitDetails
