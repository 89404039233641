import React, { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  ArrowTrendingUpIcon,
  BellAlertIcon,
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  XMarkIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import { Link, useParams } from 'react-router-dom'

import { request } from '@helpers/graphql'
import { formatTimestamp } from '@helpers/format'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import Spinner from '@components/Spinner'
import Card from '@components/Card'
import CopyToClipboardInput from '@components/CopyToClipboardInput'
import Button from '@components/Button'
import Notification from '@components/Notification'

import ChatHistoryModal from './ChatHistoryModal'

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      privateStudentChatsEnabled
    }
  }
`

const ASSIGNMENT_QUERY = gql`
  query assignment($id: ID!, $page: Int!, $privateChatsEnabled: Boolean!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        classroom {
          id
          name
        }
        closed
        createdAt
        summaryStatistics {
          positiveSentimentCount
          neutralSentimentCount
          negativeSentimentCount
          curiousLearningStatusCount
          neutralLearningStatusCount
          confusedLearningStatusCount
          totalChatCount
          totalEngagementCount
        }
        chats(page: $page, perPage: 50) @skip(if: $privateChatsEnabled) {
          pagesCount
          nodesCount
          nodes {
            id
            createdAt
            profile {
              fullName
            }
            learningStatus
            sentiment
            flaggedByModeration
            messages {
              nodesCount
            }
          }
        }
      }
    }
  }
`

const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation updateTutorAssignment($input: UpdateTutorAssignmentInput!) {
    updateTutorAssignment(input: $input) {
      assignment {
        id
      }
    }
  }
`

const SessionDetails = ({ tutorId }) => {
  const { id: assignmentId } = useParams()
  const [page, setPage] = useState(1)
  const [selectedChatId, setSelectedChatId] = useState(null)

  const { data: { organization = {} } = {}, isFetched } = useQuery({
    queryKey: ['organization'],
    queryFn: async () => request(ORGANIZATION_QUERY)
  })

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['assignment', assignmentId, page],
    queryFn: async () => request(ASSIGNMENT_QUERY, { id: assignmentId, page, privateChatsEnabled: organization.privateStudentChatsEnabled }),
    refetchInterval: 120000, // Refetch every 2 minutes
    enabled: isFetched
  })

  const { mutate: update, isLoading: isUpdating } = useMutation({
    mutationFn: async data => request(UPDATE_ASSIGNMENT_MUTATION, { input: { assignmentId, ...data } }),
    onSuccess: () => refetch()
  })

  const assignment = data?.node || {}
  const chats = data?.node?.chats?.nodes || []
  const pagesCount = data?.node?.chats?.pagesCount || 0
  const classroom = data?.node?.classroom || {}
  const privateStudentChatsEnabled = organization.privateStudentChatsEnabled

  const {
    positiveSentimentCount,
    negativeSentimentCount,
    neutralSentimentCount,
    curiousLearningStatusCount,
    confusedLearningStatusCount,
    neutralLearningStatusCount,
    totalChatCount,
    totalEngagementCount
  } = data?.node?.summaryStatistics || {}

  if (isLoading || !isFetched) {
    return <Spinner className='flex items-center justify-center w-full mt-32' />
  }

  return (
    <div className='md:px-5 py-3'>
      <Link
        to={`/educators/tutors/${tutorId}/sessions`}
        className='block text-blue-500 mb-3'
      >
        <ChevronLeftIcon className='w-4 h-4 inline-flex mr-1' />
        back
      </Link>

      <Card className='flex justify-between p-5'>
        <div>
          <div className='flex'>
            <h2 className='text-xl font-semibold mr-2'>
              {classroom.name}
            </h2>

            <Choose>
              <When condition={assignment.closed}>
                <span className='bg-gray-100 text-gray-900 px-2 py-1 rounded-full w-fit h-fit text-sm'>
                  Closed
                </span>
              </When>

              <Otherwise>
                <span className='bg-green-100 text-green-900 px-2 py-1 rounded-full w-fit h-fit text-sm'>
                  Active
                </span>
              </Otherwise>
            </Choose>
          </div>

          <p className='text-gray-700 text-sm mt-1'>Started {formatTimestamp(assignment.createdAt)}</p>
        </div>

        <If condition={!assignment.closed}>
          <CopyToClipboardInput
            className='w-1/4'
            value={`${window.location.origin}/students/tutors/assignments/${assignmentId}`}
            label='Student session link'
          />

          <Button
            disabled={isUpdating}
            theme='error'
            variant='outlined'
            label={isUpdating ? 'Updating...' : <span className='flex items-center'>End session<XMarkIcon className='h-6 w-6 ml-2' /></span>}
            onClick={() => update({ closed: true })}
          />
        </If>
      </Card>

      <div className='flex flex-col sm:flex-row mt-5 gap-5'>
        <Card className='p-5 stats-overview'>
          <h3 className='text-lg font-semibold mb-3'>Sentiment</h3>

          <div className='flex h-4 rounded-full overflow-clip bg-gray-200'>
            <div className='bg-green-500' style={{ width: `${positiveSentimentCount / totalChatCount * 100}%` }} />
            <div className='bg-gray-200' style={{ width: `${neutralSentimentCount / totalChatCount * 100}%` }} />
            <div className='bg-red-500' style={{ width: `${negativeSentimentCount / totalChatCount * 100}%` }} />
          </div>

          <p className='w-full flex justify-around mt-2 text-sm'>
            <If condition={positiveSentimentCount > 0}>
              <span className='text-green-700'>{positiveSentimentCount}  positive</span>
            </If>

            <If condition={neutralSentimentCount > 0}>
              <span className='text-gray-700'>{neutralSentimentCount} neutral</span>
            </If>

            <If condition={negativeSentimentCount > 0}>
              <span className='text-red-700'>{negativeSentimentCount} negative</span>
            </If>
          </p>
        </Card>

        <Card className='p-5 stats-overview'>
          <h3 className='text-lg font-semibold mb-3'>Learning</h3>

          <div className='flex h-4 rounded-full overflow-clip bg-gray-200'>
            <div className='bg-blue-500' style={{ width: `${curiousLearningStatusCount / totalChatCount * 100}%` }} />
            <div className='bg-gray-200' style={{ width: `${neutralLearningStatusCount / totalChatCount * 100}%` }} />
            <div className='bg-orange-500' style={{ width: `${confusedLearningStatusCount / totalChatCount * 100}%` }} />
          </div>

          <p className='w-full flex justify-around mt-2 text-sm'>
            <If condition={curiousLearningStatusCount > 0}>
              <span className='text-blue-700'>{curiousLearningStatusCount} curious</span>
            </If>

            <If condition={neutralLearningStatusCount > 0}>
              <span className='text-gray-700'>{neutralLearningStatusCount} neutral</span>
            </If>

            <If condition={confusedLearningStatusCount > 0}>
              <span className='text-orange-700'>{confusedLearningStatusCount} confused</span>
            </If>
          </p>
        </Card>

        <Card className='p-5 stats-overview'>
          <h3 className='text-lg font-semibold mb-3'>Engagement</h3>

          <div className='flex'>
            <Choose>
              <When condition={totalEngagementCount / totalChatCount > 5}>
                <span className='inline-flex leading-none items-center bg-green-100 text-green-900 px-3 py-2 rounded-full'>
                  High
                  <ArrowTrendingUpIcon className='w-5 h-5 ml-1' />
                </span>
              </When>

              <When condition={totalEngagementCount > 0}>
                <span className='inline-flex leading-none items-center bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                  Low
                  <ChatBubbleLeftIcon className='w-5 h-5 ml-1' />
                </span>
              </When>

              <Otherwise>
                <span className='inline-flex leading-none items-center bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                  None
                  <ChatBubbleLeftIcon className='w-5 h-5 ml-1' />
                </span>
              </Otherwise>
            </Choose>
          </div>
        </Card>
      </div>

      <Choose>
        <When condition={privateStudentChatsEnabled}>
          <Notification className='border-0 font-normal text-gray-900 flex items-cente leading-none mt-5'>
            <InformationCircleIcon className='h-6 w-6 mr-2' />
            <span>Viewing student chat history has been disabled for your organization</span>
          </Notification>
        </When>

        <Otherwise>
          <div className='overflow-x-scroll mx-0 p-0.5 mt-5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='40%'>Student</Table.Header>
                  <Table.Header width='20%'>Sentiment</Table.Header>
                  <Table.Header width='20%'>Learning</Table.Header>
                  <Table.Header width='20%'>Engagement</Table.Header>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={chats.length === 0}>
                  <Table.Row>
                    <Table.Cell colSpan='6'>
                      You don't have any student activity yet 😀
                    </Table.Cell>
                  </Table.Row>
                </If>

                <For each='chat' of={chats}>
                  <Table.Row className='hover:cursor-pointer' key={chat.id}>
                    <Table.Cell className='flex items-center' onClick={() => setSelectedChatId(chat.id)}>
                      {chat.profile.fullName}

                      <If condition={chat.flaggedByModeration}>
                        <span className='group ml-2 inline-flex items-center bg-red-100 text-red-900 p-2 rounded-full'>
                          <BellAlertIcon className='w-5 h-5' />
                          <span className='group-hover:inline hidden text-xs ml-2 leading-tight'>Flagged by moderation system</span>
                        </span>
                      </If>
                    </Table.Cell>

                    <Table.Cell className='' onClick={() => setSelectedChatId(chat.id)}>
                      <Choose>
                        <When condition={chat.sentiment === 'POSITIVE'}>
                          <span className='bg-green-100 text-green-900 px-3 py-2 rounded-full'>
                            Positive 👍
                          </span>
                        </When>

                        <When condition={chat.sentiment === 'NEGATIVE'}>
                          <span className='bg-red-100 text-red-900 px-3 py-2 rounded-full'>
                            Negative 👎
                          </span>
                        </When>

                        <When condition={chat.sentiment === 'NEUTRAL'}>
                          <span className='bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                            Neutral &#x1F60A;
                          </span>
                        </When>
                      </Choose>
                    </Table.Cell>

                    <Table.Cell onClick={() => setSelectedChatId(chat.id)}>
                      <Choose>
                        <When condition={chat.learningStatus === 'CURIOUS'}>
                          <span className='bg-blue-100 text-blue-900 px-3 py-2 rounded-full'>
                            Curious 🤔
                          </span>
                        </When>

                        <When condition={chat.learningStatus === 'CONFUSED'}>
                          <span className='bg-orange-100 text-orange-900 px-3 py-2 rounded-full'>
                            Confused 😖
                          </span>
                        </When>

                        <When condition={chat.learningStatus === 'NEUTRAL'}>
                          <span className='bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                            Neutral &#x1F60A;
                          </span>
                        </When>
                      </Choose>
                    </Table.Cell>

                    <Table.Cell onClick={() => setSelectedChatId(chat.id)}>
                      <Choose>
                        <When condition={chat.messages.nodesCount > 10}>
                          <span className='inline-flex leading-none items-center bg-green-100 text-green-900 px-3 py-2 rounded-full'>
                            {chat.messages.nodesCount}
                            <ChatBubbleLeftIcon className='w-4 h-4 ml-1' />
                          </span>
                        </When>
                        <Otherwise>
                          <span className='inline-flex leading-none items-center bg-gray-100 text-gray-900 px-3 py-2 rounded-full'>
                            {chat.messages.nodesCount}
                            <ChatBubbleLeftIcon className='w-4 h-4 ml-1' />
                          </span>
                        </Otherwise>
                      </Choose>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>

            <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />

            <If condition={selectedChatId}>
              <ChatHistoryModal
                chatId={selectedChatId}
                isOpen={!!selectedChatId}
                closeModal={() => setSelectedChatId(null)}
              />
            </If>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default SessionDetails
