import { useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useNavigate, Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { request } from '@helpers/graphql'
import { useForm } from 'react-hook-form'

import Spinner from '@components/Spinner'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import Modal from '@components/Modal'
import PageHeading from '@components/PageHeading'
import Table from '@components/Table'
import { useSubscription } from '../hooks/subscription'
import { useOnboardingChecklist } from '../hooks/onboardingChecklist'
import ProFeatureTooltip from '../components/ProFeatureTooltip'

const CLASSROOMS_QUERY = gql`
  query classrooms {
    me {
      profile {
        ... on EducatorProfile {
          classrooms {
            id
            name
            studentProfiles {
              id
            }
          }
        }
      }
    }
  }
`

const CREATE_CLASSROOM_MUTATION = gql`
  mutation createClassroom($createClassroomInput: CreateClassroomInput!) {
    createClassroom(input: $createClassroomInput) {
      classroom {
        id
        name
        educatorProfile {
          id
          fullName
        }
      }
    }
  }
`

const ClassroomList = () => {
  const navigate = useNavigate()
  const [showClassroomModal, setShowClassroomModal] = useState(false)
  const { hasProFeatures } = useSubscription()
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onTouched' })

  const { markOnboardingItemCompleted } = useOnboardingChecklist()

  const { isLoading, data: { me: { profile: { classrooms = [] } = {} } = {} } = {} } = useQuery(['classrooms'], async () => request(CLASSROOMS_QUERY))
  const { mutateAsync: createClassroom, isLoading: isCreating } = useMutation(
    ['createClassroom'], async variables => request(CREATE_CLASSROOM_MUTATION, variables),
    {
      onSuccess: data => {
        navigate(`/educators/classrooms/${data.createClassroom.classroom.id}`)
        markOnboardingItemCompleted('createdClassroom')
      }
    }
  )

  const submit = data => {
    createClassroom({ createClassroomInput: data })
  }

  return (
    <>
      <PageHeading title='Classrooms' />

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
        </When>

        <Otherwise>
          <div className='flex justify-end items-center'>
            <If condition={!hasProFeatures}>
              <ProFeatureTooltip anchorSelect='#new-classroom-button' />
            </If>

            <Button
              id='new-classroom-button'
              disabled={!hasProFeatures}
              className='w-fit self-end my-3 md:mr-5 mr-3'
              onClick={() => setShowClassroomModal(true)}
              label='New classroom'
            />
          </div>

          <div className='overflow-x-scroll md:mx-5 mx-0 p-0.5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='50%'>Name</Table.Header>
                  <Table.Header width='40%'>Students</Table.Header>
                  <Table.Header width='10%' />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={classrooms.length === 0}>
                  <Table.Row>
                    <Table.Cell colSpan='4' className='text-center'>
                      You don't have any classrooms yet 😀 Go ahead and create one!
                    </Table.Cell>
                  </Table.Row>
                </If>

                <For each='classroom' of={classrooms}>
                  <Table.Row key={classroom.id}>
                    <Table.Cell>
                      <Link className='block' to={`/educators/classrooms/${classroom.id}`}>
                        {classroom.name}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block' to={`/educators/classrooms/${classroom.id}`}>
                        {classroom.studentProfiles.length} students
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block text-blue-500' to={`/educators/classrooms/${classroom.id}`}>
                        view
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>

          <Modal
            isOpen={showClassroomModal}
            onClose={() => setShowClassroomModal(false)}
          >
            <form
              onSubmit={handleSubmit(submit)}
              className='flex flex-col space-y-4 mt-5'
            >
              <h2 className='font-heading text-3xl font-bold'>New classroom</h2>

              <div>
                <TextInput
                  id='name'
                  label='Name'
                  required
                  {...register('name', { required: 'Name is required' })}
                />
                <If condition={errors.name}>
                  <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
                </If>
              </div>

              <Button
                disabled={isCreating}
                className='w-fit ml-auto'
                type='submit'
                label={isCreating ? 'Saving...' : 'Save'}
              />
            </form>
          </Modal>
        </Otherwise>
      </Choose>
    </>
  )
}

export default ClassroomList
