import React, { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  ArrowTrendingUpIcon,
  BellAlertIcon,
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  XMarkIcon,
  InformationCircleIcon,
  CloudArrowDownIcon
} from '@heroicons/react/24/outline'
import { Link, useParams } from 'react-router-dom'

import { request } from '@helpers/graphql'
import { formatTimestamp, formatDuration } from '@helpers/format'
import Table from '@components/Table'
import Pagination from '@components/Pagination'
import Spinner from '@components/Spinner'
import Card from '@components/Card'

import { ResponsiveContainer, BarChart, ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Scatter } from 'recharts'
import jsonToCsvExport from 'json-to-csv-export'
import Select from '@components/Select'
import Button from '@components/Button'
import { capitalize, orderBy, round } from 'lodash'

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      privateStudentChatsEnabled
    }
  }
`

const ASSIGNMENT_QUERY = gql`
  query assignment($id: ID!, $page: Int!, $privateChatsEnabled: Boolean!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        classroom {
          id
          name
        }
        closed
        createdAt
        summaryStatistics {
          positiveSentimentCount
          neutralSentimentCount
          negativeSentimentCount
          curiousLearningStatusCount
          neutralLearningStatusCount
          confusedLearningStatusCount
          totalChatCount
          totalEngagementCount
        }
        chats(page: $page, perPage: 50) @skip(if: $privateChatsEnabled) {
          pagesCount
          nodesCount
          nodes {
            id
            createdAt
            profile {
              fullName
            }
            learningStatus
            sentiment
            flaggedByModeration
            messages {
              nodesCount
            }
          }
        }
      }
    }
  }
`

const CLASSROOM_INSIGHTS_QUERY = gql`
  query classroomInsights($classroomId: ID!, $insightableId: ID!) {
    classroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      studentProfile {
        firstName
        lastName
      }
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      misconceptionsConcepts
      misconceptionsDetails
      misconceptionsSyllabusConnection
      misconceptionsRootCauses
      misconceptionsWorthReteaching
    }
  }
`

const SessionInsights = ({ tutorId }) => {
  const { id: assignmentId } = useParams()
  const [page, setPage] = useState(1)

  const { data: { organization = {} } = {}, isFetched } = useQuery({
    queryKey: ['organization'],
    queryFn: async () => request(ORGANIZATION_QUERY)
  })

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['assignment', assignmentId, page],
    queryFn: async () => request(ASSIGNMENT_QUERY, { id: assignmentId, page, privateChatsEnabled: organization.privateStudentChatsEnabled }),
    refetchInterval: 120000, // Refetch every 2 minutes
    enabled: isFetched
  })

  const assignment = data?.node || {}
  const classroom = data?.node?.classroom || {}

  const { data: { classroomInsights: rawData = [] } = {} } = useQuery(
    ['classroomInsights', classroom.id, assignment.id],
    async () => request(CLASSROOM_INSIGHTS_QUERY, { classroomId: classroom.id, insightableId: assignment.id }),
    { enabled: !!classroom.id && !!assignment.id }
  )

  const dataToExport = {
    data: rawData,
    filename: 'mindjoy-export'
  }

  const scatterData = rawData.reduce((acc, student) => {
    const key = student.answerQuality
    const value = {
      y: student.questionsAsked,
      x: round(student.activeTimeSeconds / 60, 2),
      tooltipData: {
        Name: `${student.studentProfile.firstName} ${student.studentProfile.lastName}`,
        Questions: student.questionsAsked,
        Quality: capitalize(student.answerQuality),
        Time: formatDuration(student.activeTimeSeconds)
      }
    }

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(value)
    return acc
  }, {})

  const CustomTooltip = ({ active, payload, _label }) => {
    if (active && payload && payload.length) {
      return (
        <Card className='p-3'>
          {Object.entries(payload[0].payload.tooltipData || [])
            .filter(([_, value]) => value)
            .map(([key, value]) => (
              <div key={key} className='flex flex-col'>
                <span className='text-sm font-medium text-gray-500'>{key}</span>
                <span className='text-lg font-semibold text-gray-900'>{value}</span>
              </div>
            ))}

        </Card>
      )
    }

    return null
  }

  const cardData = [
    {
      title: 'Class Averages',
      type: 'cards',
      data: [
        { name: 'Total Time', value: formatDuration((rawData.reduce((acc, student) => acc + student.totalTimeSeconds, 0) / rawData.length) / 60) },
        { name: 'Active Engagement', value: formatDuration((rawData.reduce((acc, student) => acc + student.activeTimeSeconds, 0) / rawData.length) / 60) },
        { name: 'Questions Asked', value: (rawData.reduce((acc, student) => acc + student.questionsAsked, 0) / rawData.length).toFixed(1) },
        { name: 'Questions Answered', value: (rawData.reduce((acc, student) => acc + student.questionsAnswered, 0) / rawData.length).toFixed(1) }
      ]
    },
    {
      title: 'Common Misconceptions',
      type: 'expandableList',
      data: orderBy(
        rawData.reduce((acc, student) => {
          if (!student.misconceptionsConcepts) return acc

          student.misconceptionsConcepts.forEach((misconception, index) => {
            const existingMisconception = acc.find(item => item.name === misconception)
            if (existingMisconception) {
              existingMisconception.count += 1
            } else {
              acc.push({
                name: misconception,
                count: 1,
                details: student.misconceptionsDetails[index] || 'No details available.'
              })
            }
          })
          return acc
        }, []),
        ['count'],
        ['desc']
      )
    },
    {
      title: 'Root Causes',
      type: 'table',
      data: rawData.reduce((acc, student) => {
        if (!student.misconceptionsRootCauses) return acc

        student.misconceptionsRootCauses.forEach((rootCauseList) => {
          rootCauseList.split(',').forEach((rootCause) => {
            const trimmedRootCause = rootCause.trim()
            if (!acc.some(item => item.Topic === trimmedRootCause)) {
              acc.push({ Topic: trimmedRootCause })
            }
          })
        })
        return acc
      }, [])
    },
    {
      title: 'Topics Worth Reteaching',
      type: 'table',
      data: rawData.reduce((acc, student) => {
        if (!student.misconceptionsConcepts || !student.misconceptionsWorthReteaching) return acc

        student.misconceptionsConcepts.forEach((misconception, index) => {
          if (student.misconceptionsWorthReteaching[index] === 'Yes') {
            if (!acc.some(item => item.Topic === misconception)) {
              acc.push({ Topic: misconception })
            }
          }
        })
        return acc
      }, [])
    },
    {
      title: 'Table of Topics and the Number of Times They Were Misconceived',
      type: 'table',
      data: orderBy(
        rawData.reduce((acc, student) => {
          if (!student.misconceptionsConcepts) return acc

          student.misconceptionsConcepts.forEach((misconception) => {
            const existingMisconception = acc.find(item => item.Topic === misconception)
            if (existingMisconception) {
              existingMisconception.Count += 1
            } else {
              acc.push({ Topic: misconception, Count: 1 })
            }
          })
          return acc
        }, []),
        ['Count'],
        ['desc']
      )
    },
    {
      title: 'Active Engagement Time',
      type: 'barChart',
      labels: {
        x: 'Student',
        y: 'Active Time (mins)'
      },
      angle: {
        x: -45
      },
      data: orderBy(
        rawData.map((student) => ({
          name: student.studentProfile.firstName,
          value: round(student.activeTimeSeconds / 60, 2),
          tooltipData: { Name: `${student.studentProfile.firstName} ${student.studentProfile.lastName}`, Time: formatDuration(student.activeTimeSeconds) }
        })),
        ['name'],
        ['asc']
      )
    },
    {
      title: 'Active Time vs Questions Asked',
      type: 'scatterChart',
      units: {
        x: ' min'
      },
      data: scatterData
    },
    {
      title: 'Answer Quality Distribution',
      type: 'barChart',
      data: [
        { name: 'Poor', value: rawData.filter((student) => student.answerQuality === 'poor').length },
        { name: 'Satisfactory', value: rawData.filter((student) => student.answerQuality === 'satisfactory').length },
        { name: 'Good', value: rawData.filter((student) => student.answerQuality === 'good').length },
        { name: 'Excellent', value: rawData.filter((student) => student.answerQuality === 'excellent').length }
      ].map((item) => ({ ...item, tooltipData: { Quality: item.name, Answers: item.value } }))
    },
    {
      title: 'Student Groups',
      type: 'cards',
      data: [
        { name: 'Beginner', value: `${rawData.filter((student) => student.group === 'beginner').length} students` },
        { name: 'Intermediate', value: `${rawData.filter((student) => student.group === 'intermediate').length} students` },
        { name: 'Advanced', value: `${rawData.filter((student) => student.group === 'advanced').length} students` }
      ]
    }
  ]

  const [expandedMisconception, setExpandedMisconception] = useState(null)

  const toggleMisconception = (index) => {
    setExpandedMisconception(expandedMisconception === index ? null : index)
  }

  const renderCardContent = (card) => {
    switch (card.type) {
      case 'cards':
        // Don't make dynamic classes (like grid-cols-${card.data.length}) or Tailwind will purge them. Tailwind Safelist not working for some reason, so:
        if (card.data.length === 4) {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        } else {
          return (
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
              {card.data.map((item) => (
                <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                  <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                </div>
              ))}
            </dl>
          )
        }
      case 'barChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart data={card.data} margin={{ bottom: card.labels && card.labels.x && 45 }} style={{ cursor: 'pointer' }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis interval={0} dataKey='name' angle={card.angle && card.angle.x} textAnchor={(card.angle && card.angle.x && 'end') || 'middle'} label={{ value: card.labels && card.labels.x, offset: -40, position: 'insideBottom' }} />
                  <YAxis label={{ value: card.labels && card.labels.y, angle: -90, offset: 20, position: 'insideBottomLeft' }} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey='value' fill='#3b82f6' />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )
      case 'scatterChart':
        return (
          <Card className='items-center p-3'>
            <div className='mt-5 h-64'>
              <ResponsiveContainer width='100%' height='100%'>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 10,
                    left: 10
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='x' type='number' unit={card.units && card.units.x} />
                  <YAxis dataKey='y' type='number' />
                  <Tooltip content={<CustomTooltip />} />
                  <Scatter data={card.data.null} fill='#64748b' />
                  <Scatter data={card.data.poor} fill='#ef4444' />
                  <Scatter data={card.data.satisfactory} fill='#eab308' />
                  <Scatter data={card.data.good} fill='#22c55e' />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )
      case 'table':
        return (
          <div className='mt-5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <For each='key' of={Object.keys(card.data[0] || {})}>
                    <Table.Header>{key}</Table.Header>
                  </For>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <If condition={card.data.length < 1}>
                  <Table.Row>
                    <Table.Cell colSpan={Object.keys(card.data[0] || {}).length || 1}>
                      Nothing found!
                    </Table.Cell>
                  </Table.Row>
                </If>

                <For each='row' of={card.data}>
                  <Table.Row key={row.Topic}>
                    <For each='key' of={Object.keys(row)}>
                      <Table.Cell>{row[key]}</Table.Cell>
                    </For>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        )
      case 'expandableList':
        return (
          <div className='space-y-2'>
            {card.data.map((misconception, index) => (
              <div key={index} className='border rounded-lg overflow-hidden'>
                <button
                  className='w-full text-left p-4 focus:outline-none hover:bg-gray-100 flex justify-between items-center bg-white'
                  onClick={() => toggleMisconception(index)}
                >
                  <span className='font-semibold text-gray-900'>{misconception.name}</span>
                  <span className='text-2xl text-gray-500'>{expandedMisconception === index ? '−' : '+'}</span>
                </button>
                {expandedMisconception === index && (
                  <div className='p-4 bg-gray-50'>
                    <p className='text-gray-700'>{misconception.details}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )
      default:
        return null
    }
  }

  if (isLoading || !isFetched) {
    return <Spinner className='flex items-center justify-center w-full mt-32' />
  }

  return (
    <>
      <div className='sm:mx-5 my-3'>
        <Link
          to={`/educators/tutors/${tutorId}/insights`}
          className='block text-blue-500 mb-3'
        >
          <ChevronLeftIcon className='w-4 h-4 inline-flex mr-1' />
          back
        </Link>

        <div class='flex'>
          <Card className='flex justify-between p-5'>
            <div>
              <div className='flex'>
                <h2 className='text-xl font-semibold mr-2'>
                  {classroom.name}
                </h2>
              </div>

              <p className='text-gray-700 text-sm mt-1'>Started {formatTimestamp(assignment.createdAt)}</p>
            </div>
          </Card>

          <Card className='flex items-center p-3'>
            <If condition={rawData.length > 0}>
              <div className='ml-auto'>
                <Button
                  onClick={() => jsonToCsvExport(dataToExport)}
                  theme='secondary'
                  className='flex items-center border-0 text-xl'
                  label={<span className='flex gap-2 items-center'>CSV<CloudArrowDownIcon className='h-6 w-6' /></span>}
                />
              </div>
            </If>
          </Card>
        </div>
      </div>

      <div className='sm:mx-6 my-4 space-y-8 pb-8'>
        <If condition={rawData.length > 0}>
          {cardData.map((card, index) => (
            <div key={index} class='space-y-1'>
              <h3 className='mx-3 sm-mx-0 text-lg font-semibold text-gray-900'>{card.title}</h3>
              {renderCardContent(card)}
            </div>
          ))}
        </If>
      </div>
    </>
  )
}

export default SessionInsights
