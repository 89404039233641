
import { Outlet, Link } from 'react-router-dom'

import { useSubscription } from '../../hooks/subscription'

const Paywall = () => {
  const { isLoading, hasActiveSubscription, isWithinTrialPeriod } = useSubscription()

  if (isLoading) return null

  return (
    <>
      <Choose>
        <When condition={hasActiveSubscription || isWithinTrialPeriod}>
          <Outlet />
        </When>

        <Otherwise>
          <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-3xl font-bold font-heading">Your free trial has expired 😔</h1>

            <p className="text-lg mt-5">
              Please&nbsp;
              <Link className="mx-1 text-purple-500 underline font-semibold hover:text-purple-600 " to="/educators/upgrade">
                upgrade
              </Link>
              &nbsp;to continue using Mindjoy, or
              <a className="mx-1 text-purple-500 underline font-semibold hover:text-purple-600 " href="mailto:support@mindjoy.com">
                contact us
              </a>
              if you'd like to chat.
            </p>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default Paywall

