import Markdown from '@components/Markdown'

const Explanation = ({ heading, text }) => (
  <>
    <h1 className="font-heading text-4xl font-bold my-5">{heading}</h1>
    <Markdown content={text} />
  </>
)

export default Explanation
