import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'
import PageHeading from '@components/PageHeading'
import Tab from '@components/Tab'

import PublicSeries from './PublicSeries'
import OrganizationSeries from './OrganizationSeries'

const Community = () => {
  const { user: { organization: { name: organizationName } } } = useCurrentUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get('tab') || 0
  const setSelectedTab = index => setSearchParams({ tab: index })

  return (
    <>
      <PageHeading title="Community" />

      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List>
          <Tab>
            Mindjoy projects
          </Tab>

          <Tab>
            {organizationName} projects
          </Tab>
        </Tab.List>

        <Tab.Panels className="flex flex-col md:px-5 md:py-3">
          <Tab.Panel>
            <PublicSeries />
          </Tab.Panel>

          <Tab.Panel>
            <OrganizationSeries />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group >
    </>
  )
}

export default Community
