import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import NavItem from './NavItem'

const BetaPill = () => (
  <span className='ml-3 rounded-3xl text-purple-500 py-1 px-3 text-xs m-1 border border-purple-500'>Beta</span>
)

const NavBar = ({ children, navigation = [], settings = [], message }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const closeSidebar = () => setSidebarOpen(false)
  const openSidebar = () => setSidebarOpen(true)

  return (
    <>
      <div className='w-full'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-50 lg:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-900/80' />
            </Transition.Child>

            <div className='fixed inset-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button type='button' className='-m-2.5 p-2.5' onClick={closeSidebar}>
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>

                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className='flex grow flex-col overflow-y-auto bg-white px-6 pb-3'>
                    <div className='flex h-24 shrink-0 items-center my-3'>
                      <img
                        className='h-14 w-auto'
                        src='/mindjoy-logo.svg'
                        alt='Mindjoy logo'
                      />
                      <BetaPill />
                    </div>
                    <nav className='flex flex-1 flex-col'>
                      <ul role='list' className='flex flex-1 flex-col gap-y-7 justify-between'>
                        <li>
                          <ul role='list' className='-mx-2 space-y-1'>
                            <For each='item' of={navigation}>
                              <li key={item.name}>
                                <NavItem closeSidebar={closeSidebar} item={item} />
                              </li>
                            </For>
                          </ul>
                        </li>

                        <li>
                          {message}
                        </li>

                        <li>
                          <ul role='list' className='-mx-2 space-y-1'>
                            <For each='item' of={settings}>
                              <li key={item.name}>
                                <NavItem closeSidebar={closeSidebar} item={item} />
                              </li>
                            </For>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-[240px] lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white px-6 pb-3'>
            <div className='flex h-24 shrink-0 items-center my-3'>
              <img
                className='h-14 w-auto'
                src='/mindjoy-logo.svg'
                alt='logo'
              />
              <BetaPill />
            </div>
            <nav className='flex flex-1 flex-col'>
              <ul role='list' className='flex flex-1 flex-col gap-y-7 justify-between'>
                <li>
                  <ul role='list' className='-mx-2 space-y-1'>
                    <For each='item' of={navigation}>
                      <li key={item.name}>
                        <NavItem closeSidebar={closeSidebar} item={item} />
                      </li>
                    </For>
                  </ul>
                </li>

                <If condition={message}>
                  <li>
                    {message}
                  </li>
                </If>

                <li>
                  <ul role='list' className='-mx-2 space-y-1'>
                    <For each='item' of={settings}>
                      <li key={item.name}>
                        <NavItem closeSidebar={closeSidebar} item={item} />
                      </li>
                    </For>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='sticky top-0 z-30 flex items-center gap-x-6 bg-white px-4 py-4 border-b-gray-200 border-b sm:px-6 lg:hidden'>
          <button type='button' className='-m-2.5 p-2.5 text-gray-700 lg:hidden' onClick={openSidebar}>
            <span className='sr-only'>Open sidebar</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        {children}
      </div>
    </>
  )
}

export default NavBar
