import PageHeading from '@components/PageHeading'

import MyLibrary from './MyLibrary'

const Projects = () => (
  <>
    <PageHeading title='My courses' />

    <MyLibrary />
  </>
)

export default Projects
