import { useState } from 'react'
import { Link } from 'react-router-dom'

import Card from '@components/Card'
import Markdown from '@components/Markdown'
import BreadCrumbs from '@components/Breadcrumbs'

const LessonPlanList = ({ sections, unitName, unitSlug, pathPrefix }) => {
  const projects = sections.flatMap(section => section.projects)
  const [activeSectionId, setActiveSectionId] = useState(projects?.[0]?.id)
  const activeSection = projects.find(project => project.id === activeSectionId)

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label="Projects" to={`/${pathPrefix}/projects?tab=0`} />
        <BreadCrumbs.Link label={unitName} to={`/${pathPrefix}/units/${unitSlug}`} />
        <BreadCrumbs.Text label="Lesson plans" />
      </BreadCrumbs>

      <div className="flex md:flex-row flex-col">
        <Card className="md:mr-10 md:basis-1/3 h-fit p-5 md:m-5 mt-5">
          <ul className="">
            <For each="section" of={sections}>
              <li key={section.id} className="text-base font-bold mb-3">{section.name}</li>

              <For each="project" of={section.projects}>
                <li key={project.id} className="text-base font-semibold ml-6 mb-3 text-blue-500">
                  <button className="text-left" onClick={() => setActiveSectionId(project.id)}>
                    {project.name}
                  </button>
                </li>
              </For>
            </For>
          </ul>
        </Card>

        <div className="md:basis-2/3 m-5">
          <Markdown content={activeSection?.lessonPlan} />
        </div>
      </div>
    </>
  )
}

export default LessonPlanList
