import { forwardRef } from 'react'

const MathInput = forwardRef(({ value, setValue, submitForm, ...props }, ref) => {
  return (
    <math-field
      ref={ref}
      {...props}
      math-virtual-keyboard-policy='manual'
      virtual-keyboards='numeric functions symbols roman greek'
      class='w-full controls-hidden min-h-[40px] block rounded-md border-0 py-1.5 text-gray-900 focus-within:outline-none text-xl'
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault()

          submitForm()
        }
      }}
      onInput={(e) => setValue(e.target.value)}
    >
      {value}
    </math-field>
  )
})

export default MathInput
