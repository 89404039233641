import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useParams } from 'react-router-dom'

import BreadCrumbs from '@components/Breadcrumbs'
import Card from '@components/Card'
import PageHeading from '@components/PageHeading'
import Pill from '@components/Pill'
import { request } from '@helpers/graphql'

import ChatMessageHistory from './ChatMessageHistory'
import CompletionHistory from './CompletionHistory'
import CodingHistory from './CodingHistory'
import DocumentHistory from './DocumentHistory'
import TextBlock from './TextBlock'
import ReflectionBlock from './ReflectionBlock'
import FreeResponseBlock from './FreeResponseBlock'
import QuestionBlock from './QuestionBlock'
import Skeleton from './Skeleton'

const EDUCATOR_PROJECT_SUBMISSION_QUERY = gql`
  query educatorProjectSubmission($id: ID!) {
    node(id: $id) {
      ... on EducatorProjectSubmission {
        id
        status
        blockSubmissions {
          id
          status
          blockId
          createdAt
          content {
            ... on ReflectionSubmission {
              __typename
              answer
            }
            ... on FreeResponseSubmission {
              __typename
              answer
              correct
            }
            ... on QuestionSubmission {
              __typename
              question {
                label
              }
              answer {
                label
              }
              correct
            }
          }
        }
        educatorProject {
          id
          name
          readme
          series {
            id
            name
          }
          blocks {
            blockId
            position
            ... on Text {
              __typename
              id
              content
            }
            ... on Reflection {
              __typename
              id
              question
            }
            ... on FreeResponse {
              __typename
              id
              question
            }
            ... on Question {
              __typename
              id
              label
            }
          }
          playground
          submission
          createdAt
          updatedAt
        }
      }
    }
  }
`

const SubmissionDetails = () => {
  const { id } = useParams()
  const { isLoading, data: { node: { educatorProject: { blocks = [], ...educatorProject } = {}, blockSubmissions = [], ...submission } = {} } = {} } = useQuery({
    queryKey: ['educatorProjectSubmission', id],
    queryFn: async () => request(EDUCATOR_PROJECT_SUBMISSION_QUERY, { id })
  })

  // create map of block submissions by blockId
  const blockSubmissionsMap = blockSubmissions.reduce((acc, blockSubmission) => {
    acc[blockSubmission.blockId] = blockSubmission
    return acc
  }, {})

  const totalBlocks = blocks.length
  const completedBlocks = blockSubmissions.filter(blockSubmission => blockSubmission.status === 'COMPLETED').length

  const correctQuestions = blockSubmissions.filter(blockSubmission => {
    return blockSubmission.content?.correct &&
      (blockSubmission?.content?.__typename === 'QuestionSubmission' || blockSubmission?.content?.__typename === 'FreeResponseSubmission')
  }).length
  const totalQuestions = blocks.filter(block => block.__typename === 'Question' || block.__typename === 'FreeResponse').length

  if (isLoading) return <Skeleton />

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='My courses' to='/educators/courses' />
        <BreadCrumbs.Link label={educatorProject.series.name} to={`/educators/courses/${educatorProject.series.id}`} />
        <BreadCrumbs.Link label={educatorProject.name} to={`/educators/projects/${educatorProject.id}`} />
        <BreadCrumbs.Text label='Submission' />
      </BreadCrumbs>

      <PageHeading title={
        <span className='flex items-center'>
          {educatorProject.name}
          <Pill theme={submission.status === 'COMPLETED' ? 'success' : 'primary'} label={submission.status.replace('_', ' ').toLowerCase()} className='ml-2 font-semibold' />
        </span>
      }
      />

      <div className='flex md:px-5 pt-3 md:gap-10 gap-5 flex-col md:flex-row'>
        <div className='md:basis-1/2'>
          <Card className='md:mb-6 mb-3 md:mt-3 p-3 md:mr-3 md:w-fit inline-block'>
            <p><span className='font-bold'>{completedBlocks}/{totalBlocks}</span> sections completed</p>
          </Card>

          <If condition={totalQuestions > 0}>
            <Card className='md:mb-6 mb-3 md:mt-3 p-3 md:mr-3 md:w-fit inline-block'>
              <p><span className='font-bold'>{correctQuestions}/{totalQuestions}</span> questions correct</p>
            </Card>
          </If>

          <div className='px-3 md:px-0'>
            <For each='block' of={blocks}>
              <With blockSubmission={blockSubmissionsMap[block.blockId]}>
                <Choose>
                  <When condition={block.__typename === 'Text'}>
                    <TextBlock
                      submittedAt={blockSubmission?.createdAt}
                      status={blockSubmission?.status}
                      content={block.content}
                    />
                  </When>

                  <When condition={block.__typename === 'Reflection'}>
                    <ReflectionBlock
                      submittedAt={blockSubmission?.createdAt}
                      status={blockSubmission?.status}
                      question={block.question}
                      answer={blockSubmission?.content?.answer}
                    />
                  </When>

                  <When condition={block.__typename === 'FreeResponse'}>
                    <FreeResponseBlock
                      submittedAt={blockSubmission?.createdAt}
                      status={blockSubmission?.status}
                      question={block.question}
                      answer={blockSubmission?.content?.answer}
                      correct={blockSubmission?.content?.correct}
                    />
                  </When>

                  <When condition={block.__typename === 'Question'}>
                    <QuestionBlock
                      submittedAt={blockSubmission?.createdAt}
                      status={blockSubmission?.status}
                      question={block.label}
                      answer={blockSubmission?.content?.answer.label}
                      correct={blockSubmission?.content?.correct}
                    />
                  </When>
                </Choose>
              </With>
            </For>
          </div>
        </div>

        <div className='basis-1/2'>
          <Choose>
            <When condition={educatorProject.playground === 'CHAT'}>
              <ChatMessageHistory id={id} />
            </When>

            <When condition={educatorProject.playground === 'COMPLETION'}>
              <CompletionHistory id={id} />
            </When>

            <When condition={educatorProject.playground === 'CODE'}>
              <CodingHistory id={id} />
              <ChatMessageHistory id={id} />
            </When>

            <When condition={educatorProject.playground === 'DOC'}>
              <DocumentHistory id={id} />
              <ChatMessageHistory id={id} />
            </When>
          </Choose>
        </div>
      </div>
    </>
  )
}

export default SubmissionDetails
