import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import Table from '@components/Table'
import { capitalize, round } from 'lodash'
import Heading from '@components/Heading'
import Card from '../../../components/Card'
import { formatDuration } from '@helpers/format'

const SERIES_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        id
        name
        description
        educatorName
        organizationName
        coverImage {
          url
        }
        educatorProjects {
          id
          name
          playground
          mySubmission {
            id
            status
          }
        }
      }
    }
  }
`

const STUDENT_INSIGHTS_QUERY = gql`
  query studentInsights($insightableId: ID!) {
    studentInsights(insightableId: $insightableId) {
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      questionCountDefinition
      questionCountConceptual
      questionCountProcedural
      questionCountVerification
      questionCountOther
      questionExampleDefinition
      questionExampleConceptual
      questionExampleProcedural
      questionExampleVerification
      questionExampleOther
      questionAdvice
      aiAdvice
      answerCountIncorrect
      answerCountPartiallyCorrect
      answerCountCorrect
      answerCountSingleWord
      answerCountOther
      answerExampleIncorrect
      answerExamplePartiallyCorrect
      answerExampleCorrect
      answerExampleSingleWord
      answerExampleOther
      answerAdvice
      recommendationsFocusedStudyAreas
      recommendationsEngagement
      recommendationsCriticalThinking
      recommendationsBehaviorIndicators
      recommendationsImprovements
      skillsDemonstrated
      areasForImprovement
      misconceptionsDetails
      misconceptionsConcepts
      misconceptionsEvidence
      misconceptionsSyllabusConnection
    }
  }
`

const SeriesInsights = () => {
  const { id } = useParams()

  const { isLoading, data: { node: series = {} } = {} } = useQuery({
    queryKey: ['series', id],
    queryFn: async () => request(SERIES_QUERY, { id })
  })

  const { insightIsLoading, data: { studentInsights = {} } = {} } = useQuery({
    queryKey: ['studentInsights', id],
    queryFn: async () => request(STUDENT_INSIGHTS_QUERY, { insightableId: id })
  })

  const questionData = studentInsights && [
    { type: 'Definition Question', count: studentInsights.questionCountDefinition, example: studentInsights.questionExampleDefinition },
    { type: 'Conceptual Question', count: studentInsights.questionCountConceptual, example: studentInsights.questionExampleConceptual },
    { type: 'Procedural Question', count: studentInsights.questionCountProcedural, example: studentInsights.questionExampleProcedural },
    { type: 'Verification Question', count: studentInsights.questionCountVerification, example: studentInsights.questionExampleVerification },
    { type: 'Other', count: studentInsights.questionCountOther, example: studentInsights.questionExampleOther }
  ]

  const totalQuestions = studentInsights && questionData.reduce((sum, question) => sum + question.count, 0)

  const questionDataWithPercentages = studentInsights && questionData.map(question => ({
    ...question,
    percentage: question.count && ((question.count / totalQuestions) * 100).toFixed(2),
    example: question.example
  }))

  const answerData = studentInsights && [
    { type: 'Incorrect', count: studentInsights.answerCountIncorrect, example: studentInsights.answerExampleIncorrect },
    { type: 'Partially Correct', count: studentInsights.answerCountPartiallyCorrect, example: studentInsights.answerExamplePartiallyCorrect },
    { type: 'Correct', count: studentInsights.answerCountCorrect, example: studentInsights.answerExampleCorrect },
    { type: 'Single Word', count: studentInsights.answerCountSingleWord, example: studentInsights.answerExampleSingleWord },
    { type: 'Other', count: studentInsights.answerCountOther, example: studentInsights.answerExampleOther }
  ]

  const totalAnswers = studentInsights && answerData.reduce((sum, answer) => sum + answer.count, 0)

  const answerDataWithPercentages = studentInsights && answerData.map(answer => ({
    ...answer,
    percentage: answer.count && ((answer.count / totalAnswers) * 100).toFixed(2),
    example: answer.example
  }))

  const misconceptionData = studentInsights && studentInsights.misconceptionsDetails &&
  studentInsights.misconceptionsDetails.map((misconception, index) => ({
    concept: studentInsights.misconceptionsConcepts[index],
    detail: misconception,
    evidence: studentInsights.misconceptionsEvidence[index]
  }))

  if (isLoading) return
  if (insightIsLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Course Insights' to='/students/course_insights' />
        <BreadCrumbs.Text label={series.name} />
      </BreadCrumbs>

      <Choose>
        <When condition={!studentInsights}>
          <div className='mt-3 mx-5'>
            No insights available yet!
          </div>
        </When>

        <Otherwise>
          <div className='my-6 mx-8 space-y-16'>
            <div>
              <Heading title='Student Learning Analysis' />
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header width='50%'>Metric</Table.Header>
                    <Table.Header width='50%'>Value</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Total Time</Table.Cell>
                    <Table.Cell>{formatDuration(studentInsights.totalTimeSeconds)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Active Engagement Time</Table.Cell>
                    <Table.Cell>{formatDuration(studentInsights.activeTimeSeconds)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Questions Asked</Table.Cell>
                    <Table.Cell>{studentInsights.questionsAsked}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Questions Answered</Table.Cell>
                    <Table.Cell>{studentInsights.questionsAnswered}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Quality of Answers</Table.Cell>
                    <Table.Cell>{capitalize(studentInsights.group)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>AI Usage Level</Table.Cell>
                    <Table.Cell>{capitalize(studentInsights.group)}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>

            <div class='space-y-8'>
              <If condition={questionDataWithPercentages}>
                <div>
                  <Heading title='Questions Asked' />
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header width='33%'>Question Type</Table.Header>
                        <Table.Header width='33%'>Percentage</Table.Header>
                        <Table.Header width='33%'>Example</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {questionDataWithPercentages.map((question, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='whitespace-normal'>{question.type}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{round(question.percentage)}%</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{question.example}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </If>

              <If condition={studentInsights.questionAdvice}>
                <div>
                  <Heading title='How to write better questions' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.questionAdvice}
                    </p>
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.aiAdvice}>
                <div>
                  <Heading title='How to use AI more effectively' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.aiAdvice}
                    </p>
                  </Card>
                </div>
              </If>
            </div>

            <div class='space-y-8'>
              <If condition={answerDataWithPercentages}>
                <div>
                  <Heading title='Answers Given' />
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header width='33%'>Answer Type</Table.Header>
                        <Table.Header width='33%'>Percentage</Table.Header>
                        <Table.Header width='33%'>Example</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {answerDataWithPercentages.map((answer, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='whitespace-normal'>{answer.type}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{round(answer.percentage)}%</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{answer.example}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </If>

              <If condition={studentInsights.answerAdvice}>
                <div>
                  <Heading title='How to structure better answers' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.answerAdvice}
                    </p>
                  </Card>
                </div>
              </If>
            </div>

            <div class='space-y-8'>
              <If condition={misconceptionData}>
                <div>
                  <Heading title='Misconceptions' />
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Header width='33%'>Concept</Table.Header>
                        <Table.Header width='33%'>Detail</Table.Header>
                        <Table.Header width='33%'>Evidence</Table.Header>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {misconceptionData.map((misconception, index) => (
                        <Table.Row key={index}>
                          <Table.Cell className='whitespace-normal'>{misconception.concept}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{misconception.detail}</Table.Cell>
                          <Table.Cell className='whitespace-normal'>{misconception.evidence}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </If>

              <If condition={studentInsights.misconceptionsSyllabusConnection}>
                <div>
                  <Heading title='Connection to Syllabus' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    <p class='items-center justify-center'>
                      {studentInsights.misconceptionsSyllabusConnection}
                    </p>
                  </Card>
                </div>
              </If>
            </div>

            <div className='space-y-8'>
              <Heading title='Metacognition' />

              <If condition={studentInsights.skillsDemonstrated}>
                <div>
                  <Heading title='Skills Demonstrated' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.skillsDemonstrated.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.areasForImprovement}>
                <div>
                  <Heading title='Areas for Improvement' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.areasForImprovement.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>
            </div>

            <div class='space-y-8'>
              <Heading title='Recommendations for Improvement' />

              <If condition={studentInsights.recommendationsFocusedStudyAreas}>
                <div>
                  <Heading title='Focused Study Areas' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.recommendationsFocusedStudyAreas.map((area, index) => (
                        <li key={index}>{area}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsEngagement}>
                <div>
                  <Heading title='Engagement' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    {studentInsights.recommendationsEngagement}
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsCriticalThinking}>
                <div>
                  <Heading title='Critical Thinking' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    {studentInsights.recommendationsCriticalThinking}
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsBehaviorIndicators}>
                <div>
                  <Heading title='Behavior Indicators' className='text-2xl mb-3' />
                  <Card className='p-6'>
                    {studentInsights.recommendationsBehaviorIndicators}
                  </Card>
                </div>
              </If>

              <If condition={studentInsights.recommendationsImprovements}>
                <div>
                  <Heading title='Improvements' className='text-2xl mb-3' />
                  <Card className='p-8'>
                    <ul class='items-center justify-center list-disc'>
                      {studentInsights.recommendationsImprovements.map((improvement, index) => (
                        <li key={index}>{improvement}</li>
                      ))}
                    </ul>
                  </Card>
                </div>
              </If>
            </div>

          </div>

        </Otherwise>
      </Choose>
    </>
  )
}

export default SeriesInsights
