import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'
import { DocumentTextIcon } from '@heroicons/react/24/outline'

import { request } from '@helpers/graphql'
import BlockCard from './BlockCard'
import Button from '@components/Button'
import Modal from '@components/Modal'
import SecureMarkdown from '@components/SecureMarkdown'
import TextEditor from '@components/TextEditor'

const UPDATE_TEXT_BLOCK_MUTATION = gql`
  mutation updateTextBlock($input: UpdateTextBlockInput!) {
    updateTextBlock(input: $input) {
      text {
        content
      }
      errors {
        message
      }
    }
  }
`

const TextBlock = ({ id, content, showDeleteModal, refetch, dragHandleProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { control, handleSubmit } = useForm({ defaultValues: { content } })
  const { mutate: updateTextBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_TEXT_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => {
      refetch()
      setIsModalOpen(false)
    }
  })

  const onSubmit = ({ content }) => {
    updateTextBlock({ content })
  }

  return (
    <>
      <BlockCard
        title='Text'
        icon={<DocumentTextIcon className='h-5 w-5' />}
        onEdit={setIsModalOpen}
        onDelete={showDeleteModal}
        dragHandleProps={dragHandleProps}
      >
        <Choose>
          <When condition={content}>
            <SecureMarkdown maxCharCount={300} className='px-5 py-3' content={content} />
          </When>

          <Otherwise>
            <p className='px-5 py-3 text-lg italic'>You haven't set any text just yet</p>
          </Otherwise>
        </Choose>
      </BlockCard>

      <Modal
        size='lg'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col px-5 py-5'>
          <h3 className='font-heading text-2xl font-bold mb-3'>Edit text</h3>

          <Controller
            name='content'
            control={control}
            render={({ field }) => (
              <TextEditor
                className='min-h-[200px]'
                {...field}
              />
            )}
          />

          <Button
            className='mt-5 ml-auto'
            type='submit'
            disabled={isLoading}
            label={isLoading ? 'Saving...' : 'Save'}
          />
        </form>
      </Modal>
    </>
  )
}

export default TextBlock
