import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import BreadCrumbs from '@components/Breadcrumbs'
import PageHeading from '@components/PageHeading'
import Spinner from '@components/Spinner'
import Tab from '@components/Tab'

import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'
import AddStudentsModal from '../../components/AddStudentsModal'
import OpenTutorList from './OpenTutorList'
import StudentProfilesList from './StudentProfilesList'
import Settings from './Settings'

const CLASSROOM_QUERY = gql`
  query classroom($id: ID!) {
    classroom(id: $id) {
      id
      name
      shortCode
      educatorProfile {
        id
        fullName
      }
    }
  }
`

const ClassroomDetails = () => {
  const { id } = useParams()
  const { hasProFeatures } = useSubscription()
  const [showInviteModal, setShowInviteModal] = useState(false)
  const {
    isLoading,
    data: { classroom = {} } = {},
    refetch
  } = useQuery({
    queryKey: ['classroom', id],
    queryFn: async () => await request(CLASSROOM_QUERY, { id })
  })

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Classrooms' to='/educators/classrooms' />
        <BreadCrumbs.Text label={classroom.name} />
      </BreadCrumbs>

      <PageHeading title={classroom.name}>
        <Button
          id='add-students-button'
          disabled={!hasProFeatures}
          onClick={() => setShowInviteModal(true)}
          label='Add students'
        />

        <If condition={!hasProFeatures}>
          <ProFeatureTooltip anchorSelect='#add-students-button' />
        </If>
      </PageHeading>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative left-0 top-0 flex h-screen w-full items-center justify-center' />
        </When>

        <Otherwise>
          <Tab.Group as='div' className='w-full'>
            <Tab.List>
              <Tab>Assigned tutors</Tab>
              <Tab>Students</Tab>
              <Tab>Settings</Tab>
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel>
                <OpenTutorList id={id} />
              </Tab.Panel>

              <Tab.Panel>
                <StudentProfilesList id={id} />
              </Tab.Panel>

              <Tab.Panel>
                <Settings
                  classroomId={classroom.id}
                  name={classroom.name}
                  refetch={refetch}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>

          <AddStudentsModal
            classroomId={id}
            shortCode={classroom.shortCode}
            classroomName={classroom.name}
            closeModal={() => setShowInviteModal(false)}
            isOpen={showInviteModal}
          />
        </Otherwise>
      </Choose>
    </>
  )
}

export default ClassroomDetails
