import { forwardRef } from 'react'

const MathInput = forwardRef(({ value, setValue, readOnly, ...props }, ref) => {
  return (
    <math-field
      id='mathlive-input'
      ref={ref}
      {...props}
      {...readOnly ? { 'read-only': true } : {}}
      math-virtual-keyboard-policy='manual'
      virtual-keyboards='numeric functions symbols roman greek'
      class='w-full controls-hidden min-h-[40px] block rounded-md border-0 py-1.5 text-gray-900 focus-within:outline-none text-xl'
      onInput={(e) => setValue(e.target.value)}
    >
      {value}
    </math-field>
  )
})

export default MathInput
