import React, { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useParams, useNavigate, Routes, Route, NavLink, useLocation } from 'react-router-dom'
import {
  ArrowUpOnSquareIcon,
  ChatBubbleLeftEllipsisIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  LightBulbIcon,
  PresentationChartLineIcon,
  ShareIcon
} from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import { useCurrentUser } from '@contexts/currentUser'
import { request } from '@helpers/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'

import TutorConversation from './TutorConversation'
import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'
import AssignModal from './AssignModal'
import ShareModal from './ShareModal'
import Settings from './Settings'
import StudentActivity from './StudentActivity'
import SessionDetails from './SessionDetails'
import TutorInsights from './TutorInsights'
import SessionInsights from './SessionInsights'

const TUTOR_QUERY = gql`
  query tutor($id: ID!) {
    node(id: $id) {
      ... on Tutor {
        id
        name
        description
        summary
        socratic
        suggestionsEnabled
        stemModeEnabled
        knowledgeBaseEnabled
        visibility
        isOwner
        image {
          url
        }
        documents {
          id
          file {
            url
            filename
          }
        }
        userName
        organizationName
        tags {
          id
          name
        }
      }
    }
  }
`

const CREATE_COPY_MUTATION = gql`
  mutation createCopyOfTutor($input: CreateCopyInput!) {
    createCopyOfTutor(input: $input) {
      tutor {
        id
      }
    }
  }
`

const NavItem = ({ to, state, end = false, label, icon }) => (
  <NavLink
    to={to}
    state={state}
    end={end}
    className={({ isActive }) => twMerge('w-full sm:w-fit sm:ml-5 flex flex-row justify-center items-center px-1 h-full border-b-4 outline-none', isActive ? 'font-semibold border-b-purple-500' : 'border-b-white font-normal ')}
  >
    {icon}
    <span className='sm:block hidden'>{label}</span>
  </NavLink>
)

const TutorDetails = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const { hasProFeatures } = useSubscription()
  const { user: { organization: { features = [] } = {} } } = useCurrentUser()

  const insightsEnabled = features.includes('tutor_insights')
  const previousListParams = location.state?.previousListParams || ''

  const { isLoading, refetch, data: { node: tutor = {} } = {} } = useQuery({
    queryKey: ['tutorDetails', id],
    queryFn: async () => request(TUTOR_QUERY, { id })
  })

  const { mutate: createCopy, isLoading: isCopying } = useMutation({
    mutationFn: async () => request(CREATE_COPY_MUTATION, { input: { tutorId: id } }),
    onSuccess: data => navigate(`/educators/tutors/${data.createCopyOfTutor.tutor.id}`)
  })

  if (isLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Tutors' to={`/educators/tutors${previousListParams}`} />
        <BreadCrumbs.Text label={tutor.name} />
      </BreadCrumbs>

      <div className='h-[calc(100%-45px)]'>
        <div className='h-[50px] flex flex-row items-center justify-between border-b-gray-200 border-b bg-white'>
          <NavItem
            to=''
            state={{ previousListParams }}
            end
            label='Chat'
            icon={<ChatBubbleLeftEllipsisIcon className='h-6 w-6 mr-1' />}
          />

          <If condition={tutor.isOwner}>
            <NavItem
              to='sessions'
              state={{ previousListParams }}
              icon={<PresentationChartLineIcon className='h-6 w-6 mr-1' />}
              label='Student activity'
            />
          </If>

          <NavItem
            to='settings'
            state={{ previousListParams }}
            icon={<Cog6ToothIcon className='h-6 w-6 mr-1' />}
            label='Settings'
          />

          <If condition={tutor.isOwner && insightsEnabled}>
            <NavItem
              to='insights'
              state={{ previousListParams }}
              icon={<LightBulbIcon className='h-6 w-6 mr-1' />}
              label='Insights'
            />
          </If>

          <Choose>
            <When condition={tutor.isOwner}>
              <div className='ml-auto'>
                <If condition={tutor.visibility === 'PUBLIC'}>
                  <LinkButton
                    onClick={() => setIsShareModalOpen(true)}
                    className='mr-5 text-black' theme='light'
                  >
                    Share
                    <ShareIcon className='h-5 w-5 ml-1' />
                  </LinkButton>
                </If>

                <Button
                  id='assign-tutor-button'
                  disabled={!hasProFeatures}
                  theme='secondary'
                  onClick={() => setIsAssignModalOpen(true)}
                  className='pro-feature ml-auto py-2 sm:mr-5 mr-1 my-1.5 px-3'
                  label={<span data-tutorial='assign-tutor-step' className='flex gap-2 items-center'>Assign<ArrowUpOnSquareIcon className='h-6 w-6' /></span>}
                />
              </div>

              <If condition={!hasProFeatures}>
                <ProFeatureTooltip anchorSelect='#assign-tutor-button' />
              </If>
            </When>

            <Otherwise>
              <Button
                id='copy-tutor-button'
                disabled={!hasProFeatures}
                onClick={createCopy}
                className='pro-feature ml-auto shrink-0 py-2 sm:mr-5 mr-1 my-1.5 px-3'
                label={isCopying ? 'Copying...' : <span className='flex gap-2 items-center'>Make a copy<DocumentDuplicateIcon className='h-6 w-6' /></span>}
              />

              <If condition={!hasProFeatures}>
                <ProFeatureTooltip anchorSelect='#copy-tutor-button' />
              </If>
            </Otherwise>
          </Choose>
        </div>

        <div className='h-[calc(100%-51px)]'>
          <Routes>
            <Route
              index
              element={
                <TutorConversation
                  tutorId={tutor.id}
                  name={tutor.name}
                  imageUrl={tutor.image?.url}
                />
              }
            />

            <If condition={tutor.isOwner}>
              <Route
                path='sessions'
                element={
                  <StudentActivity
                    tutorId={tutor.id}
                    imageUrl={tutor.image?.url}
                    name={tutor.name}
                    description={tutor.description}
                  />
                }
              />

              <Route
                path='sessions/:id'
                element={<SessionDetails tutorId={tutor.id} />}
              />
            </If>

            <Route
              path='settings'
              element={
                <Settings
                  tutorId={tutor.id}
                  isOwner={tutor.isOwner}
                  name={tutor.name}
                  description={tutor.description}
                  summary={tutor.summary}
                  socratic={tutor.socratic}
                  stemModeEnabled={tutor.stemModeEnabled}
                  knowledgeBaseEnabled={tutor.knowledgeBaseEnabled}
                  suggestionsEnabled={tutor.suggestionsEnabled}
                  visibility={tutor.visibility}
                  refetch={refetch}
                  imageUrl={tutor.image?.url}
                  documents={tutor.documents}
                  tagIds={tutor.tags?.map(tag => tag.id)}
                />
              }
            />

            <If condition={tutor.isOwner}>
              <Route
                path='insights'
                element={
                  <TutorInsights
                    tutorId={tutor.id}
                  />
                }
              />

              <Route
                path='insights/:id'
                element={<SessionInsights tutorId={tutor.id} />}
              />
            </If>
          </Routes>
        </div>
      </div>

      <AssignModal
        isOpen={isAssignModalOpen}
        closeModal={() => setIsAssignModalOpen(false)}
        name={tutor.name}
        tutorId={tutor.id}
        refetch={refetch}
      />

      <ShareModal
        isOpen={isShareModalOpen}
        closeModal={() => setIsShareModalOpen(false)}
        tutorId={tutor.id}
        name={tutor.name}
        summary={tutor.summary}
      />
    </>
  )
}

export default TutorDetails
