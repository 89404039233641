import { useForm, Controller } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { useAnalytics } from '@contexts/analytics'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import Select from '@components/Select'
import RadioGroup from '@components/RadioGroup'

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateEducatorProfile($input: UpdateEducatorProfileInput!) {
    updateEducatorProfile(input: $input) {
      educatorProfile {
        signUpPurpose
        deviceUsage
        subscriptionBasedServices
        marketingSource
      }
      errors {
        message
      }
    }
  }
`

const QualificationQuestions = ({ nextStep, signUpPurpose, deviceUsage, subscriptionBasedServices, marketingSource }) => {
  const { track } = useAnalytics()
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { signUpPurpose, deviceUsage, subscriptionBasedServices, marketingSource }
  })

  const { mutate: updateEducatorProfile, isLoading: isUpdating } = useMutation({
    mutationFn: async variables => await request(UPDATE_EDUCATOR_PROFILE_MUTATION, { input: { ...variables } }),
    onSuccess: () => {
      track('Educator Onboarding Step Completed', { step: 'qualification' })
      nextStep()
    }
  })

  const submit = data => updateEducatorProfile(data)

  return (
    <div className='w-full flex justify-center'>
      <Card className='flex flex-col sm:w-[400px] p-5 my-10 overflow-visible'>
        <img src='/team-idea.svg' alt='Team having an idea' className='w-2/5 self-center' />
        <h1 className='font-heading text-2xl font-bold self-center mt-3'>AI in your organization</h1>

        <p className='leading-tight my-3'>Tell us how you'd like to use AI in your organization.</p>

        <form className='flex flex-col space-y-4 mt-3' onSubmit={handleSubmit(submit)}>
          <If condition={errors.general}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
              {errors.general.message}
            </p>
          </If>

          <div className='w-full'>
            <Select
              id='sign-up-purpose'
              label='How can Mindjoy help?'
              required
              {...register('signUpPurpose', { required: 'This question is required' })}
            >
              <Select.Option value=''>Select answer</Select.Option>
              <Select.Option value='INTRODUCE_AI'>I'm looking for a safe and exciting way to introduce AI into my organization</Select.Option>
              <Select.Option value='INNOVATION'>I'm looking to bolster my organization's innovation efforts</Select.Option>
              <Select.Option value='CURIOUS'>I'm curious</Select.Option>
            </Select>

            <If condition={errors.signUpPurpose}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.signUpPurpose.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              id='device-usage'
              label='Is your organization paperless?'
              required
              {...register('deviceUsage', { required: 'This question is required' })}
            >
              <Select.Option value=''>Select answer</Select.Option>
              <Select.Option value='ONE_TO_ONE'>Yes, we use 1:1 devices in most of our classes</Select.Option>
              <Select.Option value='SOME'>Mostly, we use 1:1 devices in some of our classes</Select.Option>
              <Select.Option value='NONE'>No, we use paper solutions (eg. notebook and pen) for most of our classes</Select.Option>
            </Select>
            <If condition={errors.deviceUsage}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.deviceUsage.message}</p>
            </If>
          </div>

          <div>
            <Controller
              name='subscriptionBasedServices'
              rules={{ validate: value => value !== null || 'This question is required' }}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  className='flex sm:flex-row flex-col'
                  theme='primary'
                  label='Does your organization currently use subscription services?'
                >
                  <RadioGroup.Option
                    label='Yes'
                    value
                  />
                  <RadioGroup.Option
                    label='No'
                    value={false}
                  />
                </RadioGroup>
              )}
            />

            <If condition={errors.subscriptionBasedServices}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.subscriptionBasedServices.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              className='w-full'
              id='marketing-source'
              label='How did you hear about us?'
              required
              {...register('marketingSource', { required: 'This question is required' })}
            >
              <Select.Option value=''>Select answer</Select.Option>
              <Select.Option value='WORD_OF_MOUTH'>From a colleague who uses Mindjoy</Select.Option>
              <Select.Option value='BLOG'>Read an article on the Mindjoy blog</Select.Option>
              <Select.Option value='EMAIL'>Received an email from Mindjoy</Select.Option>
              <Select.Option value='EDUCATIONAL_INSTITUTION'>My educational institution</Select.Option>
              <Select.Option value='PODCAST'>From listening to a podcast</Select.Option>
              <Select.Option value='WORKSHOP'>Attended a Mindjoy workshop</Select.Option>
              <Select.Option value='TEAM'>Spoke to someone on the Mindjoy team</Select.Option>
              <Select.Option value='GOOGLE'>Google search</Select.Option>
              <Select.Option value='LINKEDIN'>LinkedIn</Select.Option>
              <Select.Option value='TWITTER'>X (formerly Twitter)</Select.Option>
              <Select.Option value='FACEBOOK'>Facebook</Select.Option>
              <Select.Option value='INSTAGRAM'>Instagram</Select.Option>
              <Select.Option value='TIKTOK'>TikTok</Select.Option>
              <Select.Option value='YOUTUBE'>YouTube</Select.Option>
            </Select>

            <If condition={errors.marketingSource}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.marketingSource.message}</p>
            </If>
          </div>

          <Button className='w-fit ml-auto' type='submit' label={isUpdating ? 'Saving...' : 'Next'} disabled={isUpdating} />
        </form>
      </Card>
    </div>
  )
}

export default QualificationQuestions
