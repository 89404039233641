import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { useAnalytics } from '@contexts/analytics'
import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import Combobox from '@components/Combobox'
import Select from '@components/Select'
import DismissiblePill from '@components/DismissiblePill'
import PhoneNumber, { countries } from '@components/PhoneNumber'

import timezones from './timezones.json'

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateEducatorProfile($input: UpdateEducatorProfileInput!) {
    updateEducatorProfile(input: $input) {
      educatorProfile {
        firstName
        lastName
        organizationRole
        phoneCountryCode
        phoneNumber
        locationCountryCode
        subjects {
          id
          name
        }
      }
      errors {
        message
      }
    }
  }
`

const SUBJECTS_QUERY = gql`
  query subjects {
    subjects {
      id
      name
    }
  }
`

const EducatorDetails = ({
  nextStep,
  firstName,
  lastName,
  organizationRole,
  subjects,
  locationCountryCode,
  phoneCountryCode,
  phoneNumber
}) => {
  const { track } = useAnalytics()
  const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { firstName, lastName, organizationRole, subjects, locationCountryCode, phoneCountryCode, phoneNumber }
  })

  const { mutate: updateEducatorProfile, isLoading: isUpdating } = useMutation({
    mutationFn: async variables => {
      const { subjects, ...rest } = variables
      const subjectIds = subjects.map(subject => subject.id)
      const input = { ...rest, subjectIds }

      return await request(UPDATE_EDUCATOR_PROFILE_MUTATION, { input })
    },
    onSuccess: () => {
      track('Educator Onboarding Step Completed', { step: 'educator' })
      nextStep()
    }
  })

  const [searchTerm, setSearchTerm] = useState('')

  const { data: { subjects: allSubjects = [] } = {} } = useQuery({
    queryKey: ['subjects'],
    queryFn: () => request(SUBJECTS_QUERY),
    staleTime: 86400000 // refresh once a day
  })

  const filteredSubjects = allSubjects.filter(subject => {
    const subjectName = subject.name.toLowerCase()
    return subjectName.includes(searchTerm.toLowerCase())
  })

  const submit = data => updateEducatorProfile(data)

  const selectedSubjects = watch('subjects')

  const handleDismissSubject = (subjectId) => {
    const newSubjects = selectedSubjects.filter(subject => subject.id !== subjectId)
    setValue('subjects', newSubjects)
  }

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (!timeZone) return

    const countryCode = timezones[timeZone]

    if (countryCode) {
      setValue('phoneCountryCode', countryCode)
      setValue('locationCountryCode', countryCode)
    }
  }, [])

  return (
    <div className='w-full flex justify-center'>
      <Card className='flex flex-col sm:w-[400px] p-5 my-10 overflow-visible'>
        <img src='/bag.svg' alt='Backpack' className='w-1/4 self-center mt-5' />
        <h1 className='text-2xl font-bold self-center mt-3'>Your details</h1>

        <p className='leading-tight my-3'>Tell us a little bit about yourself.</p>

        <form className='flex flex-col space-y-4 mt-3' onSubmit={handleSubmit(submit)}>
          <If condition={errors.general}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
              {errors.general.message}
            </p>
          </If>

          <div className='flex gap-3'>
            <div className='w-full'>
              <TextInput
                id='first-name'
                label='First name'
                required
                {...register('firstName', { required: 'First name is required' })}
              />
              <If condition={errors.firstName}>
                <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.firstName.message}</p>
              </If>
            </div>

            <div className='w-full'>
              <TextInput
                id='last-name'
                label='Last name'
                required
                {...register('lastName', { required: 'Last name is required' })}
              />
              <If condition={errors.lastName}>
                <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.lastName.message}</p>
              </If>
            </div>
          </div>

          <div className='w-full'>
            <PhoneNumber
              label='Phone number'
              required
            >
              <PhoneNumber.CountryCodeSelect
                {...register('phoneCountryCode', { required: 'Country code is required' })}
              />

              <PhoneNumber.PhoneNumberInput
                countryCode={watch('phoneCountryCode')}
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                  minLength: { value: 4, message: 'Phone number is too short' },
                  maxLength: { value: 10, message: 'Phone number is too long' },
                  pattern: { value: /^[0-9]*$/, message: 'Phone number must be a number' },
                  onChange: ev => {
                    const cleanedValue = ev.target.value.replace(/^0+|[^0-9]/g, '')
                    setValue('phoneNumber', cleanedValue)
                  }
                })}
              />
            </PhoneNumber>

            <If condition={errors.phoneNumber}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.phoneNumber.message}</p>
            </If>

            <If condition={errors.countryCode}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.countryCode.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              id='country'
              label='Country'
              required
              className='w-full'
              {...register('locationCountryCode', { required: 'Country is required' })}
            >
              <Select.Option value=''>Select country</Select.Option>
              <For each='country' of={countries}>
                <Select.Option key={country.code} value={country.code}>{country.name}</Select.Option>
              </For>
            </Select>
            <If condition={errors.role}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.role.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              id='role'
              label='Your role'
              required
              className='w-full'
              {...register('organizationRole', { required: 'Role is required' })}
            >
              <Select.Option value=''>Select role</Select.Option>
              <Select.Option value='teacher'>Teacher</Select.Option>
              <Select.Option value='lecturer'>Lecturer</Select.Option>
              <Select.Option value='professor'>Professor</Select.Option>
              <Select.Option value='school_leader'>School leader</Select.Option>
              <Select.Option value='head_of_department'>Head of department</Select.Option>
              <Select.Option value='dean'>Dean</Select.Option>
              <Select.Option value='edtech_specialist'>EdTech specialist</Select.Option>
              <Select.Option value='district_leader'>District leader</Select.Option>
              <Select.Option value='parent'>Parent</Select.Option>
              <Select.Option value='other'>Other</Select.Option>
            </Select>
            <If condition={errors.role}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.role.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Controller
              name='subjects'
              control={control}
              rules={{ required: 'Select at least one subject' }}
              render={({ field }) => (
                <Combobox
                  {...field}
                  label='Subjects you teach'
                  placeholder='Type to search subjects'
                  multiple
                  onSearch={text => setSearchTerm(text)}
                  by={(a, b) => a.id === b.id}
                  required
                >
                  <For each='subject' of={filteredSubjects}>
                    <Combobox.Option key={subject.id} value={subject} label={subject.name} />
                  </For>
                </Combobox>
              )}
            />
            <If condition={errors.subjects}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.subjects.message}</p>
            </If>

            <div className='flex flex-wrap mt-2'>
              <For each='subject' of={selectedSubjects}>
                <DismissiblePill
                  theme='light'
                  key={subject.id}
                  label={subject.name}
                  onDismiss={() => handleDismissSubject(subject.id)}
                  className='m-0 mr-1 mb-1'
                />
              </For>
            </div>
          </div>

          <Button className='w-fit ml-auto' type='submit' label={isUpdating ? 'Saving...' : 'Next'} disabled={isUpdating} />
        </form>
      </Card>
    </div>
  )
}

export default EducatorDetails
