import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import {
  AcademicCapIcon,
  ArrowLeftOnRectangleIcon,
  Cog6ToothIcon,
  WrenchScrewdriverIcon,
  CalendarDaysIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  GlobeAltIcon,
  ChatBubbleLeftEllipsisIcon,
  QuestionMarkCircleIcon,
  UserPlusIcon,
  SparklesIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline'

import { client } from '@helpers/api'
import { useCurrentUser } from '@contexts/currentUser'
import TechnicalDifficultiesBanner from './TechnicalDifficultiesBanner'

import Navbar from './Navbar'
import UpgradeBanner from '../educators/components/UpgradeBanner'
import OnboardingChecklist from '../educators/components/OnboardingChecklist'

const EducatorNav = ({ children }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { setUser, user: { authorization_role: authRole, organization: { features = [] } = {} } } = useCurrentUser()
  const isEducatorAdmin = authRole === 'admin'

  const { mutate: signOut } = useMutation(['signOut'], async () => client.delete('/users/sign_out'),
    {
      onSuccess: () => {
        setUser({ loading: false, signedIn: false })
        queryClient.clear()
        navigate('/sign_in')
      }
    }
  )

  const legacyFeaturesEnabled = features.includes('legacy_features')
  const technicalDifficultiesBannerEnabled = features.includes('technical_difficulties_banner')
  const educatorCreatorsEnabled = features.includes('educator_creators')

  const legacyNavigation = [
    { name: 'Tutors', href: '/educators/tutors', icon: ChatBubbleLeftEllipsisIcon },
    ...educatorCreatorsEnabled ? [{ name: 'Creators', href: '/educators/creators', icon: SparklesIcon }] : [],
    { name: 'Courses', href: '/educators/courses', icon: BookOpenIcon },
    { name: 'Classrooms', href: '/educators/classrooms', icon: AcademicCapIcon },
    { name: 'PD workshops', href: '/educators/workshops', icon: CalendarDaysIcon },
    { name: 'Tools', href: '/educators/tools', icon: WrenchScrewdriverIcon },
    { name: 'Invite educators', href: '/educators/organization/educators', icon: UserPlusIcon }
  ]

  const navigation = [
    { name: 'Tutors', href: '/educators/tutors', icon: ChatBubbleLeftEllipsisIcon },
    ...educatorCreatorsEnabled ? [{ name: 'Creators', href: '/educators/creators', icon: SparklesIcon }] : [],
    { name: 'Classrooms', href: '/educators/classrooms', icon: AcademicCapIcon },
    { name: 'Workshops', href: '/educators/workshops', icon: CalendarDaysIcon },
    { name: 'Invite educators', href: '/educators/organization/educators', icon: UserPlusIcon }
  ]

  const settings = [
    ...isEducatorAdmin ? [{ name: 'Organization', href: '/educators/organization/profile', icon: BuildingOfficeIcon }] : [],
    { name: 'Settings', href: '/educators/settings', icon: Cog6ToothIcon },
    { name: 'Help', href: 'https://coda.io/d/_dazL7wOvRR3/Frequently-Asked-Questions_suRhK', icon: QuestionMarkCircleIcon, external: true },
    { name: 'Sign out', icon: ArrowLeftOnRectangleIcon, onClick: signOut }
  ]

  return (
    <Navbar
      navigation={legacyFeaturesEnabled ? legacyNavigation : navigation}
      settings={settings}
      message={
        <>
          <If condition={technicalDifficultiesBannerEnabled}>
            <TechnicalDifficultiesBanner />
          </If>

          <OnboardingChecklist />

          <UpgradeBanner />
        </>
      }
    >
      {children}
    </Navbar>
  )
}

export default EducatorNav
