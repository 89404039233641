import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { MathfieldElement } from 'mathlive'

import Label from '@components/Label'
import TextInput from './TextInput'
import MathInput from './MathInput'
import ModeToggle from './ModeToggle'

MathfieldElement.soundsDirectory = null

const TextAreaWithMathInput = forwardRef(({ label, setValue, required, ...props }, ref) => {
  const [inputMode, setInputMode] = useState('text')
  const virtualKeyboardRef = useRef(null)

  useEffect(() => {
    const input = document.querySelector('#mathlive-input')

    if (input && inputMode === 'math') {
      input.addEventListener('focus', () => {
        window.mathVirtualKeyboard.show()
        window.mathVirtualKeyboard.container = virtualKeyboardRef.current

        const keyboardHeight = window.mathVirtualKeyboard.boundingRect.height
        virtualKeyboardRef.current.style.height = `${keyboardHeight}px`
      })

      input.addEventListener('blur', () => {
        window.mathVirtualKeyboard.hide()
        virtualKeyboardRef.current.style.height = '0px'
      })

      input.focus()
    } else {
      window.mathVirtualKeyboard.hide()
      virtualKeyboardRef.current.style.height = '0px'
    }
  }, [inputMode])

  return (
    <>
      <Label className='mb-3'>{label}</Label>

      <div
        className={twMerge(
          'w-full box-border flex flex-col pt-3 rounded-md border-2 border-x-2 border-gray-300 bg-white',
          inputMode === 'math' ? 'has-[:focus]:border-purple-500' : 'has-[:focus]:border-blue-500'
        )}
        data-tutorial='message-input-step'
      >
        <div className='flex items-start px-3'>
          <Choose>
            <When condition={inputMode === 'math'}>
              <MathInput
                setValue={setValue}
                ref={ref}
                {...props}
              />
            </When>

            <Otherwise>
              <TextInput
                ref={ref}
                {...props}
              />
            </Otherwise>
          </Choose>
        </div>

        <div className='flex items-center p-3'>
          <ModeToggle inputMode={inputMode} setInputMode={setInputMode} />
        </div>

        <div
          ref={virtualKeyboardRef}
          className='transition-height duration-300 ease-in-out'
        />
      </div>
    </>
  )
})

export default TextAreaWithMathInput
