import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox as HeadlessCombobox } from '@headlessui/react'

import Label from '../Label'

const Combobox = ({
  value,
  onChange,
  onBlur,
  onSearch,
  multiple,
  displayValue,
  className,
  name,
  placeholder = 'Type to search',
  label,
  required,
  by,
  children
}) => {
  return (
    <HeadlessCombobox
      as='div'
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      multiple={multiple}
      className={className}
      by={by}
    >
      <Label title={label} required={required} />
      <div className='relative'>
        <HeadlessCombobox.Input
          placeholder={placeholder}
          className='w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6'
          onChange={ev => {
            if (onSearch) onSearch(ev.target.value)
          }}
          displayValue={displayValue}
        />
        <HeadlessCombobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronUpDownIcon className='h-5 w-5 text-gray-500' aria-hidden='true' />
        </HeadlessCombobox.Button>

        <If condition={children}>
          <HeadlessCombobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {children}
          </HeadlessCombobox.Options>
        </If>
      </div>
    </HeadlessCombobox>
  )
}

export default Combobox
