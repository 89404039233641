import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Spinner from '@components/Spinner'
import Pagination from '@components/Pagination'
import PageHeading from '@components/PageHeading'
import TutorCard from '@components/TutorCard'

const TUTOR_ASSIGNMENTS_QUERY = gql`
  query tutorAssignments($page: Int, $closed: Boolean) {
    tutorAssignments(page: $page, closed: $closed) {
      nodes {
        id
        createdAt
        classroom {
          id
          name
        }
        tutor {
          id
          name
          summary
          createdAt
          image {
            url
          }
          userName
          organizationName
        }
      }
      pagesCount
      nodesCount
    }
  }
`

const Library = ({ pageHeading, closed }) => {
  const [page, setPage] = useState(1)

  const { isLoading, data: { tutorAssignments: { nodes: tutorAssignments = [], pagesCount } = {} } = {} } = useQuery({
    queryKey: ['tutorAssignments', page, closed],
    queryFn: async () => request(TUTOR_ASSIGNMENTS_QUERY, { page, closed }),
    keepPreviousData: true
  })

  return (
    <>
      <PageHeading title={pageHeading} />
      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-full' />
        </When>

        <Otherwise>
          <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 sm:px-5 pt-3'>
            <If condition={tutorAssignments.length < 1}>
              <p>You don't have any tutors assigned to you just yet 😀</p>
            </If>

            <For index='index' each='assignment' of={tutorAssignments}>
              <Link className='w-full' to={`assignments/${assignment.id}`} key={assignment.id}>
                <With tutor={assignment.tutor}>
                  <TutorCard
                    key={tutor.id}
                    id={tutor.id}
                    name={tutor.name}
                    description={tutor.summary}
                    imageUrl={tutor.image?.url}
                  />
                </With>
              </Link>
            </For>
          </div>

          <Pagination page={page} pagesCount={pagesCount} setPage={setPage} />
        </Otherwise>
      </Choose>
    </>
  )
}

export default Library
